import { Box, Icon, IconButton } from "@mui/material";
import DataTable from "examples/Tables/DataTable/index";
import { useCallback, useEffect, useMemo, useState } from "react";

// Rows:
// ...fields
// children -> ...fields, children -> ...

function flattenHierarchy(node, childrenField, level = 0) {
  let result = [];

  result.push({
    ...node,
    [childrenField]: null,
    level,
    numberOfChildren: node[childrenField]?.length,
    childrenIds: [],
    directChildrenIds: node[childrenField]?.map((child) => child.id) || [],
  });

  const index = result.length - 1;

  if (node[childrenField] && node[childrenField].length > 0) {
    let children = [];
    node[childrenField].forEach((child) => {
      children = children.concat(
        flattenHierarchy(child, childrenField, level + 1)
      );
    });

    result[index].childrenIds = children.map((child) => child.id);

    result = result.concat(children);
  }

  return result;
}

export default function CollapsibleDataTable({
  table,
  childrenField = "children",
  ...props
}) {
  const [rows, setRows] = useState([]);
  const [rowsState, setRowsState] = useState({});
  const columns = useMemo(() => {
    if (!table.columns) return [];
    return [...table.columns];
  }, [table.columns]);

  const firstField = useMemo(() => {
    return columns?.find((column) => column.firstField).accessor;
  }, [columns]);

  const handleToggleOpen = useCallback(
    (id, value) => {
      setRowsState((curr) => {
        const newRowsState = { ...curr };
        if (!newRowsState[id]) newRowsState[id] = {};
        newRowsState[id].open = value;

        const parent = rows.find((row) => row.id == id);
        if (!value) {
          parent?.childrenIds.map((childId) => {
            if (!newRowsState[childId]) newRowsState[childId] = {};

            newRowsState[childId].hidden = !value;
            newRowsState[childId].open = value;
          });
        } else {
          parent?.directChildrenIds.map((childId) => {
            if (!newRowsState[childId]) newRowsState[childId] = {};

            newRowsState[childId].hidden = !value;
            newRowsState[childId].open = !value;
          });
        }
        /*setRows((curr) => {
          return updateRowsByRowsState(curr, newRowsState);
        });*/
        return newRowsState;
      });
    },
    [setRowsState, rows]
  );

  const updateRowsByRowsState = useCallback(
    (rows, rowsState) => {
      const newRows = [...rows];

      newRows.forEach((row, index) => {
        newRows[index].hidden = rowsState[row.id]?.hidden;

        if (row.numberOfChildren > 0) {
          newRows[index][firstField] = (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                paddingLeft: row.level * 3,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                handleToggleOpen(row.id, !rowsState[row.id]?.open);
              }}
            >
              <IconButton size="small">
                <Icon>
                  {rowsState[row.id]?.open ? "expand_more" : "chevron_right"}
                </Icon>
              </IconButton>
              {newRows[index].firstField}
            </Box>
          );
        } else {
          newRows[index][firstField] = (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                paddingLeft: row.level * 3,
                alignItems: "center",
              }}
            >
              <IconButton size="small" sx={{ pointerEvents: "none" }}>
                <Icon></Icon>
              </IconButton>
              {newRows[index].firstField}
            </Box>
          );
        }
      });

      return newRows;
    },
    [handleToggleOpen, firstField]
  );

  useEffect(() => {
    if (!table.rows) return;
    let newRows = [];

    table.rows.forEach((element) => {
      newRows = newRows.concat(flattenHierarchy(element, childrenField));
    });

    const newRowsState = { ...rowsState };

    newRows.forEach((row) => {
      if (Boolean(row.parentId)) {
        newRowsState[row.id] = {
          open: false,
          hidden: false, // ALERT
        };
      } else {
        newRowsState[row.id] = {
          open: true,
          hidden: false,
        };
      }
    });

    newRows = updateRowsByRowsState(newRows, newRowsState);

    setRowsState(newRowsState);

    setRows(newRows);
  }, [table.rows]);

  useEffect(() => {
    if (!rowsState || !rows?.length) return;
    setRows((curr) => {
      return updateRowsByRowsState(curr, rowsState);
    });
  }, [rowsState]);

  return (
    <DataTable
      table={{
        columns,
        rows: rows,
      }}
      {...props}
      entriesPerPage={{ defaultValue: 500000 }}
    />
  );
}
