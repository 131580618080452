import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ColorPickerInput from "components/ColorPickerInput";
import DevelopmentFeature from "components/DevelopmentFeature";
import DropdownSelect from "components/DropdownSelect";
import LoadingButton from "components/LoadingButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MSelect from "components/MSelect";
import * as React from "react";
import toast from "react-hot-toast";
import accountsService from "services/accounts-service";
import useObjectState from "utils/useObjectState";

export default function UserDialog({
  isOpen,
  action,
  data,
  onSubmit,
  accountId,
}) {
  const [open, setOpen] = React.useState({ dialog: false });
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState({
    name: "",
    email: "",
    roleId: 0,
    color: "#eeeeee",
  });
  const [updated, setUpdated] = React.useState({});
  const [error, setError] = React.useState(null);
  const [options, changeOptions] = useObjectState({
    accounts: null,
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen({ dialog: true });
    setLoading(false);
    setError(null);
  }, [isOpen]);

  React.useEffect(() => {
    if (action == "create") {
      setValue({
        name: "",
        email: "",
        roleId: data?.roles[0]?.id,
        color: "#eeeeee",
      });
    } else {
      setValue(data?.user);
    }
  }, [data]);

  const accessLevelTypes = React.useMemo(() => {
    const value = [{ id: 1, label: "Account (Everything)" }];

    if (data.accounts?.length) {
      value.push({ id: 2, label: "Sub-account" });
    }

    if (data.branchGroups?.length) {
      value.push({ id: 3, label: "Branch group" });
    }

    if (data.branchGroups?.length) {
      value.push({ id: 4, label: "Branch" });
    }
    return value;
  }, [data]);

  const accessLevel = React.useMemo(() => {
    let type = 1;
    let id = null;

    if (value.accessLevel?.accountId) {
      type = 2;
      id = value?.accessLevel.accountId;
    }

    if (value.accessLevel?.branchGroupId) {
      type = 3;
      id = value?.accessLevel.branchGroupId;
    }

    if (value.accessLevel?.branchId) {
      type = 4;
      id = value?.accessLevel.branchId;
    }

    return { type, value: id };
  }, [value]);

  const handleChangeAccessLevel = (type, id) => {
    const accessLevel = {};
    if (type === 2) {
      accessLevel.accountId = id;
    } else if (type === 3) {
      accessLevel.branchGroupId = id;
    } else if (type === 4) {
      accessLevel.branchId = id;
    }

    setValue({ ...value, accessLevel });
  };

  const handleGetAccounts = async () => {
    const [response, data] = await accountsService.getAccounts({ raw: true });
    if (response.ok) {
      changeOptions("accounts", data);
    } else {
    }
  };

  React.useEffect(() => {
    handleGetAccounts();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const dropdownOptions = React.useMemo(() => {
    return {
      accounts:
        options.accounts?.map((account) => ({
          id: account.id,
          label: account.name,
        })) || [],
    };
  }, [options]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open.dialog}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(value);
          if (result?.success) {
            handleClose();
          } else {
            setError(result.error);

            if (!result.error.fields)
              toast.error(
                `${result.error.message + "\n\n" || ""}Try refreshing the page`
              );
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, pb: 0.4, pt: 1.4 }}>
        {action == "edit" ? "Edit" : "Create"}
        {" user"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              required
              error={error?.fields === "name"}
              helperText={error?.fields === "name" && error?.message}
              id="r"
              name="r"
              label="Name"
              type="text"
              value={value.name}
              onChange={(e) => {
                setValue({ ...value, name: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="r"
              name="r"
              label="Email"
              type="email"
              error={error?.fields === "email"}
              helperText={error?.fields === "email" && error?.message}
              value={value.email}
              onChange={(e) => {
                setValue({ ...value, email: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <MSelect
              error={error?.fields === "roleId"}
              helperText={error?.fields === "roleId" && error?.message}
              items={data.roles.map((role) => {
                return {
                  value: role.id,
                  render: role.description,
                };
              })}
              value={value.roleId}
              onChange={(e) => {
                setValue({ ...value, roleId: e });
              }}
              label="Role"
              required
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ mt: 0.5 }}
            onClick={() => {
              setOpen({ ...open, color: !open.color });
            }}
          >
            <ColorPickerInput
              required
              id="color"
              name="color"
              label="Color"
              type="color"
              value={value.color}
              onChange={(e) => {
                setValue({ ...value, color: e.target.value });
              }}
              setValue={(v) => {
                setValue({ ...value, color: v });
              }}
              fullWidth
            />
          </Grid>

          {!accountId && (
            <Grid item xs={12}>
              <DropdownSelect
                required
                label={"Account"}
                initialValue={value?.accountId}
                onChange={(e) => {
                  setValue({ ...value, accountId: e?.id });
                }}
                items={dropdownOptions.accounts}
                fullWidth
                renderOption={(option) => (
                  <MDBox
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{ height: "30px", width: "30px" }}
                      src={option.url}
                    />
                    <MDTypography variant="body" sx={{ fontWeight: "400" }}>
                      {option.label}
                    </MDTypography>
                  </MDBox>
                )}
              />
            </Grid>
          )}

          {/*<Grid item xs={!accessLevel.type || accessLevel.type === 1 ? 12 : 6}>
            <DropdownSelect
              label="Access level type"
              required
              initialValue={accessLevel.type || ""}
              onChange={(newValue) => {
                handleChangeAccessLevel(newValue?.id, -1);
              }}
              items={accessLevelTypes}
              fullWidth
            />
          </Grid>

          {accessLevel.type === 2 && (
            <Grid item xs={6}>
              <DropdownSelect
                label="Select"
                required
                initialValue={accessLevel.value || ""}
                onChange={(newValue) => {
                  handleChangeAccessLevel(2, newValue?.id);
                }}
                items={
                  data.accounts?.map((account) => {
                    return {
                      id: account.id,
                      label: account.name,
                    };
                  }) || []
                }
                fullWidth
              />
            </Grid>
          )}

          {accessLevel.type === 3 && (
            <Grid item xs={6}>
              <DropdownSelect
                label="Select"
                required
                initialValue={accessLevel.value || ""}
                onChange={(newValue) => {
                  handleChangeAccessLevel(3, newValue?.id);
                }}
                items={
                  data.branchGroups?.map((branchGroup) => {
                    return {
                      id: branchGroup.id,
                      label: branchGroup.name,
                    };
                  }) || []
                }
                fullWidth
              />
            </Grid>
          )}

          {accessLevel.type === 4 && (
            <Grid item xs={6}>
              <DropdownSelect
                label="Select"
                required
                initialValue={accessLevel.value || ""}
                onChange={(newValue) => {
                  handleChangeAccessLevel(4, newValue?.id);
                }}
                items={
                  data.branches?.map((branch) => {
                    return {
                      id: branch.id,
                      label: branch.name,
                      branchGroup: branch.branchGroup?.name,
                    };
                  }) || []
                }
                groupBy={(option) => option.branchGroup}
                fullWidth
              />
            </Grid>
          )}*/}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {action == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
