import {
  Avatar,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BasicPopover from "components/BasicPopover";
import DropdownSelect from "components/DropdownSelect";
import LoadingButton from "components/LoadingButton";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PillarsTable from "components/PillarsTable";
import Stepper from "components/Stepper";
import { EXTERNAL, INTERNAL } from "const";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import auditTemplatesService from "services/audit-templates.service";
import branchesService from "services/branches-service";
import pillarsService from "services/pillars-service";
import usersService from "services/users-service";
import useLoading from "utils/useLoading";
import AuditSummaryGroupsDialog from "./Audit/AuditSummaryGroupsDialog";

function compareTemplates(_template1, _template2) {
  const template1 = [...(_template1 || [])];
  const template2 = [...(_template2 || [])];
}

export default function AuditDialogv2({ open, setOpen, onSubmit, type }) {
  const [audit, setAudit] = useState({
    auditTypeId: 0,
    date: new Date(),
  });
  const [meta, setMeta] = useState({ branch: null });
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState({ create: false });

  const steps = [
    {
      label: "Setup Audit",
      description: "Provide basic audit info",
      level: 1,
      state: "Complete",
    },
    {
      label: "Select Kpis",
      description: "Choose a template, or select kpis manually",
      level: 2,
      state: "Current",
    },
    {
      label: "Select Summary Groups",
      description: "Group pillars by summary groups",
      level: 3,
      state: "Not started",
    },
  ];

  useEffect(() => {
    if (open) {
      setAudit({ auditTypeId: 0, date: new Date() });
      setMeta({ branch: null });
      setActiveStep(0);
    }
  }, [open]);

  useEffect(() => {
    setAudit((curr) => ({ ...curr, type }));
  }, [type]);

  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
            return;
          }

          setLoading({ ...loading, create: true });

          if (!audit.pillars) audit.pillars = {};

          const result = await onSubmit({
            ...audit,
            selectedPillarIds: Object.keys(audit.pillars),
          });
          if (result.success) {
            setOpen(false);
          }

          setLoading({ ...loading, create: false });
        },
      }}
    >
      <DialogTitle>
        <MDTypography variant="h5" sx={{ mb: 1 }}>
          {type === INTERNAL
            ? "Create audit"
            : type === EXTERNAL
              ? "Create external audit"
              : "Create audit"}
        </MDTypography>
        <Stepper steps={steps} activeStep={activeStep} />
      </DialogTitle>
      <DialogContent>
        {activeStep === 0 ? (
          <Tab0
            audit={audit}
            setAudit={setAudit}
            meta={meta}
            setMeta={setMeta}
          />
        ) : null}

        {activeStep === 1 ? (
          <Tab1
            audit={audit}
            setAudit={setAudit}
            meta={meta}
            setMeta={setMeta}
            type={type}
          />
        ) : null}

        {activeStep === 2 ? (
          <Tab2
            audit={audit}
            setAudit={setAudit}
            meta={meta}
            setMeta={setMeta}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <MDBox
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: 1,
            justifyContent: "end",
          }}
        >
          <MDButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </MDButton>
          {activeStep !== 0 && (
            <MDButton
              onClick={() => {
                if (activeStep !== 0) {
                  setActiveStep(activeStep - 1);
                }
              }}
            >
              Back
            </MDButton>
          )}
          <LoadingButton
            loading={loading.create}
            type="submit"
            color="info"
            variant="gradient"
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </LoadingButton>
        </MDBox>
      </DialogActions>
    </Dialog>
  );
}

function Tab0({ audit, setAudit, meta, setMeta }) {
  const [options, setOptions] = useState({
    branches: [],
    users: [],
  });
  const { loading, changeLoading } = useLoading({
    branches: false,
    users: false,
  });

  async function handleFetchBranches() {
    changeLoading("branches", true);
    const [response, data] = await branchesService.getBranches(
      audit.type ? { creatingAudit: audit.type } : {}
    );

    if (response.ok) {
      setOptions((options) => {
        return { ...options, branches: data };
      });
    }
    changeLoading("branches", false);
  }

  async function handleFetchUsers() {
    changeLoading("users", true);

    const [response, data] = await usersService.getUsers({
      creatingAudit: audit.branchId,
    });
    if (response.ok) {
      setOptions((options) => {
        return { ...options, users: data };
      });
    }
    changeLoading("users", false);
  }

  const dropdownOptions = useMemo(() => {
    const branches = [];
    options.branches?.forEach((branchGroup) => {
      let disabled =
        branchGroup.subscriptionPermissions &&
        !branchGroup.subscriptionPermissions?.audit?.create?.yes;

      if (audit.type == EXTERNAL) {
        disabled =
          branchGroup.subscriptionPermissions &&
          !branchGroup.subscriptionPermissions?.audit?.createExternal?.yes;
      }

      branchGroup.branches?.forEach((branch) => {
        branches.push({
          label: `${branch.name}${disabled ? " - Subscription Limited" : ""}`,
          id: branch.id,
          branchGroup: branchGroup.name,
          branchGroupId: branchGroup.id,
          subcategoryId: branch.subcategoryId,
          disabled,
        });
      });
    });

    return {
      branches,
      users:
        options.users?.map((user) => ({
          id: user.id,
          label: user.name,
          profileImage: user.profileImage,
        })) || [],
    };
  }, [options.branches, options.users]);

  useEffect(() => {
    if (audit.type) {
      handleFetchBranches();
    }
  }, [audit.type]);

  useEffect(() => {
    if (audit.branchId) {
      handleFetchUsers();
    }
  }, [audit.branchId]);

  return (
    <Grid container spacing={1} mt={0.5}>
      <Grid item xs={6}>
        <DropdownSelect
          label={"Branch"}
          id="company-selector"
          initialValue={
            audit.branchId == 0 || !audit.branchId ? "" : audit.branchId
          }
          onChange={(newValue) => {
            setAudit({ ...audit, branchId: newValue?.id });
            setMeta({ ...meta, branch: newValue });
          }}
          getOptionDisabled={(option) => option.disabled}
          items={dropdownOptions.branches}
          required={true}
          groupBy={(option) => option.branchGroup}
          fullWidth
          loading={loading.branches}
        />
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          title={!Boolean(audit.branchId) ? "Select branch first" : null}
        >
          <div>
            <DropdownSelect
              label={"Assigned to"}
              id="assignedto-selector"
              initialValue={
                audit.userId == 0 || !audit.userId ? "" : audit.userId
              }
              onChange={(newValue) => {
                setAudit({ ...audit, userId: newValue?.id });
              }}
              items={dropdownOptions.users}
              required
              fullWidth
              loading={loading.users}
              renderOption={(option) => (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{ height: "30px", width: "30px" }}
                    src={option.profileImage}
                  />
                  <MDTypography variant="body" sx={{ fontWeight: "400" }}>
                    {option.label}
                  </MDTypography>
                </MDBox>
              )}
              disabled={!Boolean(audit.branchId)}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            id="date-datetimepicker"
            label="Date"
            required={true}
            sx={{ width: "100%" }}
            defaultValue={dayjs(Date())}
            onChange={(e) => {
              setAudit({ ...audit, date: e?.$d });
            }}
            slotProps={{
              textField: {
                required: true,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item display={"flex"} justifyContent={"end"}>
        <ButtonGroup>
          <MDButton
            id="audit-auditTypeId-0"
            variant={audit.auditTypeId !== 1 ? "gradient" : "outlined"}
            color={audit.auditTypeId !== 1 ? "info" : "secondary"}
            size="medium"
            sx={
              audit.auditTypeId !== 1
                ? (theme) => ({ borderColor: theme.borders.borderColor })
                : null
            }
            onClick={() => {
              setAudit({
                ...audit,
                auditTypeId: 0,
              });
            }}
          >
            Normal
          </MDButton>
          <MDButton
            id="audit-auditTypeId-1"
            variant={audit.auditTypeId === 1 ? "gradient" : "outlined"}
            color={audit.auditTypeId === 1 ? "info" : "secondary"}
            size="medium"
            sx={
              audit.auditTypeId === 1
                ? (theme) => ({ borderColor: theme.borders.borderColor })
                : null
            }
            onClick={() => {
              setAudit({
                ...audit,
                auditTypeId: 1,
              });
            }}
          >
            Scoreless
          </MDButton>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <MDInput
          id="audit-description"
          multiline
          rows={4}
          label="Description"
          value={audit.description || ""}
          onChange={(e) => {
            setAudit({
              ...audit,
              description: e.target.value,
            });
          }}
          fullWidth
          required
        />
      </Grid>
    </Grid>
  );
}

function Tab1({ audit, setAudit, meta, setMeta, type }) {
  const [pillars, setPillars] = useState([]);
  const [options, setOptions] = useState({});
  const [initial, setInitial] = useState();
  const [loadFrom, setLoadFrom] = useState();
  const [loading, setLoading] = useState({ fetch: false, autoLoad: false });
  const [open, setOpen] = useState({ template: false });
  const [selectAllSignal, setSelectAllSignal] = useState(false);
  const [load, setLoad] = useState({ type: "auto", data: null });

  async function handleInitialFetch() {
    setLoading({ ...loading, fetch: true });
    await Promise.all([handleFetchPillars(), handleFetchAuditTemplates()]);
    await handleFetchPillarsAuto();
    setLoading({ ...loading, fetch: false });
  }

  async function handleFetchPillarsAuto() {
    const { subcategoryId, branchGroupId } = meta.branch;

    const [response, data] = await pillarsService.getPillars({
      subcategoryId,
      creatingAudit: branchGroupId,
    });

    if (response.ok) {
      if (!data?.selectedKpis?.length) {
        setSelectAllSignal(Math.random());
        setAudit({ ...audit, auditTemplateId: null });
        setLoad({ type: "auto" });
      } else {
        setInitial([...data.selectedKpis]);
        setAudit({
          ...audit,
          auditKpis: [...data.selectedKpis],
          auditTemplateId: null,
        });
        setLoad({ type: "auto" });
      }
    }
  }

  async function handleFetchPillars() {
    setPillars([]);
    const { subcategoryId } = meta.branch;

    const [response, data] = await pillarsService.getPillars({
      subcategoryId,
    });

    if (response.ok) {
      setPillars(data);
    }
  }

  async function handleFetchAuditTemplates() {
    const { id, subcategoryId } = meta.branch;

    const [response, data] = await auditTemplatesService.getAuditTemplates({
      subcategoryId,
      creatingAudit: type,
      branchId: id,
    });
    if (response.ok) {
      setOptions((options) => {
        return { ...options, templates: data };
      });
    }
  }

  function onChangeSelected(data) {
    const auditKpis = data.map((auditKpi) => {
      const pillar = pillars.find((pillar) =>
        pillar.kpis.find((kpi) => kpi.id == auditKpi.kpiId)
      );

      return { ...auditKpi, pillarId: pillar.id };
    });
    setAudit({ ...audit, auditKpis });
  }

  function onManualChangeSelected(data) {
    setLoad({ type: "custom" });
    setAudit({ ...audit, auditTemplateId: null });
  }

  function handleLoadFromTemplate(templateId) {
    const template = options.templates.find(
      (template) => template.id == templateId
    );

    setAudit({
      ...audit,
      auditKpis: [...template.templateKpis],
      auditTemplateId: templateId,
    });

    setInitial([...template.templateKpis]);
    setLoad({ type: "template", data: template });
  }

  const dropdownOptions = useMemo(() => {
    return {
      templates:
        options?.templates?.map((template) => ({
          id: template.id,
          label: template.name,
          base: template,
          group: template.fromExternalAuditor
            ? "From " + template.account.name
            : "Your templates",
        })) || [],
    };
  }, [options.templates]);

  useEffect(() => {
    handleInitialFetch();
  }, []);

  //compareTemplates(audit.auditKpis, options.templates?.[0].templateKpis);

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs>
          <MDTypography variant="h6">
            {load.type === "template"
              ? `Loaded from template "${load.data.name}"`
              : load.type === "auto"
                ? "Automatically loaded from past audits"
                : "Custom"}
          </MDTypography>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              "Load pillars and kpis from past audits of this branch/branch group"
            }
          >
            <MDButton
              onClick={() => {
                handleFetchPillarsAuto();
              }}
              size="small"
              variant="gradient"
              color="info"
            >
              Auto load
            </MDButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <BasicPopover
            button={
              <MDButton
                onClick={() => {
                  setOpen({ ...open, template: true });
                }}
                size="small"
                variant="gradient"
                color="info"
              >
                Load from template
              </MDButton>
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open.template}
            onClose={() => {
              setOpen({ ...open, template: false });
            }}
            content={
              !loading.fetch ? (
                <Grid
                  container
                  component={"form"}
                  sx={{ minWidth: "300px" }}
                  spacing={1}
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleLoadFromTemplate(loadFrom);
                  }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="h6">Select template</MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      label={"Template"}
                      id="assignedto-selector"
                      initialValue={""}
                      onChange={(newValue) => {
                        setLoadFrom(newValue?.id);
                      }}
                      items={dropdownOptions.templates}
                      groupBy={
                        type === INTERNAL ? (option) => option.group : null
                      }
                      required
                      fullWidth
                      disabled={dropdownOptions.templates.length === 0}
                      inputProps={
                        dropdownOptions.templates.length === 0
                          ? {
                              helperText:
                                "No templates were found for the selected branch's category",
                            }
                          : {}
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection={"row"}
                    justifyContent={"end"}
                  >
                    <MDButton
                      onClick={() => {
                        setOpen({ ...open, template: false });
                      }}
                      type="submit"
                      variant="gradient"
                      color="info"
                      size="small"
                      disabled={!Boolean(loadFrom)}
                    >
                      Load
                    </MDButton>
                  </Grid>
                </Grid>
              ) : (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "300px",
                    minHeight: "150px",
                  }}
                >
                  <CircularProgress color="info" size={20} />
                </MDBox>
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PillarsTable
          pillars={pillars}
          initial={initial}
          updatableDescription
          onChangeSelected={onChangeSelected}
          onManualChangeSelected={onManualChangeSelected}
          tableContainerProps={{ sx: { maxHeight: "500px" } }}
          loading={loading.fetch}
          defaultExpanded
          selectAllSignal={selectAllSignal}
        />
      </Grid>
    </Grid>
  );
}

function Tab2({ audit, setAudit, meta, setMeta }) {
  const [pillars, setPillars] = useState([]);

  async function handleFetchPillars() {
    setPillars([]);
    const { subcategoryId } = meta.branch;

    const [response, data] = await pillarsService.getPillars({
      subcategoryId,
    });

    if (response.ok) {
      setPillars(data);
    }
  }

  useEffect(() => {
    handleFetchPillars();
  }, []);

  return (
    <div>
      <MDTypography
        variant="h6"
        sx={{ padding: 1, color: "rgba(0, 0, 0, 0.4)", fontWeight: "400" }}
      >
        You can ignore this if you don't want to set summary groups right now.
        You can set them at a later stage.
      </MDTypography>
      <AuditSummaryGroupsDialog
        audit={{
          pillars: pillars
            .filter((pillar) => {
              for (let kpi of pillar.kpis) {
                if (audit.auditKpis.map((v) => v.kpiId).includes(kpi.id)) {
                  return true;
                }
              }
            })
            .map((pillar) => {
              return {
                ...pillar,
                auditPillar: {
                  summaryGroupId: audit.pillars
                    ? audit.pillars[pillar.id]
                    : null,
                },
              };
            }),
        }}
        onUpdate={(data) => {
          const newAuditPillars = { ...audit.pillars } || {};
          newAuditPillars[data.pillarId] = data.summaryGroupId;
          setAudit({ ...audit, pillars: newAuditPillars });
        }}
        noDialog
        mode="create"
        branchGroupId={meta.branch.branchGroupId}
        subcategoryId={meta.branch.subcategoryId}
      />
    </div>
  );
}
