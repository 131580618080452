import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import AccessDenied from "components/AccessDenied";
import AlertDialog from "components/AlertDialog";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TableSearch from "components/TableSearch";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import themeService from "services/theme-service";
import ThemesEditNewDialog from "./ThemesEditNewDialog";

export default function AllThemes({ permissions, ...props }) {
  const { type = "page", data, onRefresh = () => {} } = props;

  const [colorPalettes, setColorPalettes] = useState(null);
  const [currentColorPalette, setCurrentColorPalette] = useState(null);

  const [rows, setRows] = useState([]);

  const [newDialogIsOpen, setNewDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const columns = [
    {
      Header: "Name",
      accessor: "description",
      width: "100%",
    },
    {
      Header: "Colors",
      accessor: "colors",
      width: "100%",
    },
    {
      Header: "Font colors",
      accessor: "fontColors",
      width: "100%",
    },
  ];

  if (permissions.update || permissions.delete) {
    columns.push({
      Header: "",
      accessor: "actions",
      align: "right",
      width: "100%",
    });
  }

  useEffect(() => {
    updateRows();
  }, [colorPalettes]);

  async function handleAddTheme(newTheme) {
    if (props?.data?.accountId) newTheme.accountId = props?.data?.accountId;

    const [response, data] = await themeService.createColorPalette(
      newTheme,
      props.data?.accountId ? { accountId: props.data.accountId } : null
    );
    if (response.ok) {
      setColorPalettes(data);
      onRefresh(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleUpdateTheme(newTheme) {
    const [response, data] = await themeService.updateColorPalette(
      newTheme.id,
      newTheme,
      props.data?.accountId ? { accountId: props.data.accountId } : null
    );
    if (response.ok) {
      setColorPalettes(data);
      onRefresh(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleDeleteTheme(id) {
    const [response, data] = await themeService.removeColorPalette(
      id,
      props.data?.accountId ? { accountId: props.data.accountId } : null
    );
    if (response.ok) {
      setColorPalettes(data);
      onRefresh(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  useEffect(() => {
    console.log("Colorpalettes DATA: ", props.data);
    if (!props.data) {
      return;
    }

    setColorPalettes(props.data.colorPalettes);
  }, [props.data]);

  function updateRows() {
    setRows(
      colorPalettes?.length > 0
        ? colorPalettes.map((colorPalette) => {
            return {
              description: (
                <MDTypography variant="inherit">
                  {colorPalette.description}
                </MDTypography>
              ),
              colors: (
                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  <Card
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: colorPalette.color1,
                    }}
                    variant="outlined"
                  ></Card>
                  <Card
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: colorPalette.color2,
                    }}
                    variant="outlined"
                  ></Card>
                  <Card
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: colorPalette.color3,
                    }}
                    variant="outlined"
                  ></Card>
                </MDBox>
              ),
              fontColors: (
                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  <Card
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: colorPalette.fontColor1,
                    }}
                    variant="outlined"
                  ></Card>
                  <Card
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: colorPalette.fontColor2,
                    }}
                    variant="outlined"
                  ></Card>
                  <Card
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: colorPalette.fontColor3,
                    }}
                    variant="outlined"
                  ></Card>
                </MDBox>
              ),
              actions: (
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="end"
                  alignItems="center"
                >
                  {permissions.update && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEditDialogIsOpen(Math.random());
                        setCurrentColorPalette(colorPalette);
                      }}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )}
                  {permissions.delete && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setAlertIsOpen(Math.random());
                        setCurrentColorPalette(colorPalette);
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  )}
                </MDBox>
              ),
            };
          })
        : [
            {
              description: "No color palettes were found.",
            },
          ]
    );
  }

  return (
    <>
      <AlertDialog
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        title={"Are you sure?"}
        content={"Delete this color palette?"}
        onOk={async () => {
          await handleDeleteTheme(currentColorPalette.id);
        }}
      />
      <ThemesEditNewDialog
        accountId={data?.accountId}
        isOpen={newDialogIsOpen}
        onSubmit={handleAddTheme}
      />
      <ThemesEditNewDialog
        type="edit"
        isOpen={editDialogIsOpen}
        onSubmit={handleUpdateTheme}
        data={currentColorPalette}
        accountId={data?.accountId}
      />

      <MDBox
        sx={
          type === "component"
            ? { pt: 3 }
            : {
                pt: 6,
                pb: 3,
              }
        }
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={type === "component" ? 2 : 3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Color palettes {permissions.get ? `(${rows.length})` : ""}
                </MDTypography>
                {permissions.create && (
                  <MDButton
                    size={type === "component" ? "small" : "medium"}
                    onClick={() => {
                      setNewDialogIsOpen(Math.random());
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new color palette
                  </MDButton>
                )}
              </MDBox>
              {permissions.get ? (
                <>
                  {type === "component" ? null : (
                    <MDBox p={2} mt={2}>
                      <TableSearch
                        internalLoading={!Boolean(colorPalettes)}
                        id="all-themes"
                        fullWidth
                        setData={setColorPalettes}
                        onSearch={async (request, callback) => {
                          callback(
                            await themeService.getColorPalettes(
                              props.data?.accountId
                                ? {
                                    accountId: props.data.accountId,
                                    ...request,
                                  }
                                : { ...request }
                            )
                          );
                        }}
                        label={"Search color palettes"}
                      />
                    </MDBox>
                  )}
                  <MDBox mt={type === "component" ? 2 : null}>
                    <DataTable
                      loading={!Boolean(colorPalettes)}
                      table={{ columns: columns, rows: rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
