import { Avatar, Pagination, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import AlertDialog from "components/AlertDialog";
import ExpandableButton from "components/ExpandableButton";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { usePermissionMonitor } from "components/PermissionMonitor";
import TableSearch from "components/TableSearch";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import auditsService from "services/audits-service";
import regionsService from "services/regions-service";
import usersService from "services/users-service";
import useObjectState from "utils/useObjectState";
import { EXTERNAL, INTERNAL, STATUS_COLOR } from "../../../const";
import AuditDialogv2 from "./AuditDialog.v3";

const columns = [
  { Header: "", accessor: "type", align: "left" },
  { Header: "Company", accessor: "branch", align: "left", width: "100%" },
  {
    Header: "Description",
    accessor: "description",
    align: "left",
    width: "100%",
  },
  {
    Header: "Date",
    accessor: "date",
    align: "left",
    width: "100%",
  },
  {
    Header: "Assigned to",
    accessor: "assignedTo",
    align: "left",
    width: "100%",
  },
  { Header: "Status", accessor: "status", align: "left", width: "100%" },
  {
    Header: "",
    accessor: "actions",
    align: "left",
    width: "100%",
  },
];

export default function AllAudits({ permissions }) {
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    sortBy: "",
  });
  const can = usePermissionMonitor();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [audits, setAudits] = useState(null);
  const [count, setCount] = useState(null);
  const [options, changeOptions] = useObjectState({
    statuses: null,
    users: null,
    regions: null,
  });
  const [open, changeOpen] = useObjectState({
    create: false,
    alert: false,
  });
  const [csvData, setCsvData] = useState(null);
  const [lastRequestQuery, setLastRequestQuery] = useState(null);

  async function fetchAudits(cancelToken, sortBy, loadingId) {
    const requests = [
      usersService.getUsers(null, null, cancelToken),
      regionsService.getRegions(null, cancelToken),
    ];

    if (permissions.get) {
      requests.push(auditsService.getStatuses(null, cancelToken));
    }
    const values = await Promise.all(requests);

    let [response, data] = values[0];
    if (response.ok) {
      changeOptions("users", data);
    } else {
      console.log(`Error while fetching users: ${data.error}`);
    }

    [response, data] = values[1];
    if (response.ok) {
      changeOptions("regions", data);
    } else {
      console.log(`Error while fetching regions: ${data.error}`);
    }

    if (permissions.get) {
      [response, data] = values[2];
      if (response.ok) {
        changeOptions("statuses", data);
      } else {
        console.log(`Error while fetching statuses: ${data.error}`);
      }
    }
  }

  async function fetchAuditsOnly(request, newPage, sortBy) {
    const req = request ? { ...request } : { ...lastRequestQuery };
    req.skip = newPage
      ? (newPage - 1) * 10
      : (Number(searchParams.get("page") || 1) - 1) * 10;
    req.take = 10;
    req.sortBy = sortBy
      ? sortBy.replace("asce", "asc")
      : searchParams.get("sortBy").replace("asce", "asc");

    const [ok, data] = await auditsService.getAudits(req);

    if (ok) {
      setAudits(data.data);
      setCount(data.count);
    }
  }

  async function handleCreateAudit(newAudit) {
    newAudit = JSON.parse(JSON.stringify(newAudit));

    newAudit.date = new Date(newAudit.date).toISOString();

    const [response, data] = await auditsService.createAudit(newAudit);
    if (response.ok) {
      await fetchAuditsOnly();
      return { success: true };
    } else {
      console.log(`Error while creating audit: ${data.error}`);
      return { error: data.error };
    }
  }

  async function handleDeleteAudit(audit) {
    const [response, data] = await auditsService.removeAudit(audit.id);
    if (response.ok) {
      await fetchAuditsOnly();
      return { success: true };
    } else {
      console.log(`Error while deleting audit: ${data.error}`);
      return { error: `Error while deleting audit: ${data.error}` };
    }
  }

  const filterBySelectsItems = useMemo(() => {
    const filters = [
      {
        id: "status",
        items: [
          { value: 0, render: "All" },
          ...(options?.statuses?.map((status) => {
            return {
              value: status.id,
              render: status.description,
            };
          }) || []),
        ],
        label: "Filter by status",
      },
      {
        id: "regionId",
        items: [
          { value: 0, render: "All" },
          ...(options.regions?.map((region) => {
            return {
              value: region.id,
              render: region.description,
            };
          }) || []),
        ],
        label: "Region",
      },
    ];

    if (!userId) {
      filters.push({
        id: "userId",
        items: [
          { value: 0, render: "All" },
          ...(options.users?.map((user) => {
            return {
              value: user.id,
              render: user.name,
            };
          }) || []),
        ],
        label: "Assigned to",
      });
    }
    return filters;
  }, [userId, options]);

  const filterBys = useMemo(() => {
    const filters = ["Description", "Company"];

    if (!userId) {
      filters.push("User");
    }
    return filters;
  }, [userId]);

  useEffect(() => {
    localStorage.setItem(
      "all-audits-page_filters",
      JSON.stringify({ sortBy: searchParams.get("sortBy") })
    );
  }, [searchParams.get("sortBy")]);

  useEffect(() => {
    if (!audits) return;

    setRows(
      audits.map((audit) => {
        return {
          type: (
            <MDBadge
              badgeContent={audit.type === EXTERNAL ? "" : ""}
              sx={{ m: -1, p: 0 }}
              color={audit.type === EXTERNAL ? "primary" : null}
            />
          ),
          branch: (
            <MDTypography
              variant="inherit"
              color="inherit"
              onClick={() => {
                if (permissions?.update || permissions?.get) {
                  navigate(`${audit.id}`);
                }
              }}
              sx={
                permissions?.update || permissions?.get
                  ? {
                      cursor: "pointer",
                    }
                  : null
              }
            >
              {audit.branch.branchGroup.name + " - " + audit.branch.name}
            </MDTypography>
          ),
          branchCsv: audit.branch.name,
          date: (
            <MDTypography variant="inherit" color="inherit">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(new Date(audit.date))}
            </MDTypography>
          ),
          dateCsv: new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(new Date(audit.date)),
          description: (
            <MDTypography
              variant="inherit"
              color="inherit"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {audit.description}
            </MDTypography>
          ),
          descriptionCsv: audit.description,
          assignedTo: (
            <MDBox display="flex" alignItems="center" gap={1}>
              <Avatar
                sx={{ width: 24, height: 24 }}
                src={audit.assignedTo?.profileImage}
              />
              <MDTypography variant="inherit" color="inherit">
                {audit.assignedTo.name}
              </MDTypography>
            </MDBox>
          ),
          assignedToCsv: audit.assignedTo.name,
          status: (
            <MDBadge
              badgeContent={audit.status.description}
              variant="gradient"
              color={STATUS_COLOR[audit.status.id]?.preset ?? null}
              container
              width="90px"
            ></MDBadge>
          ),
          statusCsv: audit.status.description,
          actions: (
            <>
              {permissions?.delete && (
                <IconButton
                  size="small"
                  onClick={() => {
                    changeOpen("alert", { audit });
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </>
          ),
        };
      })
    );
  }, [audits]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const loadingId = Math.random();
    let filters = localStorage.getItem("all-audits-page_filters");
    let sortBy;
    if (filters) {
      try {
        filters = JSON.parse(filters);
        if (filters.sortBy && !searchParams.get("sortBy")) {
          setSearchParams((curr) => {
            curr.set("sortBy", filters.sortBy);
            sortBy = filters.sortBy;
            return curr;
          });
        }
      } catch {}
    }
    //startLoading(loadingId);
    fetchAudits(source.token, sortBy, loadingId);
    return () => {
      source.cancel("Component unmounted");
      //stopLoading(loadingId);
    };
  }, []);

  return (
    <>
      {(can("create", "audit", {
        type: INTERNAL,
      }) ||
        can("create", "audit", {
          type: EXTERNAL,
        })) && (
        <AuditDialogv2
          open={Boolean(open.create)}
          setOpen={(value) => {
            changeOpen("create", value);
          }}
          type={open.create.type}
          onSubmit={handleCreateAudit}
        />
      )}
      <AlertDialog
        open={Boolean(open.alert)}
        setOpen={(value) => {
          changeOpen("alert", value);
        }}
        title={"Are you sure?"}
        content={"Remove this audit?"}
        onOk={async () => {
          return await handleDeleteAudit(open.alert.audit);
        }}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Audits {permissions.get ? `(${rows.length})` : ""}
                </MDTypography>
                <MDBox display="flex" flexDirection="row" gap={1}>
                  {can("create", "audit", {
                    type: INTERNAL,
                  }) &&
                  can("create", "audit", {
                    type: EXTERNAL,
                  }) ? (
                    <ExpandableButton
                      items={[
                        {
                          label: "For My Organization",
                          props: {
                            onClick: () => {
                              changeOpen("create", { type: INTERNAL });
                            },
                            disabled: !can("create", "audit", {
                              type: INTERNAL,
                            }),
                          },
                          tooltip: can(
                            "create",
                            "audit",
                            {
                              type: INTERNAL,
                            },
                            (reason) => reason.type,
                            () => null
                          ),
                        },
                        {
                          label: "For My Clients",
                          props: {
                            onClick: () => {
                              changeOpen("create", { type: EXTERNAL });
                            },
                            disabled: !can("create", "audit", {
                              type: EXTERNAL,
                            }),
                          },
                          tooltip: can(
                            "create",
                            "audit",
                            {
                              type: EXTERNAL,
                            },
                            (reason) => reason.type,
                            () => null
                          ),
                        },
                      ]}
                      disabled={
                        !can("create", "audit", {
                          type: INTERNAL,
                        }) &&
                        !can("create", "audit", {
                          type: EXTERNAL,
                        })
                      }
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;add new audit
                    </ExpandableButton>
                  ) : (
                    <Tooltip
                      title={
                        !can("create", "audit", {
                          type: EXTERNAL,
                        }) &&
                        !can("create", "audit", {
                          type: INTERNAL,
                        })
                          ? "You need a subscription to create audits"
                          : ""
                      }
                    >
                      <div>
                        <MDButton
                          onClick={() => {
                            const canCreateExternal = can("create", "audit", {
                              type: EXTERNAL,
                            });
                            const canCreateInternal = can("create", "audit", {
                              type: INTERNAL,
                            });

                            if (canCreateExternal) {
                              changeOpen("create", { type: EXTERNAL });
                            } else if (canCreateInternal) {
                              changeOpen("create", { type: INTERNAL });
                            } else {
                              console.log("Can create none");
                            }
                          }}
                          disabled={
                            !can("create", "audit", {
                              type: EXTERNAL,
                            }) &&
                            !can("create", "audit", {
                              type: INTERNAL,
                            })
                          }
                        >
                          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                          &nbsp;add new audit
                        </MDButton>
                      </div>
                    </Tooltip>
                  )}

                  {permissions.get && (
                    <CSVLink
                      className="downloadbtn"
                      filename={`audits_${Date()}.csv`}
                      data={csvData || [[]]}
                    >
                      <MDButton>
                        <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                        &nbsp;export
                      </MDButton>
                    </CSVLink>
                  )}
                </MDBox>
              </MDBox>
              {permissions?.get ? (
                <>
                  <MDBox p={2} mt={2}>
                    <TableSearch
                      id="all-audits"
                      externalLoading={
                        !(
                          (!permissions.get || options.statuses) &&
                          options.users &&
                          options.regions
                        )
                      }
                      internalLoading={!audits}
                      fullWidth
                      setData={setAudits}
                      setCount={setCount}
                      onSearch={async (request, callback) => {
                        if (userId) {
                          request.userId = userId;
                        }

                        setLastRequestQuery(request);

                        callback(
                          await auditsService.getAudits({
                            ...request,
                            skip:
                              (Number(searchParams.get("page") || 1) - 1) * 10,
                            take: 10,
                            sortBy: searchParams
                              .get("sortBy")
                              ?.replace("asce", "asc"),
                          })
                        );
                      }}
                      filterByDate={true}
                      label={"Search audits"}
                      filterBys={filterBys}
                      filterBySelectsItems={filterBySelectsItems}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <DataTable
                      table={{ columns: columns, rows: rows }}
                      isSorted={true}
                      canSort={[
                        "Date",
                        "Company",
                        "Description",
                        "Assigned to",
                        "Status",
                      ]}
                      loading={!audits}
                      isScrollable={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      manualSortFunction={(columnId, direction) => {
                        fetchAuditsOnly(null, null, `${columnId}-${direction}`);
                      }}
                      noEndBorder
                      setCsvData={setCsvData}
                    />
                    <MDBox sx={{ padding: 2 }}>
                      <Pagination
                        count={Math.floor(count / 10) + 1}
                        color={"info"}
                        variant="outlined"
                        page={Number(searchParams.get("page"))}
                        onChange={(_, newPage) => {
                          setSearchParams((curr) => {
                            curr.set("page", newPage);
                            return curr;
                          });

                          fetchAuditsOnly(null, newPage, null);
                        }}
                      />
                    </MDBox>
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
