export function checkPermission(permission, allPermissions) {
  const permissions = allPermissions.filter((actualPermission) => {
    return actualPermission.name.includes(`-${permission}`);
  });

  return {
    get: permissions.find((p) => {
      return p.name === `get-${permission}`;
    }),
    create: permissions.find((p) => {
      return p.name === `create-${permission}`;
    }),
    update: permissions.find((p) => {
      return p.name === `update-${permission}`;
    }),
    delete: permissions.find((p) => {
      return p.name === `delete-${permission}`;
    }),
  };
}

export function checkSubscriptionPermission(subscription) {
  return {
    audit: {
      create: {
        yes: false,
        reason: "",
      },
    },
    user: {
      create: {
        yes: false,
        reason: "",
      },
    },
    branch: {
      create: {
        yes: false,
        reason: "",
      },
    },
    branchGroup: {
      create: {
        yes: false,
        reason: "",
      },
    },
    subAccount: {
      create: {
        yes: false,
        reason: "",
      },
    },
  };
}
