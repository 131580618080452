import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import AccessDenied from "components/AccessDenied";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { useEffect, useMemo, useReducer, useState } from "react";
import auditTemplatesService from "services/audit-templates.service";
import RowTemplate from "./components/RowTemplate";
import TemplateDialog from "./components/TemplateDialog";
import AlertDialog from "components/AlertDialog";
import TableSearch from "components/TableSearch";

export default function AllTemplates({ permissions }) {
  const [originalTemplates, setOriginalTemplates] = useState();
  const [templates, setTemplates] = useState();
  const [open, changeOpen] = useReducer((state, action) => {
    return { ...state, [action.field]: action.value };
  }, {});

  async function handleFetchAuditTemplates() {
    const [response, data] = await auditTemplatesService.getAuditTemplates();
    if (response.ok) {
      setOriginalTemplates(data);
    }
  }

  async function handleCreateAuditTemplate(newAuditTemplate, requested) {
    const [response, data] = await auditTemplatesService.createAuditTemplate(
      newAuditTemplate,
      requested ? { request: true } : {}
    );
    if (response.ok) {
      setOriginalTemplates(data);
      return { success: true };
    } else {
      return { error: "Internal error occured" };
    }
  }

  async function handleUpdateAuditTemplate(id, newAuditTemplate) {
    const [response, data] = await auditTemplatesService.updateAuditTemplate(
      id,
      newAuditTemplate
    );
    if (response.ok) {
      setOriginalTemplates(data);
      return { success: true };
    } else {
      return { error: "Internal error occured" };
    }
  }

  async function handleRemoveAuditTemplate(id) {
    const [response, data] =
      await auditTemplatesService.removeAuditTemplate(id);
    if (response.ok) {
      setOriginalTemplates(data);
      return { success: true };
    } else {
      return { error: "Internal error occured" };
    }
  }

  function arrangeAuditTemplates(data) {
    return data.map((element) => {
      const template = {
        id: element.id,
        name: element.name,
        subcategoryId: element.subcategoryId,
        subcategory: element.subcategory,
        requestedAt: element.requestedAt,
        approvedAt: element.approvedAt,
        requestedFromId: element.requestedFromId,
      };
      const pillars = {};
      element.templateKpis.forEach((templateKpi) => {
        if (!templateKpi?.kpi?.pillar?.id) return;

        if (!pillars[templateKpi?.kpi?.pillar?.id]) {
          pillars[templateKpi?.kpi?.pillar?.id] = {
            ...templateKpi?.kpi?.pillar,
            kpis: [],
            weight: 0,
          };
        }
        pillars[templateKpi?.kpi?.pillar?.id].kpis.push({
          ...templateKpi.kpi,
          description: templateKpi.description,
        });
        pillars[templateKpi?.kpi?.pillar?.id].weight += templateKpi.kpi.weight;
      });

      template.pillars = Object.values(pillars);
      return template;
    });
  }

  const onClickTemplate = useMemo(() => {
    return {
      update: (template) => {
        const initial = originalTemplates.find((t) => t.id == template.id);
        initial.templateKpis.forEach((kpi, index) => {
          initial.templateKpis[index].kpiId =
            initial.templateKpis[index].kpi.id;
        });
        changeOpen({
          field: "templateDialog",
          value: { mode: "update", initial },
        });
      },
      remove: (id) => {
        changeOpen({ field: "alertRemoveDialog", value: { id } });
      },
      approve: (template) => {
        handleUpdateAuditTemplate(template.id, { approvedAt: new Date() });
      },
      duplicate: (template) => {
        const initial = originalTemplates.find((t) => t.id == template.id);
        initial.templateKpis.forEach((kpi, index) => {
          initial.templateKpis[index].kpiId =
            initial.templateKpis[index].kpi.id;
        });
        initial.name += " Copy";
        changeOpen({
          field: "templateDialog",
          value: { mode: "create", initial },
        });
      },
    };
  }, [changeOpen, originalTemplates]);

  useEffect(() => {
    handleFetchAuditTemplates();
  }, []);

  useEffect(() => {
    if (originalTemplates)
      setTemplates(arrangeAuditTemplates(originalTemplates));
  }, [originalTemplates]);

  return (
    <MDBox pt={6} pb={3}>
      <TemplateDialog
        open={Boolean(open.templateDialog)}
        mode={open.templateDialog?.mode}
        onClose={() => {
          changeOpen({ field: "templateDialog", value: false });
        }}
        onSubmit={async (data) => {
          if (open.templateDialog?.mode === "create")
            return await handleCreateAuditTemplate(data);

          if (open.templateDialog?.mode === "request")
            return await handleCreateAuditTemplate(data, true);

          if (open.templateDialog?.mode === "update")
            return await handleUpdateAuditTemplate(data.id, data);
        }}
        initial={open.templateDialog?.initial}
      />
      <AlertDialog
        open={open.alertRemoveDialog}
        setOpen={(value) => {
          changeOpen({ field: "alertRemoveDialog", value });
        }}
        title="Delete template?"
        content={
          "Are you sure you want to delete this template? This action is irreversible!"
        }
        onOk={async () => {
          await handleRemoveAuditTemplate(open.alertRemoveDialog.id);
        }}
      />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6" color="white">
                Templates
              </MDTypography>
              <MDBox sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                {/*permissions.create && (
                  <MDButton
                    onClick={() => {
                      changeOpen({
                        field: "templateDialog",
                        value: { mode: "request" },
                      });
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;request new template
                  </MDButton>
                )*/}
                {permissions.create && (
                  <MDButton
                    onClick={() => {
                      changeOpen({
                        field: "templateDialog",
                        value: { mode: "create" },
                      });
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new template
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
            {permissions.get ? (
              <>
                <MDBox p={2}>
                  <TableSearch
                    id="all-audit-templates"
                    internalLoading={!Boolean(originalTemplates)}
                    fullWidth
                    setData={setOriginalTemplates}
                    onSearch={async (request, callback) => {
                      callback(
                        await auditTemplatesService.getAuditTemplates(request)
                      );
                    }}
                    label={"Search templates"}
                  />
                </MDBox>
                <MDBox
                  sx={{
                    overflow: "hidden",
                    borderBottomRightRadius: "16px",
                    borderBottomLeftRadius: "16px",
                  }}
                >
                  {templates?.length
                    ? templates?.map((template) => {
                        return (
                          <RowTemplate
                            key={template?.id}
                            template={template}
                            onClick={onClickTemplate}
                          />
                        );
                      })
                    : null}

                  {!templates?.length && templates && (
                    <MDTypography
                      sx={{
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      No templates were found
                    </MDTypography>
                  )}
                  {!templates?.length && !templates && (
                    <MDTypography
                      sx={{
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      Loading...
                    </MDTypography>
                  )}
                </MDBox>
              </>
            ) : (
              <AccessDenied type="component" />
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
