import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LoadingButton from "components/LoadingButton";
import dayjs from "dayjs";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import AssessmentReportDialog from "./AssessmentReportDialog";

import CloseIcon from "@mui/icons-material/Close";

import { FormControlLabel, SvgIcon } from "@mui/material";
import React, { useMemo, useState } from "react";
import IndeterminateIcon from "components/IndeterminateIcon";

export default function AuditGenerateReportDialog({
  isOpen,
  audit,
  contacts,
  fetchContacts,
  siblingBranches,
  fetchSiblingBranches,
  handleEditAuditInfo,
}) {
  const [loading, setLoading] = React.useState({
    update: false,
  });
  const [open, setOpen] = React.useState(false);
  const [aReportDialogOpen, setAReportDialogOpen] = useState(false);
  const [auditTime, setAuditTime] = useState(audit.firstAnsweredAt);
  const [selectedSummaryGroups, setSelectedSummaryGroups] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const summaryGroups = useMemo(() => {
    const sumGrps = {};
    audit.pillars.forEach((pillar) => {
      if (pillar?.auditPillar?.summaryGroupId) {
        if (sumGrps[pillar.auditPillar.summaryGroupId]) {
          sumGrps[pillar.auditPillar.summaryGroupId].pillarsCount++;
        } else {
          sumGrps[pillar.auditPillar.summaryGroupId] = {
            ...pillar.auditPillar.summaryGroup,
            pillarsCount: 1,
          };
        }
      } else {
        if (sumGrps["none"]) {
          sumGrps["none"].pillarsCount++;
        } else {
          sumGrps["none"] = {
            pillarsCount: 1,
          };
        }
      }
    });

    return Object.values(sumGrps);
  }, [audit]);

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setSelectedSummaryGroups((curr) => {
      const newValue = { ...curr };

      summaryGroups.forEach((summaryGroup) => {
        newValue[summaryGroup.id] = true;
      });

      return newValue;
    });
  }, [isOpen]);

  React.useEffect(() => {
    setAuditTime(audit.firstAnsweredAt);
  }, [audit.firstAnsweredAt]);

  React.useEffect(() => {
    if (isOpen && audit) {
      if (!contacts) fetchContacts();
      if (!siblingBranches) fetchSiblingBranches();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpen && audit) {
      fetchContacts();
      fetchSiblingBranches();
    }
  }, [audit]);

  const filteredAudit = React.useMemo(() => {
    const summaryGroupIds = Object.keys(selectedSummaryGroups).filter(
      (k) => selectedSummaryGroups[k]
    );

    if (summaryGroupIds.length == 0) return { ...audit, pillars: [] };
    const pillars = audit.pillars.filter((pillar) => {
      return pillar?.auditPillar?.summaryGroupId
        ? summaryGroupIds.includes(`${pillar?.auditPillar?.summaryGroupId}`)
        : summaryGroupIds.includes("undefined");
    });
    return { ...audit, pillars };
  }, [selectedSummaryGroups, summaryGroups]);

  return (
    <>
      <AssessmentReportDialog
        summaryGroups={selectedSummaryGroups}
        isOpen={aReportDialogOpen}
        audit={filteredAudit}
        contacts={contacts}
        handleEditAuditInfo={handleEditAuditInfo}
        fetchSiblingBranches={fetchSiblingBranches}
      />
      <Dialog fullWidth={true} maxWidth={"md"} open={open}>
        <DialogTitle>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <MDTypography variant="h5">Generate Report</MDTypography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </MDBox>
        </DialogTitle>
        <DialogContent>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={1}
              sx={{
                paddingTop: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Audit Time"
                  required={true}
                  defaultValue={dayjs(auditTime)}
                  onChange={(newValue) => {
                    setAuditTime(new Date(newValue.toDate()));
                  }}
                />
              </LocalizationProvider>
              <MDTypography variant="caption">
                Change the audit time included in this report
              </MDTypography>
            </MDBox>
            <LoadingButton
              loading={loading.update}
              type="button"
              variant="gradient"
              color="info"
              onClick={async () => {
                setLoading({ ...loading, update: true });
                await handleEditAuditInfo({
                  firstAnsweredAt: auditTime,
                });
                setLoading({ ...loading, update: false });
              }}
              disabled={!auditTime || !dayjs(auditTime).isValid()}
            >
              Save
            </LoadingButton>
          </MDBox>

          <Divider variant="fullWidth" />
          <MDBox
            display="flex"
            justifyContent="space-between"
            gap={2}
            alignItems="start"
          >
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={1}
            >
              <MDTypography variant="h6">Assessment Report</MDTypography>
              <MDTypography variant="caption">
                Select required summary groups
              </MDTypography>

              <FormControlLabel
                label={`Select all`}
                control={
                  <Checkbox
                    checked={Boolean(
                      Object.values(selectedSummaryGroups).filter((v) => v)
                        .length == summaryGroups?.length
                    )}
                    indeterminate={Boolean(
                      Object.values(selectedSummaryGroups).filter((v) => v)
                        .length != summaryGroups?.length &&
                        Object.values(selectedSummaryGroups).filter((v) => v)
                          .length != 0
                    )}
                    indeterminateIcon={<IndeterminateIcon />}
                    onChange={(e) => {
                      const checked = e.target.checked;

                      setSelectedSummaryGroups((curr) => {
                        const newValue = { ...curr };

                        summaryGroups.forEach((summaryGroup) => {
                          newValue[summaryGroup.id] = checked;
                        });

                        return newValue;
                      });
                    }}
                  />
                }
              />
              <MDBox
                display="flex"
                flexDirection="column"
                gap={0}
                sx={{ paddingLeft: 2 }}
              >
                {summaryGroups?.map((summaryGroup) => (
                  <FormControlLabel
                    sx={{ padding: 0, margin: 0 }}
                    key={summaryGroup.id}
                    label={`${
                      summaryGroup.description || "No summary group"
                    } (${summaryGroup.pillarsCount} pillar${
                      summaryGroup.pillarsCount == 1 ? "" : "s"
                    })`}
                    control={
                      <Checkbox
                        checked={Boolean(
                          selectedSummaryGroups[summaryGroup.id]
                        )}
                        indeterminate={false}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (checked) {
                            setSelectedSummaryGroups((curr) => {
                              const newValue = { ...curr };
                              newValue[summaryGroup.id] = true;
                              return newValue;
                            });
                          } else {
                            setSelectedSummaryGroups((curr) => {
                              const newValue = { ...curr };
                              newValue[summaryGroup.id] = false;
                              return newValue;
                            });
                          }
                        }}
                      />
                    }
                  />
                ))}
              </MDBox>
            </MDBox>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <LoadingButton
                loading={false}
                type="button"
                variant="gradient"
                color="info"
                onClick={() => setAReportDialogOpen(Math.random())}
              >
                Generate
              </LoadingButton>
              {/* <LoadingButton
                loading={generatingLink}
                type="button"
                variant="gradient"
                color="primary"
                onClick={() => {
                  generateLink();
                }}
              >
                Generate link
              </LoadingButton>*/}
            </div>
          </MDBox>
        </DialogContent>
      </Dialog>
    </>
  );
}
