import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AllSubscriptions from "./components/AllSubscriptions.js";

import PageContainer from "components/PageContainer/index.js";
import Footer from "examples/Footer/index.js";
import { Route, Routes } from "react-router-dom";

import AccessDenied from "components/AccessDenied/index.js";
import { useAuth } from "contexts/auth.context";
import { usePermissionMonitor } from "components/PermissionMonitor/index.js";

function Subscriptions() {
  const { checkPermission, user } = useAuth();
  const can = usePermissionMonitor();

  if (!can("isAdmin") && !user.account.isExternalAuditor) return "";

  if (
    Object.keys(checkPermission("subscription")).filter(
      (key) => checkPermission("subscription")[key]
    ).length === 0
  ) {
    return <AccessDenied type="page" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <PageContainer>
        <Routes>
          <Route
            index
            element={
              <AllSubscriptions permissions={checkPermission("subscription")} />
            }
          />
        </Routes>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default Subscriptions;
