import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableSearch from "components/TableSearch";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import AlertDialog from "components/AlertDialog";
import MDButton from "components/MDButton";
import rolesService from "services/roles-service";
import usersService from "services/users-service";
import UserDialog from "./UserDialog";

import { Avatar, Tooltip } from "@mui/material";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import { usePermissionMonitor } from "components/PermissionMonitor";
import { useAuth } from "contexts/auth.context";
import accountsService from "services/accounts-service";

export default function AllUsers({
  onRefresh = () => {},
  type = "page",
  ...props
}) {
  const { data } = props;
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: "25%",
    },
    {
      Header: "Email",
      accessor: "email",
      width: "25%",
    },
    ...(!props?.data?.account
      ? [
          {
            Header: "Account",
            accessor: "account",
            width: "25%",
          },
        ]
      : []),
    {
      Header: "Role",
      accessor: "role",
      width: "25%",
    },
    {
      Header: "Color",
      accessor: "color",
      width: "25%",
    },

    /*{
      Header: "Access level",
      accessor: "accessLevel",
      width: "15%",
    },*/
  ];

  const { subscriptionPermissions, ...auth } = useAuth();
  const can = usePermissionMonitor();
  const [rows, setRows] = useState(null);
  const [loading, setLoading] = useState({ fetch: true });

  const [users, setUsers] = useState(null);
  const [roles, setRoles] = useState(null);
  const [accounts, setAccounts] = useState(null);

  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [userDialogAction, setUserDialogAction] = useState("create");
  const [currentUser, setCurrentUser] = useState(null);

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  if (can("update", "user") || can("delete", "user")) {
    columns.push({
      Header: "",
      accessor: "actions",
      align: "right",
    });
  }

  function updateRows() {
    setRows(
      users?.length > 0
        ? users?.map((user) => {
            return {
              name: (
                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                >
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    src={user.profileImage}
                  />
                  <MDTypography
                    variant="inherit"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {user.name}
                    &nbsp;&nbsp;&nbsp;
                    {user.isVerified ? (
                      <Tooltip title="User has been verified and a password has been setup.">
                        <Icon color="info" fontSize="small">
                          verified
                        </Icon>
                      </Tooltip>
                    ) : (
                      <Tooltip title="User has not been verified yet. No password was set for account.">
                        <Icon color="primary" fontSize="small">
                          new_releases
                        </Icon>
                      </Tooltip>
                    )}
                  </MDTypography>
                </MDBox>
              ),
              email: (
                <MDTypography variant="inherit">{user.email}</MDTypography>
              ),
              role: (
                <MDTypography variant="inherit">
                  {user.role?.description || ""}
                </MDTypography>
              ),
              color: (
                <Card
                  style={{
                    height: "20px",
                    width: "60px",
                    backgroundColor: user.color || "#eee",
                  }}
                />
              ),
              accessLevel: (
                <MDTypography variant="inherit">
                  {user.accessLevel?.description || ""}
                </MDTypography>
              ),

              account: (
                <MDTypography variant="inherit">
                  {user?.account?.name || "None"}
                </MDTypography>
              ),

              actions: (
                <>
                  {can("update", "user", user) && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setCurrentUser({ ...user });
                        setUserDialogAction("edit");
                        setUserDialogOpen(Math.random());
                      }}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )}
                  {can("delete", "user", user) && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setCurrentUser(user);
                        setAlertDialogOpen(Math.random());
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  )}
                </>
              ),
            };
          })
        : loading.fetch
          ? [{ name: "Loading..." }]
          : [{ name: "No users were found" }]
    );
  }

  async function handleGetRoles(cancelToken) {
    let [response, data] = await rolesService.getRoles(null, cancelToken);
    if (response.ok) {
      setRoles(data);
    } else {
    }
  }

  async function handleGetAccounts() {
    let [response, data] = await accountsService.getAccounts({ raw: true });
    if (response.ok) {
      setAccounts(data);
    } else {
    }
  }

  async function handleGetAccountsAndRoles(cancelToken) {
    setLoading((curr) => ({ ...curr, fetch: true }));

    const requests = [handleGetRoles(cancelToken), handleGetAccounts()];
    await Promise.all(requests);

    setLoading((curr) => ({ ...curr, fetch: false }));
  }

  useEffect(() => {
    updateRows();
  }, [users]);

  useEffect(() => {
    if (data) {
      return;
    }

    const source = axios.CancelToken.source();
    handleGetAccountsAndRoles(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    setRoles(data.roles);
    setUsers(data.users);
  }, [data]);

  async function handleCreateUser(newUser) {
    if (data) newUser.accountId = data.accountId;
    const [response, users] = await usersService.createUser(
      data ? { accountId: data.accountId } : {},
      newUser
    );
    if (response.ok) {
      setUsers(users);
      onRefresh(users);
      return { success: true };
    } else {
      console.log("ERROR while creating user: " + users.error);
      return { error: users.error };
    }
  }

  async function handleUpdateUser(newUser) {
    if (data) newUser.accountId = data.accountId;
    const [response, users] = await usersService.updateUser(
      newUser.id,
      data ? { accountId: data.accountId } : {},
      newUser
    );
    if (response.ok) {
      setUsers(users);
      onRefresh(users);
      if (newUser.id == auth.user.id) await auth.getUser();
      return { success: true };
    } else {
      console.log("ERROR while creating user: " + users.error);
      return { error: true };
    }
  }

  async function handleDeleteUser(user) {
    const [response, users] = await usersService.deleteUser(
      user.id,
      data ? { accountId: data.accountId } : {}
    );
    if (response.ok) {
      setUsers(users);
      onRefresh(users);
      return { success: true };
    } else {
      console.log("ERROR while creating user: " + users.error);
    }
  }

  const filterBySelectsItems = useMemo(() => {
    return accounts
      ? [
          {
            id: "accountId",
            items: [
              { value: 0, render: "All" },
              ...accounts.map((account) => {
                return {
                  value: account.id,
                  render: account.name,
                };
              }),
            ],
            label: "Filter by account",
          },
        ]
      : null;
  }, [accounts]);

  return (
    <>
      {roles && rows && (
        <>
          <UserDialog
            isOpen={userDialogOpen}
            action={userDialogAction}
            data={{
              roles: roles,
              user: currentUser,
              branchGroups: data?.branchGroups || [],
              branches: data?.branches || [],
              accounts: data?.accounts || [],
            }}
            accountId={props?.data?.accountId}
            onSubmit={async (newUser) => {
              if (userDialogAction == "create") {
                return await handleCreateUser(newUser);
              } else {
                return await handleUpdateUser(newUser);
              }
            }}
          />

          <AlertDialog
            open={alertDialogOpen}
            setOpen={setAlertDialogOpen}
            title="Delete user?"
            content={"Are you sure you want to delete this user?"}
            onOk={async () => {
              await handleDeleteUser(currentUser);
            }}
          />

          <MDBox sx={type === "component" ? { pt: 3 } : { pt: 6, pb: 3 }}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card
                  sx={type === "component" ? { all: "unset !important" } : null}
                >
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={type === "component" ? 2 : 3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDTypography variant="h6" color="white">
                      Users{" "}
                      {can("read", "user") ? `(${users?.length || 0})` : ""}
                    </MDTypography>

                    <Tooltip
                      title={can(
                        "create",
                        "user",
                        props?.data?.account,
                        (reason) => reason.message,
                        () => null
                      )}
                    >
                      <div>
                        <MDButton
                          disabled={
                            !can("create", "user", props?.data?.account)
                          }
                          size={type === "component" ? "small" : "medium"}
                          onClick={() => {
                            setUserDialogAction("create");
                            setUserDialogOpen(Math.random());
                          }}
                        >
                          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                          &nbsp;add new user
                        </MDButton>
                      </div>
                    </Tooltip>
                  </MDBox>
                  {can("read", "user") ? (
                    <>
                      {type === "component" ? null : (
                        <MDBox p={2} mt={2}>
                          <TableSearch
                            id="all-users"
                            internalLoading={!Boolean(users)}
                            fullWidth
                            setData={setUsers}
                            onSearch={async (request, callback) => {
                              if (request.accountId === 0) {
                                delete request.accountId;
                              }
                              callback(await usersService.getUsers(request));
                            }}
                            label={"Search users"}
                            filterBySelectsItems={filterBySelectsItems}
                          />
                        </MDBox>
                      )}
                      <MDBox sx={type === "component" ? { mt: 2 } : null}>
                        <DataTable
                          loading={!Boolean(users)}
                          table={{ columns: columns, rows: rows }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </>
                  ) : (
                    <AccessDenied type="component" />
                  )}
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </>
  );
}
