const STATUS_COLOR = {
  1: {
    light: "#DDDDDD",
    dark: "#627254",
    preset: "secondary",
  },
  2: {
    light: "#FFFF99",
    dark: "#666600",
    preset: "warning",
  },
  3: {
    light: "#99CCFF",
    dark: "#336699",
    preset: "info",
  },
  4: {
    light: "#99FF99",
    dark: "#006600",
    preset: "success",
  },
};

const SUBMITTED_STATUS_ID = 2;
const COMPLETED_STATUS_ID = 4;
const APPROVED_STATUS_ID = 7;

const GENERATE_REPORT_STATUS_IDS = [2, 4, 7];

const STD_ANSWER_TYPE_ID = 3;
const DEV_ANSWER_TYPE_ID = 4;
const NA_ANSWER_TYPE_ID = 5;

const INTERNAL = 1;
const EXTERNAL = 2;

const ACCOUNT_TYPE = {
  TECHNICAL_ADMINISTRATOR: 1,
  ADMINISTRATOR: 2,
  FRANCHISEE: 3,
  SUBSCRIBER: 4,
};

export {
  STATUS_COLOR,
  SUBMITTED_STATUS_ID,
  STD_ANSWER_TYPE_ID,
  DEV_ANSWER_TYPE_ID,
  NA_ANSWER_TYPE_ID,
  COMPLETED_STATUS_ID,
  APPROVED_STATUS_ID,
  GENERATE_REPORT_STATUS_IDS,
  INTERNAL,
  EXTERNAL,
  ACCOUNT_TYPE,
};
