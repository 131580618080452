import { useCallback, useState } from "react";

export default function useObjectState(initial = {}) {
  const [data, setData] = useState(initial);

  const handleChangeData = useCallback(
    (field, value) => {
      setData((curr) => ({ ...curr, [field]: value }));
    },
    [data, setData]
  );

  return [data, handleChangeData, setData];
}
