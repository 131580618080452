import { Avatar, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import AlertDialog from "components/AlertDialog";
import CollapsibleDataTable from "components/CollapsibleDataTable/index.v2";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TableSearch from "components/TableSearch";
import { useAuth } from "contexts/auth.context";
import { useLoading } from "contexts/loading.context";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import accountsService from "services/accounts-service";
import AccountsEditNewDialog from "./AccountsEditNewDialog";
import { usePermissionMonitor } from "components/PermissionMonitor";

export default function AllAccounts({ permissions }) {
  const { subscriptionPermissions, user } = useAuth();
  const loadingController = useLoading();
  const [accounts, setAccounts] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [rows, setRows] = useState([]);
  const [newDialogIsOpen, setNewDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const can = usePermissionMonitor();

  const navigate = useNavigate();

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: "100%",
      flexWrap: "nowrap",
      bodyCellStyle: {},
      firstField: true,
    },
  ];

  if (permissions.update || permissions.delete || permissions.get) {
    columns.push({
      Header: "",
      accessor: "actions",
      align: "right",
      width: "100%",
    });
  }

  async function fetch(cancelToken) {
    if (!permissions.get) return;
    loadingController.startLoading();
    const [response, data] = await accountsService.getAccounts(
      null,
      cancelToken
    );
    if (response.ok) {
      setAccounts(data);
    }
    loadingController.stopLoading();
  }

  async function handleAddAccount(newElement) {
    const [response, data] = await accountsService.createAccount(newElement);
    if (response.ok) {
      setAccounts(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleUpdateAccount(newElement) {
    const [response, data] = await accountsService.updateAccount(
      newElement.id,
      newElement
    );
    if (response.ok) {
      setAccounts(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleDeleteAccount(id) {
    const [response, data] = await accountsService.removeAccount(id);
    if (response.ok) {
      setAccounts(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  function updateRows() {
    function generateRow(account) {
      return {
        key: account.id,
        firstField: (
          <>
            <Avatar
              src={account.logo}
              name={account.name}
              sx={{ width: 32, height: 32 }}
            />

            <MDTypography
              variant="inherit"
              sx={{ fontSize: "1rem", fontWeight: "400" }}
            >
              {account.name}
            </MDTypography>
          </>
        ),

        actions: (
          <MDBox
            display="flex"
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
          >
            {/*permissions.create && !account.parentId && (

              <Tooltip title={subscriptionPermissions.subAccount.create.reason}>
                <div>
                  <IconButton
                    disabled={!subscriptionPermissions.subAccount.create.yes}
                    size="small"
                    onClick={() => {
                      navigate(`/accounts/new/${account.id}`);
                    }}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </div>
              </Tooltip>
            )*/}
            {!account.parentId &&
              can(
                "create",
                "subAccount",
                { account },
                (no) => {
                  return (
                    <Tooltip title={no.message}>
                      <div>
                        <IconButton
                          disabled={true}
                          size="small"
                          onClick={() => {
                            navigate(`/accounts/new/${account.id}`);
                          }}
                        >
                          <Icon>add</Icon>
                        </IconButton>
                      </div>
                    </Tooltip>
                  );
                },
                (yes) => {
                  return (
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigate(`/accounts/new/${account.id}`);
                      }}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  );
                }
              )}
            {(permissions.update || permissions.get) && (
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`/accounts/${account.id}`);
                }}
              >
                <Icon>{!permissions.update ? "arrow_forward" : "edit"}</Icon>
              </IconButton>
            )}

            {permissions.delete &&
              (!account.parentId
                ? !user.account ||
                  user.account?.accountTypeId === 1 ||
                  user.account?.accountTypeId === 2
                : true) && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setAlertIsOpen(Math.random());
                    setCurrentAccount(account);
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              )}
          </MDBox>
        ),
        children: account.accounts?.map((child) => generateRow(child)),
        id: account.id,
        parentId: account.parentId,
      };
    }
    setRows(
      accounts?.length > 0
        ? accounts.map((account) => {
            return generateRow(account);
          })
        : []
    );
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetch(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    updateRows();
  }, [accounts]);

  return (
    <>
      <AlertDialog
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        title={"Are you sure?"}
        content={"Delete this account?"}
        onOk={async () => {
          await handleDeleteAccount(currentAccount.id);
        }}
      />
      <AccountsEditNewDialog
        type="create"
        isOpen={newDialogIsOpen}
        onSubmit={handleAddAccount}
        data={currentAccount}
      />
      <AccountsEditNewDialog
        type="edit"
        isOpen={editDialogIsOpen}
        onSubmit={handleUpdateAccount}
        data={currentAccount}
      />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Accounts {permissions.get ? `(${accounts?.length || 0})` : ""}
                </MDTypography>
                {permissions.create &&
                  (!user.account ||
                    user.account?.accountTypeId === 1 ||
                    user.account?.accountTypeId === 2) && (
                    <MDButton
                      onClick={() => {
                        navigate(`/accounts/new/0`);
                      }}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;add new account
                    </MDButton>
                  )}
              </MDBox>
              {permissions.get ? (
                <>
                  <MDBox p={2} mt={2}>
                    <TableSearch
                      id="all-accounts"
                      internalLoading={!Boolean(accounts)}
                      fullWidth
                      setData={setAccounts}
                      onSearch={async (request, callback) => {
                        callback(await accountsService.getAccounts(request));
                      }}
                      label={"Search accounts"}
                    />
                  </MDBox>
                  <MDBox>
                    <CollapsibleDataTable
                      loading={!Boolean(accounts)}
                      table={{ columns: columns, rows: rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
