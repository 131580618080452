import HttpService from "./htttp.service";

class ThemeService {
  createColorPalette = async (payload, params) => {
    const endpoint = "themes";
    return await HttpService.post(endpoint, payload, params);
  };

  getTheme = async (params, cancelToken) => {
    const endpoint = "themes";
    return await HttpService.get(endpoint, params, cancelToken);
  };

  getColorPalettes = async (params) => {
    const endpoint = "themes";
    return await HttpService.get(endpoint, params);
  };

  updateColorPalette = async (id, payload, params) => {
    const endpoint = "themes/" + id;
    return await HttpService.put(endpoint, payload, params);
  };

  removeColorPalette = async (id, params) => {
    const endpoint = "themes/" + id;
    return await HttpService.delete(endpoint, params);
  };
}

export default new ThemeService();
