import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DropdownSelect from "components/DropdownSelect";
import Avatar from "components/FileAvatar";
import LoadingButton from "components/LoadingButton";
import MDBox from "components/MDBox";
import MDSelect from "components/MDSelect";
import * as React from "react";
import toast from "react-hot-toast";

export default function BranchGroupDialog({
  isOpen,
  action,
  data,
  onSubmit,
  fetchSubscriptions,
  fetchColorPalettes,
  fetchAccountManagers,
  fetchAccounts,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState({
    name: "",
    email: "",
    phone: "",
    logo: "",
    colorPaletteId: 1,
    //subscriptionId: 1,
    accountManagerId: 1,
    accountId: data.accountId,
  });

  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (!isOpen) return;

    setOpen(true);
    setLoading(false);
    setError(null);

    if (data?.subscriptions?.length === 0) fetchSubscriptions();
    if (!data?.colorPalettes) fetchColorPalettes();
    //if (!data?.accountManagers) fetchAccountManagers();
    if (!data?.accounts && !data.accountId) fetchAccounts();

    if (action == "create") {
      setValue({
        name: "",
        email: "",
        phone: "",
        logo: "",
        colorPaletteId: 1,
        accountManagerId: 1,
        accountId: data.accountId,
      });
    } else {
      setValue(data?.branchGroup);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (action != "create") {
      setValue(data?.branchGroup);
    }
  }, [data]);

  React.useEffect(() => {
    if (!value.accountId) return;

    fetchAccountManagers(value.accountId);
  }, [value.accountId]);

  const handleClose = () => {
    setOpen(false);
  };

  const accountManagerOptions = React.useMemo(() => {
    return (
      data?.accountManagers?.map((accountManager) => {
        return {
          id: accountManager.id,
          label: accountManager.name,
        };
      }) || []
    );
  }, [data?.accountManagers]);

  const accountOptions = React.useMemo(() => {
    return (
      data?.accounts?.map((account) => {
        return {
          id: account.id,
          label: account.name,
        };
      }) || []
    );
  }, [data?.accounts]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          delete value.branches;
          delete value.colorPalette;
          if (data.accountId) value.accountId = data.accountId;

          const result = await onSubmit(value);

          if (result.success) {
            handleClose();
          } else {
            console.log("ERROR IS: ", result.error);
            setError(result.error);
            if (!result.error.fields)
              toast.error(`${result.error.message}\n\nTry refreshing the page`);
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>
        {action == "edit" ? "Edit" : "Create"}
        {" branch group"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Avatar
                attachment={value.logo == "reset" ? null : value.logo}
                setAttachment={(file) => {
                  setValue({ ...value, logo: file ? file : "reset" });
                }}
                sx={{
                  aspectRatio: "1 !important",
                  height: "calc(100% - 12px)",
                  width: "160px",
                }}
              />
            </Grid>
            <Grid container item xs>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  error={error?.fields === "name"}
                  helperText={error?.fields === "name" && error?.message}
                  id="branch group name"
                  name="branch group name"
                  label="Name"
                  type="text"
                  value={value.name}
                  onChange={(e) => {
                    setValue({ ...value, name: e.target.value });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  error={error?.fields === "email"}
                  helperText={error?.fields === "email" && error?.message}
                  id="branch group email"
                  name="branch group email"
                  label="Email"
                  type="text"
                  value={value.email}
                  onChange={(e) => {
                    setValue({ ...value, email: e.target.value });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  error={error?.fields === "phone"}
                  helperText={error?.fields === "phone" && error?.message}
                  id="branch group phone"
                  name="branch group phone"
                  label="Phone"
                  type="text"
                  value={value.phone}
                  onChange={(e) => {
                    setValue({ ...value, phone: e.target.value });
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          {data.accountId ? null : (
            <Grid item xs={12}>
              <DropdownSelect
                label={"Account"}
                initialValue={
                  value.accountId == 0 || !value.accountId
                    ? ""
                    : value.accountId
                }
                onChange={(newValue) => {
                  setValue({ ...value, accountId: newValue?.id });
                }}
                items={accountOptions}
                required={true}
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <MDSelect
              fullWidth
              required
              value={value.colorPaletteId}
              label={"Color palette"}
              items={(data?.colorPalettes || []).map((cp) => {
                return {
                  value: cp.id,
                  render: (
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ width: "100%", gap: ".1rem" }}
                    >
                      <MDBox
                        sx={{
                          flex: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: ".8rem",
                          fontWeight: "600",
                        }}
                      >
                        {cp.description}
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        sx={{ flex: 1 }}
                      >
                        <MDBox
                          sx={{
                            height: 24,
                            flex: 3,
                            backgroundColor: cp.color1,
                          }}
                        ></MDBox>
                        <MDBox
                          sx={{
                            flex: 2,
                            height: 24,
                            backgroundColor: cp.color2,
                          }}
                        ></MDBox>
                        <MDBox
                          sx={{
                            flex: 1,
                            height: 24,
                            backgroundColor: cp.color3,
                          }}
                        ></MDBox>
                      </MDBox>
                    </MDBox>
                  ),
                };
              })}
              onChange={(newValue) => {
                setValue({ ...value, colorPaletteId: newValue });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DropdownSelect
              disabled={!value.accountId}
              inputProps={
                !value.accountId
                  ? {
                      helperText: "Select account first",
                    }
                  : {}
              }
              label={"Branch manager"}
              initialValue={
                value.accountManagerId == 0 || !value.accountManagerId
                  ? ""
                  : value.accountManagerId
              }
              onChange={(newValue) => {
                setValue({ ...value, accountManagerId: newValue?.id });
              }}
              items={accountManagerOptions}
              //required={true}
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={12} sx={{ mt: 1 }}>
            <MSelect
              error={error?.fields === "subscriptionId"}
              helperText={error?.fields === "subscriptionId" && error?.message}
              items={data?.subscriptions.map((item, index) => {
                return {
                  value: item.id,
                  render: item.name,
                };
              })}
              value={value.subscriptionId}
              onChange={(e) => {
                setValue({ ...value, subscriptionId: e });
              }}
              label="Subscription type*"
              required
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {action == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
