import { Card, Chip, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

import PillarsTable from "components/PillarsTable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/StyledAccordion";
import { useState } from "react";

export default function RowTemplate({ template, onClick }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        aria-controls={template.id + "d-content"}
        id={template.id + "d-header"}
      >
        <Grid
          container
          sx={{ display: "flex", flexDirection: "row", gap: 4 }}
          alignItems={"center"}
        >
          <Grid item xs>
            <MDTypography
              sx={{
                fontSize: "1rem",
                fontWeight: "400",
              }}
            >
              {template.name}
            </MDTypography>
          </Grid>
          <Grid item xs>
            <Chip variant="outlined" label={template.subcategory.description} />
          </Grid>
          {/* <Grid item xs>
            <MDBadge
              badgeContent={
                !template.requestedAt
                  ? "Internal"
                  : template.approvedAt
                    ? "Approved"
                    : "Requested"
              }
              color={
                !template.requestedAt
                  ? "info"
                  : template.approvedAt
                    ? "success"
                    : "warning"
              }
            />
          </Grid>*/}

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            {/* <Tooltip title="Approve">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick.approve(template);
                }}
              >
                <Icon>check_circle_outline</Icon>
              </IconButton>
            </Tooltip>*/}
            <Tooltip title="Duplicate">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick.duplicate(template);
                }}
              >
                <Icon>copy</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick.update(template);
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick.remove(template.id);
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{ backgroundColor: true ? "rgba(0, 0, 0, 0.05)" : "white" }}
      >
        <Grid container spacing={2}>
          {/*<Grid item sx={{ width: "100%" }}>
            <Card
              variant="outlined"
              sx={{ borderRadius: "8px", width: "100%" }}
            >
              <MDTypography variant="h6" sx={{ px: 2, py: 1 }}>
                Info
              </MDTypography>
              <MDBox p={1}>
                <MDInput
                  label="Description"
                  sx={{
                    width: "100%",
                    backgroundColor: "white !important",
                  }}
                />
              </MDBox>
            </Card>
          </Grid>*/}
          <Grid item sx={{ width: "100%" }}>
            <Card
              variant="outlined"
              sx={(theme) => ({
                borderRadius: "8px",
                width: "100%",
                overflow: "hidden",
              })}
            >
              <MDTypography variant="h6" sx={{ px: 2, pt: 1, pb: 2 }}>
                Pillars
              </MDTypography>
              <PillarsTable pillars={template.pillars} />
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
