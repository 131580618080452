import { Chip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import AccessDenied from "components/AccessDenied";
import AlertDialog from "components/AlertDialog";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { usePermissionMonitor } from "components/PermissionMonitor";
import TableSearch from "components/TableSearch";
import { EXTERNAL, INTERNAL } from "const";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import subscriptionsService from "services/subscriptions-service";
import SubscriptionDialog from "./SubscriptionDialog";

export default function AllSubscriptions({ permissions, ...props }) {
  const { type = "page", data, onRefresh = () => {} } = props;

  const [rows, setRows] = useState([]);
  const [subscriptions, setSubscriptions] = useState(null);

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [subscriptionDialogAction, setSubscriptionDialogAction] =
    useState("create");
  const [currentSubscription, setCurrentSubscription] = useState(null);

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  const can = usePermissionMonitor();
  const [subscriptionType, setSubscriptionType] = useState(
    can("isAdmin") ? 1 : 2
  );

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: "30%",
    },
    {
      Header: "Description",
      accessor: "description",
      width: "100%",
    },
    ...(!props?.data?.account && subscriptionType == EXTERNAL
      ? [{ Header: "Account", accessor: "account", width: "40%" }]
      : []),
    {
      Header: "Price",
      accessor: "price",
      width: "15%",
    },
    { Header: "Period", accessor: "period", width: "15%" },
  ];

  if (permissions?.update || permissions?.delete) {
    columns.push({
      Header: "Actions",
      accessor: "actions",
      width: "10%",
    });
  }

  function updateRows() {
    setRows(
      subscriptions?.length > 0
        ? subscriptions.map((subscription) => {
            return {
              name: (
                <MDTypography variant="inherit">
                  {subscription.name}
                </MDTypography>
              ),
              description: (
                <MDTypography variant="inherit">
                  {subscription.description}
                </MDTypography>
              ),
              price: (
                <MDTypography variant="inherit">
                  {subscription.price}
                </MDTypography>
              ),
              account: (
                <MDTypography variant="inherit">
                  {subscription.account?.name || "None"}
                </MDTypography>
              ),
              period: (
                <MDTypography variant="inherit">
                  {subscription.period
                    ? subscription.period + " Months"
                    : "None"}
                </MDTypography>
              ),
              maxAuditsMonth: (
                <MDTypography variant="inherit">
                  {subscription.maxAuditsMonth >= 0
                    ? subscription.maxAuditsMonth
                    : "Unlimited"}
                </MDTypography>
              ),
              actions: (
                <>
                  {permissions?.update && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setCurrentSubscription({ ...subscription });
                        setSubscriptionDialogAction("edit");
                        setSubscriptionDialogOpen(Math.random());
                      }}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )}
                  {permissions?.delete && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setCurrentSubscription(subscription);
                        setAlertDialogOpen(Math.random());
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  )}
                </>
              ),
            };
          })
        : subscriptions?.length === 0
          ? [{ name: "No subscriptions were found" }]
          : [{ name: "Loading..." }]
    );
  }

  useEffect(() => {
    if (subscriptions) {
      updateRows();
    }
  }, [subscriptions]);

  async function handleCreateSubscription(newSubscription) {
    if (props?.data?.accountId)
      newSubscription.accountId = props?.data?.accountId;
    const [response, data] = await subscriptionsService.createSubscription(
      {
        ...newSubscription,
        auditLimitPerMonth: parseInt(newSubscription.auditLimitPerMonth),
        price: parseFloat(newSubscription.price),
      },
      props?.data
        ? { accountId: props?.data?.accountId }
        : {
            mine: true,
            ...(subscriptionType == INTERNAL
              ? { internal: true }
              : { external: true }),
          }
    );
    if (response.ok) {
      setSubscriptionType(`${subscriptionType}`);
      setSubscriptions(data);
      onRefresh(data);
      return { success: true };
    } else {
      console.log("ERROR while creating subscription: " + data.error);
      return { error: data.error };
    }
  }

  async function handleUpdateSubscription(newSubscription) {
    if (props?.data?.accountId)
      newSubscription.accountId = props?.data?.accountId;
    const [response, data] = await subscriptionsService.updateSubscription(
      newSubscription.id,
      props?.data
        ? { accountId: props?.data?.accountId }
        : {
            mine: true,
            ...(subscriptionType == INTERNAL
              ? { internal: true }
              : { external: true }),
          },
      {
        ...newSubscription,
        auditLimitPerMonth: parseInt(newSubscription.auditLimitPerMonth),
        price: parseFloat(newSubscription.price),
      }
    );
    if (response.ok) {
      setSubscriptionType(`${subscriptionType}`);
      setSubscriptions(data);
      onRefresh(data);
      return { success: true };
    } else {
      console.log("ERROR while updating subscription: " + data.error);
    }
  }

  async function handleDeleteSubscription(subscription) {
    const [response, data] = await subscriptionsService.removeSubscription(
      subscription.id,
      props?.data
        ? { accountId: props?.data?.accountId }
        : {
            mine: true,
            ...(subscriptionType == INTERNAL
              ? { internal: true }
              : { external: true }),
          }
    );
    if (response.ok) {
      setSubscriptionType(`${subscriptionType}`);
      setSubscriptions(data);
      onRefresh(data);
      return { success: true };
    } else {
      console.log("ERROR while deleting subscription: " + data.error);
    }
  }

  useEffect(() => {
    if (!data) {
      return;
    }

    setSubscriptions(data.subscriptions);
  }, [data]);

  return (
    <>
      <SubscriptionDialog
        isOpen={subscriptionDialogOpen}
        action={subscriptionDialogAction}
        data={currentSubscription}
        onSubmit={async (newSubscription) => {
          if (subscriptionDialogAction == "create") {
            return await handleCreateSubscription(newSubscription);
          } else {
            return await handleUpdateSubscription(newSubscription);
          }
        }}
        accountId={data?.accountId}
      />

      <AlertDialog
        open={alertDialogOpen}
        setOpen={setAlertDialogOpen}
        title="Delete subscription?"
        content={"Are you sure you want to delete this subscription?"}
        onOk={async () => {
          await handleDeleteSubscription(currentSubscription);
        }}
      />

      <MDBox
        sx={
          type === "component"
            ? { pt: 3 }
            : {
                pt: 6,
                pb: 3,
              }
        }
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={type === "component" ? 2 : 3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Subscriptions {permissions?.get ? `(${rows?.length})` : ""}
                </MDTypography>
                {permissions?.create && (
                  <MDButton
                    size={type === "component" ? "small" : "medium"}
                    onClick={() => {
                      setSubscriptionDialogAction("create");
                      setSubscriptionDialogOpen(Math.random());
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new subscription
                  </MDButton>
                )}
              </MDBox>
              {permissions?.get ? (
                <>
                  {type === "component" ? null : (
                    <>
                      {!data?.accountId && can("isAdmin") ? (
                        <MDBox
                          px={2}
                          mt={2}
                          display="flex"
                          flexDirection="row"
                          gap={1}
                        >
                          <Chip
                            label="Self-Service"
                            onClick={() => setSubscriptionType(INTERNAL)}
                            color={
                              subscriptionType == INTERNAL ? "info" : undefined
                            }
                            sx={
                              subscriptionType == INTERNAL
                                ? { color: "white !important" }
                                : undefined
                            }
                            variant={
                              subscriptionType == INTERNAL
                                ? undefined
                                : "outlined"
                            }
                          />
                          <Chip
                            label="Third-Party"
                            onClick={() => setSubscriptionType(EXTERNAL)}
                            color={
                              subscriptionType == EXTERNAL ? "info" : undefined
                            }
                            sx={
                              subscriptionType == EXTERNAL
                                ? { color: "white !important" }
                                : undefined
                            }
                            variant={
                              subscriptionType == EXTERNAL
                                ? undefined
                                : "outlined"
                            }
                          />
                        </MDBox>
                      ) : null}
                      <MDBox p={2}>
                        <TableSearch
                          internalLoading={!subscriptions}
                          id="all-subscriptions"
                          fullWidth
                          setData={setSubscriptions}
                          onSearch={async (request, callback) => {
                            callback(
                              await subscriptionsService.getSubscriptions({
                                ...request,
                                mine: true,
                              })
                            );
                          }}
                          refreshSignal={subscriptionType}
                          refreshSignalToQuery={(signal) => {
                            return signal == INTERNAL
                              ? { internal: true }
                              : { external: true };
                          }}
                          label={"Search subscriptions"}
                        />
                      </MDBox>
                    </>
                  )}
                  <MDBox mt={type === "component" ? 2 : null}>
                    <DataTable
                      loading={!Boolean(subscriptions)}
                      table={{ columns: columns, rows: rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
