import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AllRoles from "./components/AllRoles.js";

import PageContainer from "components/PageContainer/index.js";
import Footer from "examples/Footer/index.js";
import { Route, Routes } from "react-router-dom";

import AccessDenied from "components/AccessDenied/index.js";
import { useAuth } from "contexts/auth.context";

function Roles() {
  const { checkPermission } = useAuth();

  if (
    Object.keys(checkPermission("role")).filter(
      (key) => checkPermission("role")[key]
    ).length === 0
  ) {
    return <AccessDenied type="page" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PageContainer>
        <Routes>
          <Route
            index
            element={<AllRoles permissions={checkPermission("role")} />}
          />
        </Routes>
      </PageContainer>
      <Footer />
    </DashboardLayout>
  );
}

export default Roles;
