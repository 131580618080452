import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import DropdownSelect from "components/DropdownSelect";
import LoadingButton from "components/LoadingButton";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PillarsTable from "components/PillarsTable";
import { useAuth } from "contexts/auth.context";
import { useCallback, useEffect, useMemo, useState } from "react";
import accountsService from "services/accounts-service";
import categoriesService from "services/categories-service";
import pillarsService from "services/pillars-service";

export default function TemplateDialog({
  open,
  onClose,
  onSubmit,
  mode,
  initial,
}) {
  const [value, setValue] = useState({});
  const [updated, setUpdated] = useState({});
  const [loading, setLoading] = useState({ save: false });
  const [pillars, setPillars] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const auth = useAuth();

  async function handleFetchPillars(subcategoryId) {
    const [response, data] = await pillarsService.getPillars({ subcategoryId });
    if (response.ok) {
      setPillars(data);
    }
  }

  async function handleFetchSubcategories() {
    const [response, data] = await categoriesService.getCategories({
      raw: true,
    });
    if (response.ok) {
      setSubcategories(data);
    }
  }

  async function handleFetchAccounts() {
    const [response, data] = await accountsService.getAccounts({ raw: true });
    if (response.ok) {
      setAccounts(data);
    }
  }

  const handleChange = useCallback(
    (field, value) => {
      setValue((curr) => ({ ...curr, [field]: value }));
      setUpdated((curr) => ({ ...curr, [field]: value }));
    },
    [setValue, setUpdated]
  );

  const onChangeSelected = useCallback(
    (templateKpis) => {
      handleChange("templateKpis", templateKpis);
    },
    [handleChange]
  );

  function handleChangeLoading(field, value) {
    setLoading((curr) => ({ ...curr, [field]: value }));
  }

  const initialProp = useMemo(() => {
    return initial?.templateKpis || [];
  }, [initial]);

  const dropdownOptions = useMemo(() => {
    return {
      subcategories:
        subcategories?.map((subcategory) => {
          return {
            id: subcategory.id,
            label: subcategory.description,
            category: subcategory.category.description,
          };
        }) || [],

      accounts:
        accounts?.map((account) => {
          return {
            id: account.id,
            label: account.name,
          };
        }) || [],
    };
  }, [subcategories, accounts]);

  useEffect(() => {
    handleFetchSubcategories();
    handleFetchAccounts();
  }, []);

  useEffect(() => {
    if (mode === "create") {
      if (initial) {
        setValue({
          name: initial.name,
          subcategoryId: initial.subcategoryId,
          templateKpis: initial.templateKpis,
        });
        setUpdated({
          name: initial.name,
          subcategoryId: initial.subcategoryId,
          templateKpis: initial.templateKpis.map((templateKpi) => ({
            kpiId: templateKpi.kpi.id,
            description: templateKpi.description,
          })),
        });
      } else {
        setValue({ name: "", templateKpis: [] });
        setUpdated({});
      }
    } else if (mode === "update") {
      setValue(initial);
      setUpdated({});
    }
  }, [mode, initial]);

  useEffect(() => {
    if (value.subcategoryId) {
      handleFetchPillars(value.subcategoryId);
    } else {
      setPillars([]);
    }
  }, [value.subcategoryId, mode]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          handleChangeLoading("save", true);
          const response = await onSubmit({ id: value.id, ...updated });
          if (response.success) {
            onClose();
          }
          handleChangeLoading("save", false);
        },
      }}
      fullWidth
    >
      <DialogTitle>
        {mode === "create"
          ? initial
            ? "Duplicate Template"
            : "Create New Template"
          : mode === "request"
            ? `Request Template from ${auth.user?.account?.externalSubscription?.account?.name || ""}`
            : "Edit Template"}
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -1,
          pt: "0.5rem !important",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Card variant="elevation" sx={{ p: 1 }}>
          <MDTypography sx={{ mb: 1 }} variant="h6">
            Info
          </MDTypography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MDInput
                label="Name"
                fullWidth
                value={value.name || ""}
                onChange={(e) => {
                  handleChange("name", e.target.value);
                }}
                required
              />
            </Grid>
            <Grid item xs={mode === "create" ? 8 : 12}>
              <DropdownSelect
                label={"Category"}
                id="category-selector"
                initialValue={
                  value.subcategoryId == 0 || !value.subcategoryId
                    ? ""
                    : value.subcategoryId
                }
                onChange={(newValue) => {
                  handleChange("subcategoryId", newValue?.id);
                }}
                items={dropdownOptions.subcategories}
                required={true}
                groupBy={(option) => option.category}
                fullWidth
                readOnly={mode === "update"}
              />
            </Grid>

            {mode === "create" && (
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <MDTypography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "400",
                    lineHeight: "1rem",
                  }}
                  color="secondary"
                >
                  The category <strong>cannot be changed later</strong>. You can
                  only choose a category upon creation of the template.
                </MDTypography>
              </Grid>
            )}
            <Grid item xs>
              <DropdownSelect
                label={"Account"}
                initialValue={!value.accountId ? "" : value.accountId}
                onChange={(newValue) => {
                  handleChange("accountId", newValue?.id);
                }}
                items={dropdownOptions?.accounts}
                required={true}
                fullWidth
              />
            </Grid>
          </Grid>
        </Card>
        <Card variant="elevation" sx={{ overflow: "hidden" }}>
          <MDTypography sx={{ p: 1 }} variant="h6">
            Pillars & Kpis
          </MDTypography>
          <PillarsTable
            pillars={pillars}
            initial={initialProp}
            onChangeSelected={onChangeSelected}
            updatableDescription
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </MDButton>
        <LoadingButton
          type="submit"
          variant="gradient"
          color="info"
          loading={loading.save}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
