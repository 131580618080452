import { Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import { useState } from "react";

export default function ExpandableButton({ children, items, ...props }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MDButton
        onClick={(e) => {
          setOpen(e.currentTarget);
        }}
        endIcon={<Icon fontSize="large">keyboard_arrow_down</Icon>}
        {...props}
      >
        {children}
      </MDButton>
      <Menu
        open={open}
        anchorEl={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",

              mt: 1.5,

              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 19,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        {items.map((item) => {
          return (
            <Tooltip
              title={item.tooltip || ""}
              key={"ExpandableButtonItem: " + item.label}
            >
              <div>
                <MenuItem
                  sx={{
                    p: 1,
                    px: 2,
                    m: 0,
                    mx: -1,
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                  {...item.props}
                >
                  {typeof item.label === "string"
                    ? item.label.toUpperCase()
                    : item.label}
                </MenuItem>
              </div>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
}
