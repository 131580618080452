import { ACCOUNT_TYPE, EXTERNAL, INTERNAL } from "const";
import { useAuth } from "contexts/auth.context";
import { useEffect, useMemo } from "react";
import { checkPermission } from "utils/permission";

// if blocked, reason:
/*
{
    type: "subscription" | "access level" | "role",
    message: ""
}
*/
// else return true;

function isUnderMyAccounts(user, id) {
  const acceptableAccountIds = [
    user.account.id,
    ...(user.account?.accounts?.map((account) => account.id) || []),
  ];

  return acceptableAccountIds.includes(id);
}

function isAdmin(user) {
  return (
    user.account?.accountTypeId === ACCOUNT_TYPE.TECNICAL_ADMINISTRATOR ||
    user.account?.accountTypeId === ACCOUNT_TYPE.ADMINISTRATOR
  );
}

const permissionsMatrix = {
  user: {
    create: (user, data) => {
      if (!checkPermission("user", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      const account = data || user.account;
      if (account) {
        if (!account?.internalSubscription) {
          return {
            type: "subscription",
            message: "You need a subscription to create users",
          };
        }
        const maxUsers = account?.internalSubscription?.subscription.maxUsers;
        if (maxUsers >= 0 && account.counts.internal.users >= maxUsers) {
          return {
            type: "subscription",
            message: `Upgrade subscription to create more users (${user.account.counts.internal.users}/${maxUsers})`,
          };
        }
      }

      return true;
    },
    read: (user, data) => {
      if (!checkPermission("user", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("user", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    delete: (user, data) => {
      if (!checkPermission("user", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
  },
  role: {
    create: (user, data) => {
      if (!checkPermission("role", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    read: (user, data) => {
      if (!checkPermission("role", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("role", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    delete: (user, data) => {
      if (!checkPermission("role", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
  },
  category: {
    create: (user, data) => {
      if (!checkPermission("category", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    read: (user, data) => {
      if (!checkPermission("category", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("category", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    delete: (user, data) => {
      if (!checkPermission("category", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
  },
  colorPalette: {
    create: (user, data) => {
      if (
        !checkPermission("color palette", user.role?.permissions || []).create
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
        if (!user.account?.internalSubscription)
          return {
            type: "subscription",
            message: "You need a subscription to create color palettes",
          };

        /* const maxColorPalettes =
          user.account?.internalSubscription?.maxColorPalettes;
        if (
          maxColorPalettes > 0 &&
          user.account._count.colorPalettes >= maxColorPalettes
        ) {
          return {
            type: "subscription",
            message: `Upgrade subscription to create more color palettes (${user.account._count.colorPalettes}/${maxColorPalettes})`,
          };
        }*/
      }

      return true;
    },
    read: (user, data) => {
      if (!checkPermission("color palette", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    update: (user, data) => {
      if (
        !checkPermission("color palette", user.role?.permissions || []).update
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    delete: (user, data) => {
      if (
        !checkPermission("color palette", user.role?.permissions || []).delete
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
  },
  kpi: {
    create: (user, data) => {
      if (!checkPermission("kpi", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    read: (user, data) => {
      if (!checkPermission("kpi", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("kpi", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    delete: (user, data) => {
      if (!checkPermission("kpi", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
  },
  branchGroup: {
    create: (user, data) => {
      if (
        !checkPermission("branchGroup", user.role?.permissions || []).create
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      const account = data || user.account;

      if (account) {
        if (!account?.internalSubscription)
          return {
            type: "subscription",
            message: "You need a subscription to create branch groups",
          };
        const maxBranchGroups =
          account?.internalSubscription?.subscription.maxBranchGroups;

        const branchGroupsCount = account.counts.internal.branchGroups;

        if (maxBranchGroups >= 0 && branchGroupsCount >= maxBranchGroups) {
          return {
            type: "subscription",
            message: `Upgrade subscription to create more branch groups (${branchGroupsCount}/${maxBranchGroups})`,
          };
        }
      }

      return true;
    },
    read: (user, data) => {
      if (!checkPermission("branchGroup", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("branchGroup", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    delete: (user, data) => {
      if (
        !checkPermission("branchGroup", user.role?.permissions || []).delete
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.accountId && user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
  },
  branch: {
    create: (user, data) => {
      if (!checkPermission("branch", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (user.account) {
        if (!user.account?.internalSubscription)
          return {
            type: "subscription",
            message: "You need a subscription to create branches",
          };
        /* const maxBranches = user.account?.internalSubscription?.maxBranches;
        if (maxBranches > 0 && user.account._count.branches >= maxBranches) {
          return {
            type: "subscription",
            message: `Upgrade subscription to create more branches (${user.account._count.branches}/${maxBranches})`,
          };
        }*/
      }

      return true;
    },
    read: (user, data) => {
      if (!checkPermission("branch", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("branch", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    delete: (user, data) => {
      if (!checkPermission("branch", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
  },
  audit: {
    create: (user, data) => {
      if (!checkPermission("audit", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (user.account) {
        if (data.type === EXTERNAL) {
          if (!user.account.isExternalAuditor) {
            return {
              type: "access level",
              message: "You do not have access to such feature",
            };
          }
        } else if (data.type === INTERNAL) {
          if (!user.account?.internalSubscription) {
            return {
              type: "subscription",
              message: "You need a subscription to create audits",
            };
          }

          const maxAudits =
            user.account?.internalSubscription?.subscription.maxAudits;
          const auditsCount = user.account.counts.internal.audits;

          if (maxAudits >= 0 && auditsCount >= maxAudits) {
            return {
              type: "subscription",
              message: `Upgrade subscription to create more audits (${auditsCount}/${maxAudits})`,
            };
          }
        }
      }

      return true;
    },
    read: (user, data) => {
      if (!checkPermission("audit", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("audit", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    delete: (user, data) => {
      if (!checkPermission("audit", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
  },
  subscription: {
    create: (user, data) => {
      if (
        !checkPermission("subscription", user.role?.permissions || []).create
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }

        if (!user.account.isExternalAuditor) {
          if (data.type === EXTERNAL) {
            return {
              type: "access level",
              message: "You do not have access to such feature",
            };
          }
        }

        if (user.account.accountTypeId === ACCOUNT_TYPE.SUBSCRIBER) {
          if (data.type === INTERNAL) {
            return {
              type: "access level",
              message: "You do not have access to such feature",
            };
          }
        }
      }

      return true;
    },
    read: (user, data) => {
      if (!checkPermission("subscription", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (
        !checkPermission("subscription", user.role?.permissions || []).update
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    delete: (user, data) => {
      if (
        !checkPermission("subscription", user.role?.permissions || []).delete
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (user.account) {
        if (!isUnderMyAccounts(user, data.accountId)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
  },
  app: {
    create: (user, data) => {
      if (!checkPermission("app", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    read: (user, data) => {
      if (!checkPermission("app", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("app", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    delete: (user, data) => {
      if (!checkPermission("app", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
  },
  summaryGroup: {
    create: (user, data) => {
      if (
        !checkPermission("summary group", user.role?.permissions || []).create
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    read: (user, data) => {
      if (!checkPermission("summary group", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (
        !checkPermission("summary group", user.role?.permissions || []).update
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    delete: (user, data) => {
      if (
        !checkPermission("summary group", user.role?.permissions || []).delete
      ) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
  },
  account: {
    create: (user, data) => {
      if (!checkPermission("account", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      return true;
    },

    read: (user, data) => {
      if (!checkPermission("account", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.id && user.account) {
        if (!isUnderMyAccounts(user, data.id)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("account", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.id && user.account) {
        if (!isUnderMyAccounts(user, data.id)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
    delete: (user, data) => {
      if (!checkPermission("account", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.id && user.account) {
        if (!isUnderMyAccounts(user, data.id)) {
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
        }
      }

      return true;
    },
  },
  subAccount: {
    create: (user, data) => {
      if (!checkPermission("account", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;

      if (data.account) {
        if (data.account.id) {
          if (!isUnderMyAccounts(user, data.account.id)) {
            return {
              type: "access level",
              message: "You do not have access to such feature",
            };
          }

          if (!data.account?.internalSubscription)
            return {
              type: "subscription",
              message: "You need a subscription to create accounts",
            };

          const maxSubAccounts =
            data.account?.internalSubscription?.maxSubAccounts;

          if (
            maxSubAccounts >= 0 &&
            data.account._count.accounts >= maxSubAccounts
          ) {
            return {
              type: "subscription",
              message: `Upgrade subscription to create more sub-accounts (${data.account._count.accounts}/${maxSubAccounts})`,
            };
          }
        } else
          return {
            type: "access level",
            message: "You do not have access to such feature",
          };
      }

      return true;
    },
  },
  country: {
    create: (user, data) => {
      if (!checkPermission("country", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    read: (user, data) => {
      if (!checkPermission("country", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("country", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    delete: (user, data) => {
      if (!checkPermission("country", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
  },
  territory: {
    create: (user, data) => {
      if (!checkPermission("territory", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    read: (user, data) => {
      if (!checkPermission("territory", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("territory", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    delete: (user, data) => {
      if (!checkPermission("territory", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
  },
  region: {
    create: (user, data) => {
      if (!checkPermission("region", user.role?.permissions || []).create) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    read: (user, data) => {
      if (!checkPermission("region", user.role?.permissions || []).get) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      return true;
    },
    update: (user, data) => {
      if (!checkPermission("region", user.role?.permissions || []).update) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
    delete: (user, data) => {
      if (!checkPermission("region", user.role?.permissions || []).delete) {
        return {
          type: "role",
          message: "You do not have the necessary permissions",
        };
      }

      if (isAdmin(user)) return true;
      else
        return {
          type: "access level",
          message: "You do not have access to such feature",
        };
    },
  },
};

// action can be: create, read, update, delete
// entity: audit | colorPalette | branch | account | branchGroup...
// entityInfo: audit: audit type, ... | branch: ...
// onBlocked: function to run if blocked
// onPermitted: function to run if permitted
// renderBlocked: what to show if blocked
// renderPermitted: what to show if permitted

export function PermissionMonitor({
  action = "",
  entity = "",
  data = "",
  onBlocked,
  onPermitted,
  renderBlocked = () => {},
  renderPermitted = () => {},
}) {
  const { user } = useAuth();

  const permitted = useMemo(() => {
    if (permissionsMatrix?.[entity]?.[action])
      return permissionsMatrix?.[entity]?.[action](user, data);
  }, [entity, action, user, data]);

  useEffect(() => {
    if (permitted === true && onPermitted) {
      onPermitted();
    } else if (permitted !== true && onBlocked) {
      onBlocked(permitted);
    }
  }, [permitted]);

  if (permitted === true) return renderPermitted();

  return renderBlocked(permitted);
}

export function usePermissionMonitor() {
  const { user } = useAuth();

  return (
    action = "",
    entity = "",
    data = "",
    renderBlocked,
    renderPermitted,
    onBlocked,
    onPermitted
  ) => {
    if (action === "isAdmin") return isAdmin(user);

    let permitted;
    if (permissionsMatrix?.[entity]?.[action])
      permitted = permissionsMatrix?.[entity]?.[action](user, data);

    if (permitted === true && onPermitted) {
      onPermitted();
    } else if (permitted !== true && onBlocked) {
      onBlocked(permitted);
    }

    if (permitted === true) {
      if (renderPermitted) {
        if (typeof renderPermitted === "function")
          return renderPermitted(permitted);
        else return renderPermitted;
      }

      return true;
    }

    if (renderBlocked) {
      return typeof renderBlocked === "function"
        ? renderBlocked(permitted)
        : renderBlocked;
    }
    return false;
  };
}
