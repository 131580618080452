import { Checkbox, CircularProgress, Grid } from "@mui/material";
import IndeterminateIcon from "components/IndeterminateIcon";
import MDTypography from "components/MDTypography";
import { useCallback, useEffect, useMemo, useState } from "react";
import RowPillar from "./RowPillar";
import MDBox from "components/MDBox";

function refresherProps(pillar) {
  const props = {};
  pillar.kpis.forEach((kpi, index) => {
    props[`selected ${index}`] = kpi.selected;
    props[`description ${index}`] = kpi.description;
  });
  return props;
}

export default function PillarsTable({
  pillars,
  include,
  onChangeSelected,
  onManualChangeSelected,
  updatableDescription,
  initial,
  tableContainerProps,
  loading,
  defaultExpanded,
  selectAllSignal,
}) {
  const [localPillars, setLocalPillars] = useState(pillars);
  const [checkbox, setCheckbox] = useState({
    checked: false,
    indeterminate: false,
  });

  useEffect(() => {
    if (!initial) setLocalPillars(pillars);
    else {
      const newLocalPillars = JSON.parse(JSON.stringify(pillars));

      newLocalPillars.forEach((pillar, pillarIndex) => {
        pillar.kpis.forEach((kpi, kpiIndex) => {
          const foundElement = initial.find(
            (element) => element.kpiId == kpi.id
          );

          if (foundElement) {
            newLocalPillars[pillarIndex].kpis[kpiIndex].selected = true;
            newLocalPillars[pillarIndex].kpis[kpiIndex].description =
              foundElement.description;
          } else {
            newLocalPillars[pillarIndex].kpis[kpiIndex].selected = false;
          }
        });
      });

      setLocalPillars(newLocalPillars);
    }
  }, [pillars, initial]);

  useEffect(() => {
    let allSelected = true;
    let noneSelected = true;

    localPillars?.forEach((pillar) => {
      pillar.kpis?.forEach((kpi) => {
        if (kpi.selected) {
          noneSelected = false;
        } else {
          allSelected = false;
        }
      });
    });

    if (allSelected) {
      setCheckbox({ checked: true, indeterminate: false });
    } else if (noneSelected) {
      setCheckbox({ checked: false, indeterminate: false });
    } else {
      setCheckbox({ checked: false, indeterminate: true });
    }
  }, [
    ...localPillars?.flatMap((pillar) =>
      pillar.kpis.map((kpi) => Boolean(kpi.selected))
    ),
    initial,
  ]);

  const handleSelectPillar = useCallback(
    (pillarIndex) => {
      setLocalPillars((localPillars) => {
        const newPillars = [...localPillars];
        newPillars[pillarIndex].kpis.forEach((_, index) => {
          newPillars[pillarIndex].kpis[index].selected = true;
        });
        if (onManualChangeSelected) {
          onManualChangeSelected(
            newPillars?.flatMap((pillar) =>
              pillar.kpis
                .filter((kpi) => kpi.selected)
                .map((kpi) => ({ kpiId: kpi.id, description: kpi.description }))
            )
          );
        }
        return newPillars;
      });
    },
    [setLocalPillars]
  );

  const handleDeselectPillar = useCallback(
    (pillarIndex) => {
      setLocalPillars((localPillars) => {
        const newPillars = [...localPillars];
        newPillars[pillarIndex].kpis.forEach((_, index) => {
          newPillars[pillarIndex].kpis[index].selected = false;
        });
        if (onManualChangeSelected) {
          onManualChangeSelected(
            newPillars?.flatMap((pillar) =>
              pillar.kpis
                .filter((kpi) => kpi.selected)
                .map((kpi) => ({ kpiId: kpi.id, description: kpi.description }))
            )
          );
        }
        return newPillars;
      });
    },
    [setLocalPillars]
  );

  const handleSelectKpi = useCallback(
    (pillarIndex, kpiIndex) => {
      setLocalPillars((localPillars) => {
        const newPillars = [...localPillars];
        newPillars[pillarIndex].kpis[kpiIndex].selected = true;
        if (onManualChangeSelected) {
          onManualChangeSelected(
            newPillars?.flatMap((pillar) =>
              pillar.kpis
                .filter((kpi) => kpi.selected)
                .map((kpi) => ({ kpiId: kpi.id, description: kpi.description }))
            )
          );
        }
        return newPillars;
      });
    },
    [setLocalPillars]
  );

  const handleDeselectKpi = useCallback(
    (pillarIndex, kpiIndex) => {
      setLocalPillars((localPillars) => {
        const newPillars = [...localPillars];
        newPillars[pillarIndex].kpis[kpiIndex].selected = false;
        if (onManualChangeSelected) {
          onManualChangeSelected(
            newPillars?.flatMap((pillar) =>
              pillar.kpis
                .filter((kpi) => kpi.selected)
                .map((kpi) => ({ kpiId: kpi.id, description: kpi.description }))
            )
          );
        }
        return newPillars;
      });
    },
    [setLocalPillars]
  );

  const handleSelectAll = useCallback(
    (props) => {
      setLocalPillars((localPillars) => {
        const newPillars = [...localPillars];
        newPillars.forEach((_, pillarIndex) => {
          newPillars[pillarIndex].kpis.forEach((_, kpiIndex) => {
            newPillars[pillarIndex].kpis[kpiIndex].selected = true;
          });
        });

        if (!props?.ignoreManualChange && onManualChangeSelected) {
          onManualChangeSelected(
            newPillars?.flatMap((pillar) =>
              pillar.kpis
                .filter((kpi) => kpi.selected)
                .map((kpi) => ({ kpiId: kpi.id, description: kpi.description }))
            )
          );
        }

        return newPillars;
      });
    },
    [setLocalPillars]
  );

  const handleDeselectAll = useCallback(() => {
    setLocalPillars((localPillars) => {
      const newPillars = [...localPillars];
      newPillars.forEach((_, pillarIndex) => {
        newPillars[pillarIndex].kpis.forEach((_, kpiIndex) => {
          newPillars[pillarIndex].kpis[kpiIndex].selected = false;
        });
      });

      if (onManualChangeSelected) {
        onManualChangeSelected(
          newPillars?.flatMap((pillar) =>
            pillar.kpis
              .filter((kpi) => kpi.selected)
              .map((kpi) => ({ kpiId: kpi.id, description: kpi.description }))
          )
        );
      }

      return newPillars;
    });
  }, [setLocalPillars]);

  const handleChangeKpiDescription = useCallback(
    (pillarIndex, kpiIndex, description) => {
      setLocalPillars((localPillars) => {
        const newPillars = [...localPillars];
        newPillars[pillarIndex].kpis[kpiIndex].description = description;

        if (onManualChangeSelected) {
          onManualChangeSelected(
            newPillars?.flatMap((pillar) =>
              pillar.kpis
                .filter((kpi) => kpi.selected)
                .map((kpi) => ({ kpiId: kpi.id, description: kpi.description }))
            )
          );
        }

        return newPillars;
      });
    },
    [setLocalPillars]
  );

  const utils = useMemo(() => {
    return {
      handleSelectPillar,
      handleDeselectPillar,
      handleSelectKpi,
      handleDeselectKpi,
      handleChangeKpiDescription,
    };
  }, [
    handleSelectPillar,
    handleDeselectPillar,
    handleSelectKpi,
    handleDeselectKpi,
    handleChangeKpiDescription,
  ]);

  useEffect(() => {
    if (onChangeSelected) {
      onChangeSelected(
        localPillars?.flatMap((pillar) =>
          pillar.kpis
            .filter((kpi) => kpi.selected)
            .map((kpi) => ({ kpiId: kpi.id, description: kpi.description }))
        )
      );
    }
  }, [localPillars]);

  useEffect(() => {
    if (selectAllSignal) {
      handleSelectAll({ ignoreManualChange: true });
    }
  }, [selectAllSignal]);

  return (
    <>
      <Grid container sx={{ width: "100%" }} alignItems="center">
        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Checkbox
            onChange={(e, checked) => {
              if (checked) {
                handleSelectAll();
              } else {
                handleDeselectAll();
              }
            }}
            checked={checkbox.checked}
            indeterminate={checkbox.indeterminate}
            indeterminateIcon={<IndeterminateIcon />}
          />
          <MDTypography
            sx={{ fontSize: "0.9rem", fontWeight: "500", width: "100%" }}
          >
            Name
          </MDTypography>
        </Grid>
        {include?.weight && (
          <Grid item xs={2}>
            <MDTypography
              sx={{ fontSize: "0.9rem", fontWeight: "500", width: "100%" }}
            >
              Weight
            </MDTypography>
          </Grid>
        )}
        {include?.categories && (
          <Grid item xs={4.25}>
            <MDTypography
              sx={{ fontSize: "0.9rem", fontWeight: "500", width: "100%" }}
            >
              Categories
            </MDTypography>
          </Grid>
        )}
        {include?.pillar?.create &&
          include?.pillar?.update &&
          include?.pillar?.delete &&
          include?.kpi?.create &&
          include?.kpi?.update &&
          include?.kpi?.delete && <Grid item sx={{ width: "130px" }}></Grid>}
      </Grid>

      {!loading ? (
        <Grid
          container
          {...tableContainerProps}
          sx={{ overflow: "auto", ...tableContainerProps?.sx }}
        >
          {localPillars.map((pillar, pillarIndex) => {
            return (
              <RowPillar
                key={"Pillar: " + pillar.id}
                defaultExpanded={defaultExpanded}
                pillar={pillar}
                pillarIndex={pillarIndex}
                utils={utils}
                include={include}
                updatableDescription={updatableDescription}
                {...refresherProps(pillar)}
              />
            );
          })}
        </Grid>
      ) : (
        <MDBox
          sx={{
            width: "100%",
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="info" size={30} />
        </MDBox>
      )}
    </>
  );
}
