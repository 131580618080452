import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";
import AllAudits from "./components/AllAudits.v2";
import Audit from "./components/Audit/index";

import PageContainer from "components/PageContainer";
import { Route, Routes, useLocation } from "react-router-dom";

import AccessDenied from "components/AccessDenied";
import { useAuth } from "contexts/auth.context";
import { useEffect, useState } from "react";

function Audits() {
  const [route, setRoute] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setRoute(null);
  }, [location]);

  const { checkPermission } = useAuth();

  if (
    Object.keys(checkPermission("audit")).filter(
      (key) => checkPermission("audit")[key]
    ).length == 0
  ) {
    return <AccessDenied type="page" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar customRoute={route} />

      <PageContainer>
        <Routes>
          <Route
            index
            element={<AllAudits permissions={checkPermission("audit")} />}
          />
          <Route
            path={"/:id"}
            element={
              !(
                checkPermission("audit").get || checkPermission("audit").update
              ) ? (
                <AccessDenied type="half-page" />
              ) : (
                <Audit
                  setRoute={setRoute}
                  permissions={checkPermission("audit")}
                />
              )
            }
          />
        </Routes>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default Audits;
