import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ifpcSticker from "assets/images/ifpc-cover.jpg";
import LoadingButton from "components/LoadingButton";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce } from "utils/debounce";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import downloadPdf from "./GenerateReportv3";

import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SendIcon from "@mui/icons-material/Send";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  colors,
  flagColor,
  flagColorBasedOnString,
  flagHexColor,
  perfs,
} from "../../../audits/components/Audit/utils";

//import confidentialSticker from "assets/images/confidential.svg";
import confidentialSticker from "assets/images/confidential.png";
import MDBadge from "components/MDBadge";
import ResizableTextarea from "components/ResizableTextarea";
import auditsService from "services/audits-service";
import branchesService from "services/branches-service";
import styles from "./index-consolidated-v2.module.css";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CustomizedMenus = ({
  branchGroup,
  loading,
  setLoading,
  selected,
  type,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGenerate = async () => {
    handleClose();
    setLoading(true);
    await downloadPdf(`${branchGroup.name}-CR-ifpc360`, "consolidated");
    setLoading(false);
  };

  async function downloadPDF(url, filename) {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  const handleGenerateLink = async () => {
    handleClose();
    setLoading(true);
    const [response, data] = await branchesService.getConsolidatedReportLink(
      { branchGroupId: branchGroup.id },
      { type, selected }
    );
    if (response.ok) {
      console.log("CONSOLIDATED URL IS: ", data.url);
      await downloadPDF(data.url, `${branchGroup.name}-CR-ifpc360.pdf`);
    }
    setLoading(false);
  };

  return (
    <div>
      <LoadingButton
        loading={loading}
        type="button"
        variant="gradient"
        color="info"
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </LoadingButton>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            p: 1,
            px: 2,
            m: 0,
            mx: -1,
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
          onClick={handleGenerate}
          disableRipple
        >
          <FileDownloadIcon />
          Download as PDF
        </MenuItem>
        <MenuItem
          sx={{
            p: 1,
            px: 2,
            m: 0,
            mx: -1,
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
          onClick={handleGenerateLink}
          disableRipple
        >
          <FileDownloadIcon />
          Download as PDF
          <MDBadge badgeContent="BETA" color="primary" />
        </MenuItem>
        <MenuItem
          sx={{
            p: 1,
            px: 2,
            m: 0,
            mx: -1,
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
          onClick={handleClose}
          disableRipple
        >
          <SendIcon />
          Share via email
        </MenuItem>
      </Menu>
    </div>
  );
};

export default function ConsolidatedReportDialog({
  isOpen,
  setIsOpen,
  audit,
  selected,
  branches,
  branchGroup,
  type,
}) {
  if (!isOpen) {
    branches = [];
    return;
  } else {
    branches = branches.filter(
      (branch) => branch.audits[branch.audits.length - 1]
    );
    switch (type.value) {
      case "normal":
        break;
      case "region":
        branches = branches.filter(
          (branch) => branch.region.id == type.region.id
        );
        break;
      case "comprehensive":
        branches = type.regions.map((region) => {
          let audits = {};

          branches.map((branch) => {
            if (region.id != branch.region.id) return;
            const auditGroup = branch.audits;

            auditGroup.map((audit) => {
              const month = monthNames[new Date(audit.date).getMonth()];
              if (!audits[month]) audits[month] = [];

              audits[month].push(audit);
            });
          });

          audits = Object.keys(audits).map((key) => {
            const auditGroup = audits[key];
            if (auditGroup.length == 1) return auditGroup[0];

            const mergedAudit = {
              id: key,
              date: auditGroup[0].date,
              reportGenerated: { table1: {}, table2: {} },
            };

            auditGroup.map((audit) => {
              audit.reportGenerated.table2.map((row) => {
                if (!mergedAudit.reportGenerated.table2[row.pillarId])
                  mergedAudit.reportGenerated.table2[row.pillarId] = {
                    pillarId: row.pillarId,
                    description: row.description,
                    referenceCode: row.referenceCode,
                    totals: { dev: 0, na: 0, std: 0, weight: 0 },
                  };
                mergedAudit.reportGenerated.table2[row.pillarId].totals.dev +=
                  row.totals.dev;
                mergedAudit.reportGenerated.table2[row.pillarId].totals.na +=
                  row.totals.na;
                mergedAudit.reportGenerated.table2[row.pillarId].totals.std +=
                  row.totals.std;
                mergedAudit.reportGenerated.table2[
                  row.pillarId
                ].totals.weight += row.totals.weight;
              });

              audit.reportGenerated.table1.map((row, index) => {
                const isLast = index == audit.reportGenerated.table1.length - 1;

                if (!isLast) {
                  if (!mergedAudit.reportGenerated.table1[row.pillarId]) {
                    mergedAudit.reportGenerated.table1[row.pillarId] = {
                      pillarId: row.pillarId,
                      description: row.description,
                      referenceCode: row.referenceCode,
                      devPoints: 0,
                      naPoints: 0,
                      score: 0,
                      stdPoints: 0,
                      weight: 0,
                    };
                  }

                  mergedAudit.reportGenerated.table1[row.pillarId].devPoints +=
                    row.devPoints;
                  mergedAudit.reportGenerated.table1[row.pillarId].naPoints +=
                    row.naPoints;
                  mergedAudit.reportGenerated.table1[row.pillarId].score +=
                    row.score;
                  mergedAudit.reportGenerated.table1[row.pillarId].stdPoints +=
                    row.stdPoints;
                  mergedAudit.reportGenerated.table1[row.pillarId].weight +=
                    row.weight;
                } else {
                  if (!mergedAudit.reportGenerated.table1["lastRow"]) {
                    mergedAudit.reportGenerated.table1["lastRow"] = {
                      devPercentage: 0,
                      devTotal: 0,
                      naPercentage: 0,
                      naTotal: 0,
                      stdPercentage: 0,
                      stdTotal: 0,
                      totalPercentage: 0,
                      totalPoints: 0,
                    };
                  }

                  mergedAudit.reportGenerated.table1["lastRow"].devPercentage +=
                    row.devPercentage;
                  mergedAudit.reportGenerated.table1["lastRow"].devTotal +=
                    row.devTotal;
                  mergedAudit.reportGenerated.table1["lastRow"].naPercentage +=
                    row.naPercentage;
                  mergedAudit.reportGenerated.table1["lastRow"].naTotal +=
                    row.naTotal;
                  mergedAudit.reportGenerated.table1["lastRow"].stdPercentage +=
                    row.stdPercentage;
                  mergedAudit.reportGenerated.table1["lastRow"].stdTotal +=
                    row.stdTotal;
                  mergedAudit.reportGenerated.table1[
                    "lastRow"
                  ].totalPercentage += row.totalPercentage;
                  mergedAudit.reportGenerated.table1["lastRow"].totalPoints +=
                    row.totalPoints;
                }
              });
            });

            Object.keys(mergedAudit.reportGenerated.table2).map((key) => {
              mergedAudit.reportGenerated.table2[key].totals.dev /=
                auditGroup.length;
              mergedAudit.reportGenerated.table2[key].totals.na /=
                auditGroup.length;
              mergedAudit.reportGenerated.table2[key].totals.std /=
                auditGroup.length;
              mergedAudit.reportGenerated.table2[key].totals.weight /=
                auditGroup.length;
            });

            Object.keys(mergedAudit.reportGenerated.table1).map((key) => {
              if (key == "lastRow") {
                mergedAudit.reportGenerated.table1["lastRow"].devPercentage /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1["lastRow"].devTotal /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1["lastRow"].naPercentage /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1["lastRow"].naTotal /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1["lastRow"].stdPercentage /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1["lastRow"].stdTotal /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1["lastRow"].totalPercentage /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1["lastRow"].totalPoints /=
                  auditGroup.length;
              } else {
                mergedAudit.reportGenerated.table1[key].devPoints /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1[key].naPoints /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1[key].score /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1[key].stdPoints /=
                  auditGroup.length;
                mergedAudit.reportGenerated.table1[key].weight /=
                  auditGroup.length;
              }
            });

            const lastRow = mergedAudit.reportGenerated.table1.lastRow;
            delete mergedAudit.reportGenerated.table1.lastRow;
            const table1 = [
              ...Object.values(mergedAudit.reportGenerated.table1),
              lastRow,
            ];
            const table2 = Object.values(mergedAudit.reportGenerated.table2);

            mergedAudit.reportGenerated = { table1, table2 };

            return mergedAudit;
          });

          return {
            id: region.id,
            name: region.description,
            colorPalette: branches[0]?.colorPalette,
            audits,
          };
        });
        break;
    }
  }

  const [timeRecord, setTimeRecord] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setTimeRecord("");
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const getPillars = useCallback(() => {
    const pillars = {};
    branches.forEach((branch) => {
      branch.audits.forEach((audit) => {
        const internalPillars = audit.reportGenerated.table2;
        internalPillars.forEach((internalPillar) => {
          if (!pillars[internalPillar.referenceCode])
            pillars[internalPillar.referenceCode] = {
              ...internalPillar,
              id: internalPillar.pillarId,
              auditKpis: {},
            };

          internalPillar.auditKpis?.forEach((auditKpi) => {
            pillars[internalPillar.referenceCode].auditKpis[
              auditKpi.referenceCode
            ] = auditKpi;
          });
        });
      });
    });

    const arrayPillars = Object.keys(pillars).map((key) => {
      const kpis = Object.keys(pillars[key].auditKpis).map((key2) => {
        return pillars[key].auditKpis[key2];
      });
      return { ...pillars[key], auditKpis: null, kpis };
    });

    arrayPillars.sort((a, b) => a.referenceCode.localeCompare(b.referenceCode));

    return arrayPillars;
  }, [branches]);

  audit = { pillars: getPillars() };

  const pdfRef = useRef(null);
  let scrollInterval;

  function getDragAfterElement(container, y) {
    const draggableElements = [
      ...container.querySelectorAll(".draggable-item:not(.dragging)"),
    ];
    return draggableElements.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();
        const offset = y - box.top - box.height / 2;
        if (offset < 0 && offset > closest.offset) {
          return { offset: offset, element: child };
        } else {
          return closest;
        }
      },
      { offset: Number.NEGATIVE_INFINITY }
    ).element;
  }

  const { DashboardTable, reportScore } = useMemo(() => {
    const { score, dashboardTableHtml } = DashboardTableConstruct(
      audit.pillars,
      branches
    );
    return { DashboardTable: dashboardTableHtml, reportScore: score };
  }, [audit, branches]);

  const BarChart = useMemo(() => {
    return branches?.length === 1
      ? BarChartConstruct(audit?.pillars, branches)
      : BarChartConstructv2(audit?.pillars, branches);
  }, [audit, branches]);

  const PillarBarChart = useMemo(() => {
    return PillarBarChartConstruct(audit?.pillars, branches);
  }, [audit, branches]);

  const PieChart = useMemo(() => {
    return PieChartConstruct(audit?.pillars, branches);
  }, [audit, branches]);

  const GeneralGuidelinesTable = useMemo(() => {
    return (
      <GeneralGuidelinesTableConstruct
        audit={audit}
        pillars={audit?.pillars}
        branchGroup={branchGroup}
      />
    );
  }, [audit]);

  useEffect(() => {
    if (branches[0]?.colorPalette) {
      const {
        color1,
        color2,
        color3,
        fontColor1,
        fontColor2,
        fontColor3,
        ...rest
      } = branches[0]?.colorPalette;
      document.documentElement.style.setProperty("--var-color-1", color1);
      document.documentElement.style.setProperty("--var-color-2", color2);
      document.documentElement.style.setProperty("--var-color-3", color3);

      document.documentElement.style.setProperty("--var-font-1", fontColor1);
      document.documentElement.style.setProperty("--var-font-2", fontColor2);
      document.documentElement.style.setProperty("--var-font-3", fontColor3);
    }
    // Add more variable changes as needed
  }, [branches]);

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
      <DialogTitle>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <MDTypography variant="h4">Consolidated Report</MDTypography>
          <MDBox
            sx={{ flex: "1" }}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            <CustomizedMenus
              branchGroup={branchGroup}
              loading={loading}
              selected={selected}
              setLoading={setLoading}
              type={type}
            />
            <IconButton
              onClick={handleClose}
              disabled={loading}
              sx={{ padding: "0px" }}
            >
              <CloseIcon />
            </IconButton>
          </MDBox>
        </MDBox>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0 !important",
          backgroundColor: "whitesmoke !important",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className={styles["pdf-container"]}
          ref={pdfRef}
          onDragOver={(e) => {
            e.preventDefault();
            const afterElement = getDragAfterElement(pdfRef.current, e.clientY);
            const draggable = document.querySelector(".dragging");
            if (afterElement == null) {
              pdfRef.current.appendChild(draggable);
            } else {
              pdfRef.current.insertBefore(draggable, afterElement);
            }
          }}
        >
          {/* cover */}
          <div
            className={`${styles["pdf-item-container"]} printable`}
            id="cover"
          >
            <div className={styles["pdf-item"]}>
              <div className={styles["report-cover"]}>
                <Avatar
                  className={styles["report-cover-logo"]}
                  variant="rounded"
                  src={branchGroup?.branches[0]?.logo} //`${UPLOAD_URL}/${branchGroup?.branches[0]?.logo}`
                ></Avatar>
                <div className={styles["cover-info-container-box"]}>
                  <div className={styles["cover-info-container"]}>
                    <div>
                      <span className={styles["assessment"]}>
                        360<span>&deg;</span>
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          height: "100%",
                          alignItems: "center",
                          fontSize: "2rem",
                        }}
                      >
                        {branchGroup?.name}
                        {type.value === "region"
                          ? ` - ${type.region.description}`
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className={styles["cover-info-rating"]}>
                    <span>
                      <span>Rating</span>
                      {isNaN(reportScore) ? null : (
                        <img
                          className={`${styles.flag} ${styles.bx} ${styles["bxs-flag"]}}`}
                          src={flagColor(reportScore)}
                          alt="flag-score"
                        />
                      )}
                    </span>
                    <span>{isNaN(reportScore) ? "-" : reportScore + "%"}</span>
                  </div>
                </div>
                <div className={styles["cover-confidental"]}>
                  <img src={ifpcSticker} alt="ifpc" />
                  <img src={confidentialSticker} alt="confidential" />
                </div>
                <div className={styles["cover-footer"]}>
                  <span>
                    360&deg; Consolidated Report all rights reserved IFPC
                    S.A.R.L &copy; 2023
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* dashboard table */}
          <div
            className={styles["draggable-item"]}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div className={`${styles["pdf-item-container"]} title printable`}>
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={styles["title"]}>Consolidated Dashboard</div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
            {DashboardTable}
          </div>

          {/* risk analysis - static table */}
          <div
            className={`${styles["pdf-item-container"]} ${styles["draggable-item"]}`}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div
              className={`${styles["pdf-item-container"]} printable no-title`}
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles["pdf-item"]}>
                    <div className={styles["subtitle"]}>
                      <div className={styles["line"]}></div>
                      Risk Analysis
                      <div className={styles["line"]}></div>
                    </div>
                    <div className={styles["risk-analysis-table"]}>
                      <div>
                        <Table
                          theads={["Risk Range", "Weight"]}
                          rows={[
                            ["Low", "1"],
                            ["Moderate", "2"],
                            ["Critical", "3"],
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles["pdf-item"]}>
                    <div className={styles["subtitle"]}>
                      <div className={styles["line"]}></div>
                      Performance Ratings
                      <div className={styles["line"]}></div>
                    </div>
                    <div className={styles["perf-ratings"]}>
                      <div className={styles["perfs"]}>
                        {perfs.map((perf, index) => {
                          return (
                            <div
                              className={styles["perf"]}
                              key={"Perf: " + index}
                            >
                              <span>{perf.title}</span>
                              <div className={styles["arrow"]}>
                                <div className={styles["arrow-tail"]}></div>
                                <div className={styles["arrow-head"]}></div>
                              </div>
                              <span>{perf.value}%</span>
                              <img
                                className={`${styles.flag} ${styles.bx} ${styles["bxs-flag"]}}`}
                                src={flagColorBasedOnString(perf.color)}
                                alt="flag-score"
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className={styles["perf-levels"]}>
                        <span>Low</span>
                        <div className={`${styles.arrow} ${styles.vertical}`}>
                          <div className={styles["arrow-tail"]}></div>
                          <div className={styles["arrow-head"]}></div>
                        </div>
                        <span>High</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
          </div>

          {/* performance ratings - static table */}
          {/*<div
            className={`${styles["pdf-item-container"]} ${styles["draggable-item"]} printable`}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div className={styles["pdf-margin"]}></div>
            <div className={styles["pdf-item"]}>
              <div className={styles["subtitle"]}>
                <div className={styles["line"]}></div>
                Performance Ratings
                <div className={styles["line"]}></div>
              </div>
              <div className={styles["perf-ratings"]}>
                <div className={styles["perfs"]}>
                  {perfs.map((perf, index) => {
                    return (
                      <div className={styles["perf"]} key={"Perf: " + index}>
                        <span>{perf.title}</span>
                        <div className={styles["arrow"]}>
                          <div className={styles["arrow-tail"]}></div>
                          <div className={styles["arrow-head"]}></div>
                        </div>
                        <span>{perf.value}%</span>
                        <i
                          className={`${styles.flag} ${styles.bx} ${
                            styles["bxs-flag"]
                          } ${styles[perf.color]}`}
                        >
                          &#9873;
                        </i>
                      </div>
                    );
                  })}
                </div>
                <div className={styles["perf-levels"]}>
                  <span>Low</span>
                  <div className={`${styles.arrow} ${styles.vertical}`}>
                    <div className={styles["arrow-tail"]}></div>
                    <div className={styles["arrow-head"]}></div>
                  </div>
                  <span>High</span>
                </div>
              </div>
            </div>
            <div className={styles["pdf-margin"]}></div>
          </div>*/}

          {/* General Performance Positioning */}
          <div
            className={styles["draggable-item"]}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div
              className={`${styles["pdf-item-container"]} printable title`}
              id="title"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={styles["title"]}>
                  General Performance Positioning
                </div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>

            <div
              className={`${styles["pdf-item-container"]} printable`}
              id="barchart"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>{BarChart}</div>
              <div className={styles["pdf-margin"]}></div>
            </div>

            <div className={styles["pie-charts"]}>{PieChart}</div>
          </div>

          {/* Specific Performance Positioning */}
          <div
            className={styles["draggable-item"]}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div
              className={`${styles["pdf-item-container"]} printable title`}
              id="title"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={styles["title"]}>
                  Specific Performance Positioning
                </div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
            {PillarBarChart}
          </div>

          {/* General Guidelines */}
          <div
            className={styles["draggable-item"]}
            /*draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}*/
          >
            <div
              className={`${styles["pdf-item-container"]} printable title`}
              id="guidelines"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={styles["title"]}>General Guidelines</div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
            {GeneralGuidelinesTable}
          </div>

          <div id="table-hidden-container"></div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const Table = ({ theads, rows }) => {
  return (
    <table>
      <thead>
        <tr>
          {theads.map((thead, index) => {
            return <th key={"Thead: " + index}>{thead}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, row_index) => {
          return (
            <tr key={"Row: " + row_index}>
              {row.map((item, item_index) => {
                return <td key={"Item: " + item_index}>{item}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const GeneralGuidelinesTableConstruct = ({ pillars, branchGroup }) => {
  const promiseTrain = useRef({});
  const oldGuidelines = useRef();
  const [guidelines, setGuidelines] = useState([]);

  const debouncedSave = useCallback(
    debounce((nextValue) => upsertGuidelines(nextValue), 3000),
    []
  );

  function updateGuidelines(pillarId, newValue) {
    setGuidelines((curr) => {
      const newGuidelines = [...curr];
      const index = newGuidelines.findIndex(
        (guideline) => guideline.pillarId == pillarId
      );

      newGuidelines[index] = { ...newGuidelines[index], ...newValue };

      debouncedSave(newGuidelines);

      return newGuidelines;
    });
  }

  async function upsertGuidelines(guidelines) {
    const promId = Math.random();
    const prom = auditsService.upsertConsolidatedReport(
      branchGroup.id,
      guidelines
    );
    promiseTrain.current[promId] = prom;

    const [response, data] = await prom;
    delete promiseTrain.current[promId];

    if (response.ok) {
      if (Object.keys(promiseTrain.current).length == 0) {
        oldGuidelines.current = data;
        setGuidelines(data);
      }
    } else {
    }
  }

  async function getGuidelines() {
    const [response, data] = await auditsService.getConsolidatedReport(
      branchGroup.id
    );
    if (response.ok) {
      const newData = [...data];
      pillars.map((pillar) => {
        const index = data.findIndex((d) => d.pillarId == pillar.pillarId);
        if (index == -1) {
          newData.push({
            pillarId: pillar.pillarId,
            recommendation: "",
            analysis: "",
          });
        }
      });

      setGuidelines(newData);
    }
  }

  useEffect(() => {
    getGuidelines();
  }, []);

  if (!pillars) return null;

  const content = useMemo(() => {
    const c = {};
    guidelines.forEach((element) => {
      c[element.pillarId] = element;
    });

    return c;
  }, [guidelines]);

  return (
    <div
      className={`${styles["pdf-item-container"]} printable`}
      id="table"
      style={{ width: "100% !important" }}
    >
      <div className={styles["pdf-margin"]}></div>
      <div className={styles["pdf-item"]}>
        <table style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th
                className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]}`}
                style={{ width: "5%" }}
              >
                Ref.
              </th>
              <th
                className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]}`}
                style={{ width: "15%" }}
              >
                Category
              </th>
              <th
                className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]}`}
                style={{ width: "40%" }}
              >
                Analysis
              </th>
              <th
                className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]}`}
                style={{ width: "40%" }}
              >
                Instructions and Recommendations
              </th>
            </tr>
          </thead>
          <tbody>
            {pillars.map((pillar, index) => {
              return (
                <tr key={"Guidelines row: " + pillar.id + pillar.referenceCode}>
                  <td
                    className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["bold-10-cell"]}`}
                  >
                    {pillar.referenceCode}
                  </td>
                  <td
                    className={`${styles["color-3-cell"]} ${styles["left-align-cell"]}`}
                    style={{ textAlign: "start", padding: "2px" }}
                  >
                    {pillar.description}
                  </td>

                  {/*<ContentEditableTd
                    key={"Guidelines td1 row: " + pillar.id}
                    className={styles["white-cell"]}
                    style={{
                      verticalAlign: "top",
                      textAlign: "start",
                      wordWrap: "break-word",
                      cursor: "text",
                      ":focus": {
                        outline: "none",
                      },
                      outline: "none",
                    }}
                    value={content[pillar.pillarId]?.analysis || ""}
                    onChange={(e) => {
                      updateGuidelines(pillar.pillarId, {
                        analysis: e.target.innerHTML,
                      });
                    }}
                  />*/}

                  <td
                    className={styles["white-cell"]}
                    style={{
                      height: "100%",
                      padding: "2px",
                      verticalAlign: "top",
                    }}
                  >
                    {/*<ResizableTextarea
                      style={{
                        verticalAlign: "top",
                        textAlign: "start",
                        wordWrap: "break-word",
                        cursor: "text",
                        ":focus": {
                          outline: "none",
                        },
                        width: "100%",
                        height: "fit-content",
                        border: "none",
                        outline: "none",
                        resize: "none",
                        minHeight: 0,
                        backgroundColor: "red",
                      }}
                      value={content[pillar.pillarId]?.analysis || ""}
                      onChange={(e) => {
                        updateGuidelines(pillar.pillarId, {
                          analysis: e.target.value,
                        });
                      }}
                    />*/}
                    <ResizableTextarea
                      sx={{
                        width: "100%",
                        height: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                          "& textarea": {
                            padding: "0 !important",
                          },
                        },
                        "& .MuiInputBase-input": {
                          outline: "none",
                        },
                      }}
                      value={content[pillar.pillarId]?.analysis || ""}
                      onChange={(e) => {
                        updateGuidelines(pillar.pillarId, {
                          analysis: e.target.value,
                        });
                      }}
                    />
                  </td>

                  {/*<ContentEditableTd
                    key={"Guidelines td2 row: " + pillar.id}
                    className={styles["white-cell"]}
                    style={{
                      verticalAlign: "top",
                      textAlign: "start",
                      wordWrap: "break-word",
                      cursor: "text",
                      ":focus": {
                        outline: "none",
                      },
                      outline: "none",
                    }}
                    value={content[pillar.pillarId]?.recommendation || ""}
                    onChange={(e) => {
                      updateGuidelines(pillar.pillarId, {
                        recommendation: e.target.innerHTML,
                      });
                    }}
                  />*/}

                  <td
                    className={styles["white-cell"]}
                    style={{
                      height: "100%",
                      padding: "2px",
                      verticalAlign: "top",
                    }}
                  >
                    {/*<ResizableTextarea
                      style={{
                        verticalAlign: "top",
                        textAlign: "start",
                        wordWrap: "break-word",
                        cursor: "text",
                        ":focus": {
                          outline: "none",
                        },
                        width: "100%",
                        height: "100%",
                        border: "none",
                        outline: "none",
                        resize: "none",
                        minHeight: 0,
                      }}
                      value={content[pillar.pillarId]?.recommendation || ""}
                      onChange={(e) => {
                        updateGuidelines(pillar.pillarId, {
                          recommendation: e.target.value,
                        });
                      }}
                    />*/}

                    <ResizableTextarea
                      sx={{
                        width: "100%",
                        height: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                          "& textarea": {
                            padding: "0 !important",
                          },
                        },
                        "& .MuiInputBase-input": {
                          outline: "none",
                        },
                      }}
                      value={content[pillar.pillarId]?.recommendation || ""}
                      onChange={(e) => {
                        updateGuidelines(pillar.pillarId, {
                          recommendation: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles["pdf-margin"]}></div>
    </div>
  );
};

const BarChartConstruct = (pillars, branches) => {
  if (!pillars || !branches || !branches?.length) return null;

  const singleBranch = branches?.length === 1 ? branches[0] : false;

  const chartData = branches.map((branch) => {
    return {
      name: branch.name,
      value: Math.round(
        branch.audits[branch.audits.length - 1].reportGenerated.table1[
          branch.audits[branch.audits.length - 1].reportGenerated.table1
            .length - 1
        ].totalPercentage * 100
      ),
    };
  });

  const generateData = () => {
    const data = [];
    if (singleBranch) {
      singleBranch.audits.map((audit) => {
        const { table1 } = audit.reportGenerated;

        const value = {
          name: monthNames[new Date(audit.date).getMonth()],
          overall: Math.round(table1[table1.length - 1].totalPercentage * 100),
        };

        data.push(value);
      });
      return data;
    } else {
      branches.map((branch) => {
        const audit = branch.audits[branch.audits.length - 1];
        const { table1 } = audit.reportGenerated;

        const value = {
          name: branch.name,
          overall: Math.round(table1[table1.length - 1].totalPercentage * 100),
        };

        data.push(value);
      });

      return data;
    }
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value == 0 || value ? value + "%" : ""}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width={"100%"} height={850}>
      <BarChart
        data={generateData()}
        margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
        //layout="vertical"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval={0} />
        <YAxis
          type="number"
          tick={{ fontSize: "0.8em" }}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tickFormatter={(value) => `${value}%`}
          dataKey="overall"
        />
        <Tooltip
          formatter={(value, name, props) => {
            return [`${props.payload.name}: ${value || 0}%`];
          }}
        />
        <Bar dataKey={`overall`} maxBarSize={40}>
          {generateData()?.map((entry, index) => (
            <Cell
              key={`cell-${entry.id}`}
              fill={flagHexColor(Number(entry.overall) || 0)}
            />
          ))}
          <LabelList dataKey="overall" content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const BarChartConstructv2 = (pillars, branches) => {
  if (!pillars || !branches?.length) return null;

  function generateData() {
    const data = [];
    let monthsInBranch = [];
    branches.forEach((branch) => {
      const months = branch.audits
        .map(
          ({ date }) =>
            new Date(date).getMonth() * 0.01 +
            new Date(date).getFullYear() +
            new Date(date).getDate() * 0.0001
        )
        .map((v) => Number(v.toFixed(4)));
      monthsInBranch = [
        ...monthsInBranch,
        ...months, //.filter((month) => !monthsInBranch.includes(month)),
      ];
    });

    monthsInBranch = monthsInBranch.sort((a, b) => a - b);
    monthsInBranch = Array.from(new Set(monthsInBranch));
    const daysInBranch = [];
    monthsInBranch = monthsInBranch.map((month) => {
      let m = parseInt(month.toFixed(4).split(".")[1].substring(0, 2));
      let ds = month.toFixed(4).split(".")[1].substring(2, 4);
      if (ds.length == 1) ds += "0";

      let d = parseInt(ds);
      daysInBranch.push(d);
      return m;
    });

    branches.map((branch) => {
      const branchValues = [];
      let counter = 0;
      const audits = branch.audits.reverse();
      audits.map((audit) => {
        const { table1 } = audit.reportGenerated;

        const row = table1[table1.length - 1];

        const value = { name: "" + branch.name };
        value.audit = Math.round(row.totalPercentage * 100);
        value.subname = monthNames[new Date(audit.date).getMonth()];
        branchValues.push({
          ...value,
          month: new Date(audit.date).getMonth(),
        });
      });

      monthsInBranch.forEach((month) => {
        const value = branchValues.find(
          (branchValue) => branchValue.month == month
        );
        if (value) {
          value.name += "___" + counter++;
          data.push(value);
        } else {
          data.push({
            name: branch.name + "___" + counter++,
            audit: "",
            subname: "",
          });
        }
      });
    });

    return data;
  }

  function generateData2() {
    let data = [];
    branches.map((branch) => {
      let reset = 0;
      let branchAudits = [];
      branch.audits.map((audit) => {
        const { table1 } = audit.reportGenerated;

        const row = table1[table1.length - 1];
        branchAudits.push({
          name: branch.name,
          audit: Math.round(row.totalPercentage * 100),
          subname: monthNames[new Date(audit.date).getMonth()].substring(0, 3),
          month: new Date(audit.date).getMonth(),
          date: new Date(audit.date),
          branchId: branch.id,
        });
      });
      branchAudits.sort((a, b) => a.date - b.date);
      branchAudits.forEach((_, index) => {
        branchAudits[index].name +=
          "___" + index + "___" + branchAudits[index].branchId;
      });
      data = data.concat(branchAudits);
      reset = 0;
    });

    const subnameGroups = {};
    data.forEach((d, index) => {
      if (!subnameGroups[d.branchId + d.subname])
        subnameGroups[d.branchId + d.subname] = [];
      subnameGroups[d.branchId + d.subname].push({ d, index });
    });

    Object.values(subnameGroups).forEach((arr) => {
      if (arr.length > 1) {
        arr.forEach((e, count) => {
          data[e.index].subname += " " + (count + 1);
        });
      }
    });

    return data;
  }

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="9"
        >
          {value === 0 || value ? value + "%" : ""}
        </text>
      </g>
    );
  };

  function getNumberOfBars(branchId) {
    return branches.find((b) => b.id == branchId).audits.length;

    var max = 0;
    branches.forEach((branch) => {
      max = branch.audits.length > max ? branch.audits.length : max;
    });
    return max;
  }

  const renderBranchTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value } = payload;
    const month = parseInt(value.split("___")[1]);
    const maxNumber = getNumberOfBars(Number(value.split("___")[2]));

    if (maxNumber === 1 || month === Math.floor(maxNumber / 2)) {
      const even = maxNumber % 2 == 0;

      const xValue =
        x + (maxNumber === 1 ? 250 / (branches.length - 1) : !even ? 10 : 0); //-600 / (maxNumber * branches.length)); -750 / (maxNumber * branches.length)

      const yValue = y;
      return (
        <text
          x={xValue}
          y={yValue}
          textAnchor="middle"
          fontSize="12"
          transform={`rotate(-30, ${xValue}, ${yValue})`}
        >
          {value?.split("___")[0]}
        </text>
      );
    }
    if (month === maxNumber || month == 0)
      return <path d={`M${x},${y + 12}v${-51}`} stroke="black" />;
  };

  const renderMonthTick = (tick) => {
    return tick?.split(" ").join("") || "";
  };

  return (
    <ResponsiveContainer width={"100%"} height={392}>
      <BarChart
        data={generateData2()}
        margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
        //layout="vertical"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="subname"
          style={{
            fontSize: "12px",
          }}
          interval={0}
          textAnchor="middle"
          tickFormatter={renderMonthTick}
        />
        <XAxis
          dataKey="name"
          tick={renderBranchTick}
          axisLine={false}
          tickLine={false}
          interval={0}
          scale="band"
          textAnchor="middle"
          xAxisId="quarter"
        />

        <YAxis
          type="number"
          tick={{ fontSize: "0.8em" }}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tickFormatter={(value) => `${value}%`}
          dataKey="value"
        />
        <Tooltip
          formatter={(value, name, props) => {
            return [
              `${props.payload.name?.split("___")[0] || name}: ${value || 0}%`,
            ];
          }}
        />
        <Bar dataKey={`audit`} maxBarSize={40}>
          {generateData2()?.map((entry, index) => (
            <Cell
              key={`cell-${entry.id}`}
              fill={flagHexColor(Number(entry.audit) || 0)}
            />
          ))}
          <LabelList dataKey="audit" content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const PillarBarChartConstruct = (pillars, branches) => {
  if (!pillars || !branches?.length) return null;

  function generateData(pillarId) {
    const data = [];
    let monthsInBranch = [];
    branches.forEach((branch) => {
      const months = branch.audits.map(
        ({ date }) =>
          new Date(date).getMonth() * 0.01 + new Date(date).getFullYear()
      );
      monthsInBranch = [
        ...monthsInBranch,
        ...months.filter((month) => !monthsInBranch.includes(month)),
      ];
    });

    monthsInBranch = monthsInBranch.sort((a, b) => a - b);
    monthsInBranch = monthsInBranch.map((month) =>
      parseInt(month.toFixed(2).split(".")[1])
    );

    monthsInBranch = Array.from(new Set(monthsInBranch));

    branches.map((branch) => {
      const branchValues = [];
      let counter = 0;
      const audits = branch.audits.reverse();
      audits.map((audit) => {
        const { table1 } = audit.reportGenerated;

        for (let i = 0; i < table1.length - 1; i++) {
          if (table1[i].pillarId == pillarId) {
            const value = { name: "" + branch.name };
            value.audit = Math.round(table1[i].score * 100);
            value.subname = monthNames[new Date(audit.date).getMonth()];
            branchValues.push({
              ...value,
              month: new Date(audit.date).getMonth(),
            });
            //data.push(value);
            //counter++;
          }
        }
      });

      monthsInBranch.forEach((month) => {
        const value = branchValues.find(
          (branchValue) => branchValue.month == month
        );
        if (value) {
          value.name += "___" + counter++;
          data.push(value);
        } else {
          data.push({
            name: branch.name + "___" + counter++,
            audit: "",
            subname: "",
          });
        }
      });
    });

    return data;
  }

  function generateData2(pillarId) {
    let data = [];
    branches.map((branch) => {
      let reset = 0;
      let branchAudits = [];
      branch.audits.map((audit) => {
        const { table1 } = audit.reportGenerated;

        for (let i = 0; i < table1.length - 1; i++) {
          if (table1[i].pillarId == pillarId) {
            branchAudits.push({
              name: branch.name,
              audit: Math.round(table1[i].score * 100),
              subname: monthNames[new Date(audit.date).getMonth()].substring(
                0,
                3
              ),
              month: new Date(audit.date).getMonth(),
              date: new Date(audit.date),
              branchId: branch.id,
            });
          }
        }
      });
      branchAudits.sort((a, b) => a.date - b.date);
      branchAudits.forEach((_, index) => {
        branchAudits[index].name +=
          "___" + index + "___" + branchAudits[index].branchId;
      });
      data = data.concat(branchAudits);
      reset = 0;
    });

    const subnameGroups = {};
    data.forEach((d, index) => {
      if (!subnameGroups[d.branchId + d.subname])
        subnameGroups[d.branchId + d.subname] = [];
      subnameGroups[d.branchId + d.subname].push({ d, index });
    });

    Object.values(subnameGroups).forEach((arr) => {
      if (arr.length > 1) {
        arr.forEach((e, count) => {
          data[e.index].subname += " " + (count + 1);
        });
      }
    });

    return data;
  }

  function calculateAverageDiff(pillarId) {
    let averageNew = 0,
      averageOld = 0,
      counterNew = 0,
      counterOld = 0;

    let pillarDescription = "";

    branches.forEach((branch) => {
      const audit1 =
        branch.audits.length > 1
          ? branch.audits[branch.audits.length - 2]
          : null;
      const audit2 =
        branch.audits.length > 0
          ? branch.audits[branch.audits.length - 1]
          : null;

      if (audit1 && audit2) {
        const table1 = audit1.reportGenerated.table1;
        const table2 = audit2.reportGenerated.table1;

        for (let i = 0; i < table1.length - 1; i++) {
          if (table1[i].pillarId == pillarId) {
            averageNew += table1[i]?.score;
            counterNew++;
            pillarDescription = table1[i]?.description;
            break;
          }
        }

        for (let i = 0; i < table2.length - 1; i++) {
          if (table2[i]?.pillarId == pillarId) {
            averageOld += table2[i]?.score;
            counterOld++;
            pillarDescription = table1[i]?.description;
            break;
          }
        }
      }
    });

    if (counterNew > 0) averageNew /= branches.length;
    if (counterOld > 0) averageOld /= branches.length;
    const difference = (averageOld - averageNew) * 100;

    let text = (
      <>
        {Math.abs(averageOld * 100).toFixed(2)}%{" "}
        <span
          style={{
            fontWeight: "bold",
            color:
              difference > 0 ? colors.green : difference < 0 ? colors.red : "",
          }}
        >
          {difference >= 0 ? "↑" : "↓"}
        </span>
      </>
    );

    return {
      value: difference,
      text,
    };
  }

  const getPillars = () => {
    const keptPillars = [];

    branches.forEach((branch) => {
      const { table1 } =
        branch.audits[branch.audits.length - 1].reportGenerated;
      table1.forEach((item) => {
        const pillar = pillars.find(
          (pillar) => pillar.pillarId == item.pillarId
        );
        if (pillar) {
          keptPillars.push({ pillar, audits: branch.audits });
        }
      });
    });

    const returnedPillars = [];

    keptPillars.map((keptPillar) => {
      const foundPillar = returnedPillars.find(
        (p) => p.pillar.pillarId == keptPillar.pillar.pillarId
      );
      if (!foundPillar) {
        returnedPillars.push(keptPillar);
      }
    });

    returnedPillars.sort((a, b) =>
      a.pillar.referenceCode.localeCompare(b.pillar.referenceCode)
    );

    return returnedPillars;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="9"
        >
          {value === 0 || value ? value + "%" : ""}
        </text>
      </g>
    );
  };

  function getNumberOfBars(branchId) {
    return branches.find((b) => b.id == branchId).audits.length;
    var max = 0;
    branches.forEach((branch) => {
      max = branch.audits.length > max ? branch.audits.length : max;
    });
    return max;
  }

  const renderBranchTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value } = payload;
    const month = parseInt(value.split("___")[1]);
    const maxNumber = getNumberOfBars(Number(value.split("___")[2]));

    if (maxNumber === 1 || month === Math.floor(maxNumber / 2)) {
      const even = maxNumber % 2 == 0;

      const xValue =
        x + (maxNumber === 1 ? 250 / (branches.length - 1) : !even ? 0 : 0); //-750 / (maxNumber * branches.length)); //-600 / (maxNumber * branches.length));

      const yValue = y;
      return (
        <text
          x={xValue}
          y={yValue}
          textAnchor="middle"
          fontSize="12"
          transform={`rotate(-30, ${xValue}, ${yValue})`}
        >
          {value?.split("___")[0]}
        </text>
      );
    }
    if (month === maxNumber || month == 0)
      return <path d={`M${x},${y + 12}v${-51}`} stroke="black" />;
  };

  const renderMonthTick = (tick) => {
    return tick?.split(" ")?.join("") || "";
  };

  return getPillars().map(({ pillar, audits }, index) => {
    return (
      <div
        className={`${styles["pdf-item-container"]} printable`}
        id="barchart"
        key={"pillar for pillarChart: " + index}
      >
        <div className={styles["pdf-margin"]}></div>
        <div className={styles["pdf-item"]}>
          <h3
            style={{ textAlign: "center" }}
          >{`${pillar.referenceCode}. ${pillar.description}`}</h3>
          <ResponsiveContainer width={"100%"} height={392}>
            <BarChart
              data={generateData2(pillar.id)}
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
              //layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="subname"
                style={{
                  fontSize: "12px",
                }}
                interval={0}
                textAnchor="middle"
                tickFormatter={renderMonthTick}
              />
              <XAxis
                dataKey="name"
                tick={renderBranchTick}
                axisLine={false}
                tickLine={false}
                interval={0}
                scale="band"
                textAnchor="middle"
                xAxisId="quarter"
              />

              <YAxis
                type="number"
                tick={{ fontSize: "0.8em" }}
                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                tickFormatter={(value) => `${value}%`}
                dataKey="value"
              />
              <Tooltip
                formatter={(value, name, props) => {
                  return [
                    `${props.payload.name?.split("___")[0] || name}: ${
                      value || 0
                    }%`,
                  ];
                }}
              />
              <Bar dataKey={`audit`} maxBarSize={40}>
                {generateData2(pillar.id)?.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.id}`}
                    fill={flagHexColor(Number(entry.audit) || 0)}
                  />
                ))}
                <LabelList dataKey="audit" content={renderCustomizedLabel} />
              </Bar>
              {/*Array.from({ length: getNumberOfBars() }).map((_, index) => (
                <Bar
                  key={`audit`}
                  dataKey={`audit`}
                  fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                >
                   <LabelList
                    dataKey={`Subname ${index}`}
                    content={renderCustomizedLabel}
                  />
                </Bar>
              ))*/}
            </BarChart>
          </ResponsiveContainer>
          <h4 style={{ textAlign: "center" }}>
            Average={calculateAverageDiff(pillar.id).text}
          </h4>
        </div>
        <div className={styles["pdf-margin"]}></div>
      </div>
    );
  });
};

const PieChartConstruct = (pillars, branches) => {
  if (!pillars || !branches || !branches?.length) return null;
  const singleBranch = branches?.length === 1 ? branches[0] : false;

  const constructBranchPieData = (branchesData) => {
    if (singleBranch) {
      const branchPieArray = singleBranch.audits.map((audit, index) => {
        return {
          name: monthNames[new Date(audit.date).getMonth()],
          value: Math.round(
            audit.reportGenerated.table1[
              audit.reportGenerated.table1.length - 1
            ].totalPercentage * 100
          ),
          //footer: "Overall Performance",
          color: flagHexColor(
            Math.round(
              audit.reportGenerated.table1[
                audit.reportGenerated.table1.length - 1
              ].totalPercentage * 100
            ) || 0
          ),
        };
      });

      return branchPieArray;
    } else {
      const branchPieArray = branchesData.map((branch, index) => {
        const lastAudit = branch.audits[branch.audits.length - 1];
        const prevLastAudit = branch.audits[branch.audits.length - 2];

        return {
          name: branch.name,
          value: Math.round(
            lastAudit.reportGenerated.table1[
              lastAudit.reportGenerated.table1.length - 1
            ].totalPercentage * 100
          ),
          footer: prevLastAudit ? (
            <span>
              Overall Performance{" "}
              {lastAudit.reportGenerated.table1[
                lastAudit.reportGenerated.table1.length - 1
              ].totalPercentage >
              prevLastAudit.reportGenerated.table1[
                prevLastAudit.reportGenerated.table1.length - 1
              ].totalPercentage ? (
                <span
                  style={{ color: "green", fontSize: "30px", fontWeight: 900 }}
                >
                  ↑
                </span>
              ) : (
                <span
                  style={{ color: "red", fontSize: "30px", fontWeight: 900 }}
                >
                  ↓
                </span>
              )}
            </span>
          ) : (
            ""
          ),
          color: flagHexColor(
            Math.round(
              branch.audits[branch.audits.length - 1].reportGenerated.table1[
                branch.audits[branch.audits.length - 1].reportGenerated.table1
                  .length - 1
              ].totalPercentage * 100
            ) || 0
          ),
        };
      });
      return branchPieArray;
    }
  };

  const dataPie = constructBranchPieData(branches);

  const pies = dataPie.map((pie, index) => {
    const { name, value, color, footer } = pie;
    const data = value;
    const title = name;
    const keyProp = "Pie: " + index;
    const pieChartStyle = {
      background: `conic-gradient(from 0deg, ${color} 0%, ${color} ${data}%, #98958e ${data}%, #98958e 100%)`,
    };
    return (
      <div className={`${styles["pie-chart"]}`} key={keyProp}>
        <div className={styles["pie-title"]} style={{ textAlign: "center" }}>
          {title}
        </div>
        <div className={styles["pie-chart-circle"]} style={pieChartStyle}>
          <h1 className={styles["pie-center-value"]}>{data}%</h1>
        </div>
        <div className={styles["pie-footer"]}>{footer}</div>
      </div>
    );
  });

  return pies.map((pie, index) => {
    if (index % 2 == 0) {
      return (
        <div
          className={`${styles["pdf-item-container"]} printable`}
          id="piechart"
          key={"pillar for pieChart: " + index}
        >
          <div className={styles["pdf-item"]}>
            {pie}
            {index < pies.length ? pies[index + 1] : null}
          </div>
        </div>
      );
    }
  });
};

const DashboardTableConstruct = (pillars, branches) => {
  if (!branches || !pillars)
    return {
      dashboardTableHtml: null,
      score: 0,
    };

  const singleBranch = branches?.length === 1 ? branches[0] : false;

  function calculateBranchValues(branch) {
    if (!branch) return;
    const { table1 } = branch.audits[branch.audits.length - 1]?.reportGenerated;
    if (!table1) return;
    const values = {};
    for (let i = 0; i < table1.length - 1; i++) {
      values[table1[i].pillarId] = {
        name: branch.name, // table1[i].description
        value: (table1[i].score * 100).toFixed(2),
        pillar: table1[i],
        totals: table1[table1.length - 1],
        ignore: table1[i].ignore,
      };
    }

    return values;
  }

  function calculateAuditValues(audit) {
    const { table1 } = audit.reportGenerated;
    const values = {};
    for (let i = 0; i < table1.length - 1; i++) {
      values[table1[i].pillarId] = {
        name: monthNames[new Date(audit.date).getMonth()], // table1[i].description
        value: (table1[i].score * 100).toFixed(2),
        pillar: table1[i],
        totals: table1[table1.length - 1],
        ignore: table1[i].ignore,
      };
    }

    return values;
  }

  function calculatePillarValues() {
    const answer = {};

    if (singleBranch) {
      singleBranch.audits.map((audit) => {
        const values = calculateAuditValues(audit);
        Object.keys(values).forEach((key) => {
          if (!answer[key])
            answer[key] = {
              pillar: values[key].pillar,
              average: 0,
              count: 0,
              audits: {},
            };
          answer[key].audits[audit.id] = values[key];

          if (
            (values[key].value || values[key].value == 0) &&
            !values[key].ignore
          ) {
            answer[key].average += Number(values[key].value);
            answer[key].count++;
          }
        });
      });
      Object.keys(answer).map((key) => {
        answer[key].average /= answer[key].count;
      });

      return answer;
    }

    branches.forEach((branch) => {
      const values = calculateBranchValues(branch);
      Object.keys(values).forEach((key) => {
        if (!answer[key])
          answer[key] = {
            pillar: values[key].pillar,
            average: 0,
            count: 0,
            branches: {},
          };
        answer[key].branches[branch.id] = values[key];

        if (
          (values[key].value || values[key].value == 0) &&
          !values[key].ignore
        ) {
          answer[key].average += Number(values[key].value);
          answer[key].count++;
        }
      });
    });

    Object.keys(answer).map((key) => {
      answer[key].average /= answer[key].count;
    });

    return answer;
  }

  let totalWeights = 0;

  const pillarValues = calculatePillarValues();

  function calculateAverage() {
    let average = 0;

    if (singleBranch) {
      singleBranch.audits.map((audit) => {
        const { table1 } = audit.reportGenerated;

        average += table1[table1.length - 1].totalPercentage;
      });

      return ((average * 100) / singleBranch.audits.length).toFixed(2);
    } else {
      branches.map((branch) => {
        const { table1 } =
          branch.audits[branch.audits.length - 1].reportGenerated;

        average += table1[table1.length - 1].totalPercentage;
      });
      return ((average * 100) / branches.length).toFixed(2);
    }
  }

  return {
    dashboardTableHtml: (
      <div
        className={`${styles["pdf-item-container"]} printable`}
        id="table-scrollable"
      >
        <div className={styles["pdf-margin"]}></div>
        <div className={styles["pdf-item"]} style={{ overflowX: "scroll" }}>
          <table>
            <thead>
              <tr>
                <th
                  className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                  style={{ width: 50 }}
                  rowSpan={2}
                >
                  Ref.
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                  rowSpan={2}
                  style={{ width: "120px" }}
                >
                  Category
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                  rowSpan={2}
                >
                  Weight
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                  colSpan={
                    singleBranch ? singleBranch.audits.length : branches.length
                  }
                >
                  Score %
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                  rowSpan={2}
                >
                  Average
                </th>
              </tr>
              <tr>
                {singleBranch
                  ? singleBranch.audits.map((audit) => {
                      return (
                        <th
                          className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                          style={{ fontSize: "14px" }}
                        >
                          {monthNames[new Date(audit.date).getMonth()]}
                        </th>
                      );
                    })
                  : branches.map((branch) => (
                      <th
                        className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                        style={{
                          fontSize: "14px",
                          minWidth: "300px !important",
                        }}
                      >
                        {branch.name}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {pillarValues &&
                branches &&
                pillars &&
                Object.keys(pillarValues).map((pillarId) => {
                  totalWeights += pillarValues[pillarId]?.pillar?.weight;
                  return (
                    <tr key={"Row: " + pillarId}>
                      <td
                        className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                      >
                        {pillarValues[pillarId]?.pillar?.referenceCode}
                      </td>
                      <td
                        className={`${styles["color-3-cell"]} ${styles["left-align-cell"]}`}
                      >
                        {pillarValues[pillarId]?.pillar?.description}
                      </td>
                      <td
                        className={styles["color-2-cell"]}
                        style={{ width: "30px" }}
                      >
                        {pillarValues[pillarId]?.pillar?.weight
                          .toFixed(2)
                          .replace(".00", "")}
                      </td>
                      {!singleBranch &&
                        branches.map((branch, index) => {
                          return (
                            <td
                              key={"td of branch:" + branch?.id + " - " + index}
                              className={styles["color-3-cell"]}
                              style={{ minWidth: "300px !important" }}
                            >
                              {pillarValues[pillarId].branches[branch.id]?.value
                                ? pillarValues[pillarId].branches[branch.id]
                                    ?.ignore
                                  ? "—"
                                  : pillarValues[pillarId].branches[branch.id]
                                      ?.value + "%"
                                : "—"}
                            </td>
                          );
                        })}

                      {singleBranch
                        ? singleBranch.audits.map((audit, index) => {
                            return (
                              <td
                                key={"td of audit:" + audit?.id + " - " + index}
                                className={styles["color-3-cell"]}
                              >
                                {pillarValues[pillarId].audits[audit.id]?.value
                                  ? pillarValues[pillarId].audits[audit.id]
                                      ?.ignore
                                    ? "—"
                                    : pillarValues[pillarId].audits[audit.id]
                                        ?.value + "%"
                                  : (0).toFixed(2) + "%"}
                              </td>
                            );
                          })
                        : null}

                      <td
                        className={`${styles["color-2-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                      >
                        {pillarValues[pillarId]?.average ||
                        pillarValues[pillarId]?.average == 0
                          ? pillarValues[pillarId]?.average.toFixed(2) + "%"
                          : "—"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <td className={styles["color-1-cell"]} rowSpan={2}></td>
                <td className={styles["color-3-cell"]}>Total</td>
                <td className={styles["color-2-cell"]}>
                  {totalWeights.toFixed(2).replace(".00", "")}
                </td>
                {!singleBranch &&
                  branches.map((branch, index) => {
                    const values = calculateBranchValues(branch);
                    return (
                      <td
                        key={"td2 of branch:" + branch?.id + " - " + index}
                        className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                        rowSpan={2}
                      >
                        {(
                          values[Object.keys(values)[0]].totals
                            .totalPercentage * 100
                        ).toFixed(2)}
                        %
                      </td>
                    );
                  })}

                {singleBranch
                  ? singleBranch.audits.map((audit, index) => {
                      const values = calculateAuditValues(audit);
                      return (
                        <td
                          key={"td2 of audit:" + audit?.id + " - " + index}
                          className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                          rowSpan={2}
                        >
                          {(
                            values[Object.keys(values)[0]].totals
                              .totalPercentage * 100
                          ).toFixed(2)}
                          %
                        </td>
                      );
                    })
                  : null}
                <td
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                  rowSpan={2}
                >
                  {calculateAverage()}%
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                >
                  Total %
                </td>
                <td
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                >
                  100%
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className={styles["pdf-margin"]}></div>
      </div>
    ),
    score: calculateAverage(),
  };
};

const getLastThreeMonthsNames = () => {
  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const lastThreeMonthsNames = [];
  for (let i = 3; i >= 1; i--) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );
    const monthName = monthNames[date.getMonth()];
    lastThreeMonthsNames.push(monthName);
  }

  return lastThreeMonthsNames;
};

const constructBranchChartData = (branchesData, lastThreeMonths) => {
  const currentDate = new Date();

  // Construct branch chart data for each branch
  const branchChartArray = branchesData.flatMap((branch) => {
    return lastThreeMonths.map((month) => {
      const monthIndex = lastThreeMonths.indexOf(month);
      //const value = branch.data[monthIndex] || 0; // If data is not available, default to 0
      const value = Math.floor(Math.random() * (90 - 30 + 1)) + 30; // If data is not available, default to 0
      return {
        branchName: branch.name,
        monthName: month,
        [month]: value,
      };
    });
  });

  return branchChartArray;
};
