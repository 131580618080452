import Axios from "axios";
import FormData from "form-data";
import { API_URL } from "config";

Axios.defaults.baseURL = API_URL;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url, params, cancelToken) => {
    url = generateParameterizedUrl(url, params);
    return await this.request(
      this.getOptionsConfig("get", url, null, cancelToken)
    );
  };

  post = async (url, data, params) => {
    url = generateParameterizedUrl(url, params);
    return await this.request(this.getOptionsConfig("post", url, data));
  };

  put = async (url, data, params) => {
    url = generateParameterizedUrl(url, params);
    return await this.request(this.getOptionsConfig("put", url, data));
  };

  patch = async (url, data) =>
    await this.request(this.getOptionsConfig("patch", url, data));

  delete = async (url, params) => {
    url = generateParameterizedUrl(url, params);
    return await this.request(this.getOptionsConfig("delete", url));
  };

  uploadFilesWithData = async (url, files, body, method, params) => {
    let data = new FormData();
    files.map((file) => {
      data.append("files", file);
    });
    data.append("body", JSON.stringify(body));
    url = generateParameterizedUrl(url, params);
    return await this.request(this.getFileUploadOptions(method, url, data));
  };

  getOptionsConfig = (method, url, data, cancelToken) => {
    const options = {
      method,
      url,
      data,
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    if (cancelToken) options.cancelToken = cancelToken;
    return options;
  };

  /*getImageUploadOptions = (method, url, data) => {
    return {
      method,
      url,
      data,
      header: {
        Accept: "application/vnd.api+json",
        "Content-Type": `multipart/form-data`,
        "Access-Control-Allow-Credentials": true,
      },
    };
  };*/

  getFileUploadOptions = (method, url, data, cancelToken) => {
    return {
      method,
      url,
      data,
      header: {
        Accept: "application/vnd.api+json",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Credentials": true,
      },
    };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => {
          res.ok = res.status == 200;

          if (res?.data?.notifications) {
            const event = new CustomEvent("notifications-updated", {
              detail: res.data.notifications,
            });
            document.dispatchEvent(event);
          }

          if (res?.data?.user) {
            const event = new CustomEvent("user-updated", {
              detail: res.data.user,
            });
            document.dispatchEvent(event);
          }

          return resolve([
            res,
            res?.data?.notifications ? res.data.body : res.data,
          ]);
        })
        .catch((ex) => {
          if (!ex.response) return;
          ex.response.ok = ex.response.status == 200;
          return resolve([
            ex.response,
            ex.response?.data?.body ? ex.response.data.body : ex.response.data,
          ]);
        });
    });
  }
}

function generateParameterizedUrl(url, params) {
  if (params) {
    url += "?";
    const keys = Object.keys(params);
    keys.map((key) => {
      url += key + "=" + params[key] + "&";
    });
    return url.slice(0, -1);
  }

  return url;
}

export default new HttpService();
