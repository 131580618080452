import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AlertDialog from "components/AlertDialog";
import BasicPopover from "components/BasicPopover";
import DropdownSelect from "components/DropdownSelect";
import Avatar from "components/FileAvatar";
import InfoPanel from "components/InfoPanel";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import TabButton from "components/Tabs/TabButton";
import TabElement from "components/Tabs/TabElement";
import { ACCOUNT_TYPE, EXTERNAL, INTERNAL } from "const";
import { useAuth } from "contexts/auth.context";
import { useLoading } from "contexts/loading.context";
import AllBranches from "layouts/branches/components/AllBranches";
import AllRoles from "layouts/roles/components/AllRoles";
import AllSubscriptions from "layouts/subscriptions/components/AllSubscriptions";
import AllThemes from "layouts/themes/components/AllThemes";
import AllUsers from "layouts/users/components/AllUsers";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import accountsService from "services/accounts-service";
import locationService from "services/location-service";
import regionsService from "services/regions-service";
import SubscriptionDialog from "./SubscriptionDialog";
import SubscriptionItem from "./SubscriptionItem";
import AccountSubscriptionDialog from "./AccountSubscriptionDialog";

function Account({ setRoute, permissions }) {
  const params = useParams();
  const mode = params.mode === "new" ? "create" : "edit";
  const id = mode == "edit" ? Number(params.id) : null;
  const parentId = mode == "create" ? Number(params.id) : null;
  const loadingController = useLoading();
  const auth = useAuth();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [account, setAccount] = useState({ parentId });
  const [updatedAccount, setUpdatedAccount] = useState({ parentId });
  const [options, setOptions] = useState({
    accounts: [],
    accountTypes: [],
    countries: [],
  });
  const [loading, setLoading] = useState({ fetch: true });
  const [open, setOpen] = useState({
    alertUserDialog: false,
    showMoreSubscriptions: false,
    tab: "subscriptions",
  });

  const handleChangeField = (field, value) => {
    setAccount((curr) => ({ ...curr, [field]: value }));
    if (field !== "users") {
      setUpdatedAccount((curr) => ({ ...curr, [field]: value }));
    }
  };

  const handleSave = async () => {
    if (mode == "create") {
      const [response, data] =
        await accountsService.createAccount(updatedAccount);
      if (response.ok) {
        navigate("/accounts/" + data.id);
        setAccount(data);
        setUpdatedAccount({});
      }
    } else if (mode == "edit") {
      const [response, data] = await accountsService.updateAccount(
        id,
        updatedAccount
      );
      if (response.ok) {
        setAccount(data);
        setUpdatedAccount({});
        await auth.getUser();
      }
    }
  };

  const fetchAccount = async () => {
    const [response, data] = await accountsService.getAccount(id);
    if (response.ok) {
      setAccount(data);
      setUpdatedAccount({});
    } else {
      setAccount(null);
    }
  };

  const fetchAccounts = async () => {
    const [response, data] = await accountsService.getAccounts({ raw: true });
    if (response.ok) {
      setOptions((curr) => ({
        ...curr,
        accounts:
          mode === "edit" ? data.filter((account) => account.id != id) : data,
      }));
    }
  };

  const fetchAccountTypes = async () => {
    const [response, data] = await accountsService.getAccountTypes();
    if (response.ok) {
      setOptions((curr) => ({ ...curr, accountTypes: data }));
    }
  };

  const fetchCountries = async () => {
    const [response, data] = await regionsService.getCountries();
    if (response.ok) {
      setOptions((curr) => ({ ...curr, countries: data }));
    }
  };

  const fetchTerritories = async () => {
    const [response, data] = await locationService.getTerritories();
    if (response.ok) {
      setOptions((curr) => ({ ...curr, territories: data }));
    }
  };

  const handleSubscribe = async (value) => {
    const [response, data] = await accountsService.subscribe(
      account?.id,
      value
    );
    if (response.ok) {
      setAccount(data);
      await auth.getUser();
      return { success: true };
    } else {
      return { error: data.error };
    }
  };

  const handleUnsubscribe = async (value) => {
    const [response, data] = await accountsService.unsubscribe(account?.id, {
      subscriptionId: value.id,
    });
    if (response.ok) {
      setAccount(data);
      await auth.getUser();
      return { success: true };
    } else {
      return { error: data.error };
    }
  };

  const handleEditAccountSubscription = async (
    accountSubscriptionId,
    accountSubscription
  ) => {
    const [response, data] = await accountsService.updateAccountSubscription(
      account?.id,
      accountSubscriptionId,
      accountSubscription
    );
    if (response.ok) {
      setAccount(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  };

  useEffect(() => {
    setLoading((curr) => ({ ...curr, fetch: true }));
    loadingController.startLoading();
    const promises = [
      fetchAccounts(),
      fetchAccountTypes(),
      fetchCountries(),
      fetchTerritories(),
    ];
    if (mode === "edit") promises.concat([fetchAccount()]);
    Promise.all(promises).then(() => {
      setLoading((curr) => ({ ...curr, fetch: false }));
      loadingController.stopLoading();
    });
  }, []);
  useEffect(() => {
    if (setRoute && account?.name)
      setRoute(["Accounts", mode === "edit" ? account?.name : "New"]);
  }, [account]);
  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams((prev) => {
        prev.set("tab", "users");
        return prev;
      });
    }
  }, [searchParams]);

  const parentAccount = useMemo(() => {
    return options.accounts.find((acc) => {
      return acc.id == account?.parentId;
    });
  }, [account?.parentId, options]);

  const dropdownOptions = useMemo(() => {
    const value = {
      accounts:
        options.accounts?.map((account) => ({
          id: account?.id,
          label: account?.name,
        })) || [],
      accountTypes:
        options.accountTypes
          ?.map((accountType) => ({
            id: accountType.id,
            label: accountType.description,
          }))
          .filter((v) => {
            const acceptableIds = [1];

            if (parentAccount?.accountTypeId < 4) {
              acceptableIds.push(4);
            }
            if (parentAccount?.accountTypeId < 3) {
              acceptableIds.push(3);
            }

            if (parentAccount?.accountTypeId < 2) {
              acceptableIds.push(2);
            }

            if (!parentAccount) {
              acceptableIds.push(2);
              acceptableIds.push(3);
              acceptableIds.push(4);
            }

            return acceptableIds.includes(v.id);
          }) || [],
      countries:
        options.countries?.map((country) => ({
          id: country.id,
          label: country.name,
        })) || [],
      roles:
        account?.roles?.map((role) => ({
          id: role.id,
          label: role.description,
        })) || [],
      users:
        account?.users?.map((user) => ({ id: user.id, label: user.name })) ||
        [],
      territories:
        options?.territories
          ?.filter(
            (territory) =>
              account?.countries?.length &&
              territory.countryId == account?.countries?.[0]?.id
          )
          .map((territory) => ({
            id: territory.id,
            label: territory.name,
          })) || [],
    };

    if (
      account?.accountTypeId === 1 &&
      !value.accountTypes.find((a) => a.id === 1)
    ) {
      value.accountTypes.unshift({ id: 1, label: "Technical Administrator" });
    }

    return value;
  }, [options, account, parentAccount]);

  const saveable = useMemo(() => {
    return mode === "create" || JSON.stringify(updatedAccount) !== "{}";
  }, [updatedAccount]);

  const isSubscribed = useMemo(() => {
    let live = { internal: false, external: false };
    account?.subscriptions?.map((subscription) => {
      const expiresAt = new Date(subscription?.expiresAt);
      if (!subscription?.cancelledAt && expiresAt > new Date()) {
        if (subscription.subscription.type == INTERNAL)
          live.internal = subscription;
        else if (subscription.subscription.type == EXTERNAL)
          live.external = subscription;
      }
    });

    return live;
  }, [account]);

  const allData = useMemo(() => {
    return {
      users: {
        users: account?.users || [],
        roles: account?.roles || [],
        accountId: account?.id,
        accounts: account?.accounts || [],
        branchGroups: account?.branchGroups || [],
        branches:
          account?.branchGroups
            ?.map((branchGroup) =>
              branchGroup.branches.map((branch) => ({
                ...branch,
                branchGroup: {
                  id: branchGroup.id,
                  name: branchGroup.name,
                },
              }))
            )
            ?.reduce((prev, curr) => {
              return [...prev, ...curr];
            }, []) || [],
        account: account,
      },
      branchGroups: {
        branchGroups: account?.branchGroups,
        accountId: account?.id,
        account: account,
      },
      roles: { roles: account?.roles || [], accountId: account?.id },
      accountSubscriptions: {
        subscriptions: account?.accountSubscriptions,
        accountId: account?.id,
      },
      colorPalettes: {
        colorPalettes: account?.colorPalettes || [],
        accountId: account?.id,
      },
    };
  }, [account]);

  if (loading.fetch) return;
  if (!account) return "Account does not exist";

  return (
    <MDBox display="flex" flexDirection="column" gap={2}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
        style={{ position: "relative" }}
      >
        <Card>
          <MDBox display="flex" gap={1} p={2}>
            <Avatar
              attachment={account?.logo || ""}
              setAttachment={(file) => {
                handleChangeField("logo", file);
              }}
              readOnly={
                !(permissions?.account?.update || permissions?.account?.create)
              }
              sx={{ height: "96px", width: "96px" }}
            />
            <Grid container spacing={1}>
              <Grid item xs={mode == "edit" ? 4 : 6}>
                <MDInput
                  id="name-input"
                  required
                  fullWidth
                  label={"Name"}
                  value={account?.name || ""}
                  onChange={(e) => {
                    handleChangeField("name", e.target.value);
                  }}
                  readOnly={
                    !(
                      permissions?.account?.update ||
                      permissions?.account?.create
                    )
                  }
                />
              </Grid>
              {mode == "edit" ? (
                <Grid item xs={mode == "edit" ? 4 : 6}>
                  <DropdownSelect
                    label={"Parent account"}
                    initialValue={account?.parentId || ""}
                    onChange={(newValue) => {
                      handleChangeField("parentId", newValue?.id || 0);
                    }}
                    items={dropdownOptions.accounts}
                    fullWidth
                    readOnly={
                      mode == "edit"
                        ? !permissions?.account?.update
                        : !permissions?.account?.create
                    }
                  />
                </Grid>
              ) : null}
              <Grid item xs={mode == "edit" ? 4 : 6}>
                <DropdownSelect
                  label={"Account type"}
                  initialValue={account?.accountTypeId || ""}
                  onChange={(newValue) => {
                    handleChangeField("accountTypeId", newValue?.id);
                  }}
                  items={dropdownOptions.accountTypes}
                  required={true}
                  fullWidth
                  readOnly={
                    mode == "edit"
                      ? !permissions?.account?.update
                      : !permissions?.account?.create
                  }
                />
              </Grid>

              {mode === "edit" && (
                <Grid item xs={6}>
                  <DropdownSelect
                    label={"Account manager"}
                    initialValue={account?.accountManagerId || ""}
                    onChange={(newValue) => {
                      handleChangeField("accountManagerId", newValue?.id);
                    }}
                    items={dropdownOptions.users || []}
                    //required={true}
                    fullWidth
                    readOnly={
                      mode == "edit"
                        ? !permissions?.account?.update
                        : !permissions?.account?.create
                    }
                  />
                </Grid>
              )}

              <Grid item xs={mode === "edit" ? 6 : 12}>
                <DropdownSelect
                  //multiple
                  label={"Country"}
                  initialValue={
                    account?.countries?.map((country) => country?.id)[0] || []
                  }
                  onChange={(newValue) => {
                    handleChangeField("countries", [newValue]);
                    handleChangeField("territories", []);
                  }}
                  items={dropdownOptions.countries || []}
                  required={true}
                  fullWidth
                  readOnly={
                    mode == "edit" ? true : !permissions?.account?.create
                  }
                />
              </Grid>
              {mode === "edit" && (
                <Grid item xs={mode === "edit" ? 6 : 12}>
                  <DropdownSelect
                    multiple
                    label={
                      Boolean(account?.countries?.[0])
                        ? "Territories"
                        : "Territories (Select country first)"
                    }
                    initialValue={
                      account?.territories?.map((territory) => territory.id) ||
                      []
                    }
                    onChange={(newValue) => {
                      handleChangeField("territories", newValue);
                    }}
                    items={dropdownOptions.territories || []}
                    //required={true}
                    fullWidth
                    readOnly={
                      mode == "edit"
                        ? !permissions?.account?.update
                        : !permissions?.account?.create
                    }
                    disabled={!Boolean(account?.countries?.[0])}
                  />
                </Grid>
              )}
              {account?.accountTypeId === 1 && (
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={account?.hidden}
                        onChange={(e) => {
                          handleChangeField("hidden", e.target.checked);
                        }}
                        disabled={
                          mode == "edit"
                            ? !permissions?.account?.update
                            : !permissions?.account?.create
                        }
                      />
                    }
                    label="Hidden from others"
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <FormControlLabel
                  label={"Mark me as third-party auditor"}
                  control={
                    <Checkbox
                      checked={account?.isExternalAuditor}
                      onChange={(e) => {
                        handleChangeField(
                          "isExternalAuditor",
                          e.target.checked
                        );
                      }}
                    />
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={
                  !saveable
                    ? {
                        mt: -1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                      }
                }
              >
                <Collapse in={saveable}>
                  <Grid container spacing={1}>
                    {mode == "edit" ? (
                      <Grid item>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setUpdatedAccount({});
                            fetchAccount();
                          }}
                        >
                          Cancel
                        </MDButton>
                      </Grid>
                    ) : null}
                    <Grid item>
                      <MDButton type="submit" variant="gradient" color="info">
                        Save
                      </MDButton>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </form>

      {mode === "edit" &&
      account.accountTypeId != ACCOUNT_TYPE.TECHNICAL_ADMINISTRATOR &&
      account.accountTypeId != ACCOUNT_TYPE.ADMINISTRATOR ? (
        <Card>
          <MDBox display="flex" flexDirection="column" gap={1} p={2}>
            <MDTypography variant="h6">Subscriptions</MDTypography>
            <Grid container item xs={12} spacing={1}>
              <SubscriptionElement
                counts={account?.counts?.internal}
                open={open.internalSubscription}
                setOpen={setOpen}
                type={INTERNAL}
                subscription={isSubscribed.internal}
                isSubscribed={isSubscribed}
                onUnsubscribe={() => {
                  setOpen({
                    ...open,
                    alertSubscriptionDialog: { data: isSubscribed.internal },
                  });
                }}
                onSubscribe={() => {
                  setOpen((curr) => ({
                    ...curr,
                    subscriptionDialog: {
                      mode: "create",
                      data: { type: INTERNAL },
                    },
                    subscribe: false,
                  }));
                }}
              />

              <SubscriptionElement
                counts={account?.counts?.external}
                open={open.externalSubscription}
                setOpen={setOpen}
                type={EXTERNAL}
                subscription={isSubscribed.external}
                isSubscribed={isSubscribed}
                onUnsubscribe={() => {
                  setOpen({
                    ...open,
                    alertSubscriptionDialog: { data: isSubscribed.external },
                  });
                }}
                onSubscribe={() => {
                  setOpen((curr) => ({
                    ...curr,
                    subscriptionDialog: {
                      mode: "create",
                      data: { type: EXTERNAL },
                    },
                    subscribe: false,
                  }));
                }}
              />
            </Grid>
          </MDBox>
        </Card>
      ) : null}

      {mode == "edit" ? (
        <>
          {open.subscriptionDialog && (
            <SubscriptionDialog
              metadata={{ accountId: account.id }}
              isOpen={open.subscriptionDialog}
              action={open.subscriptionDialog.mode}
              data={
                open.subscriptionDialog.data
                  ? { ...open.subscriptionDialog.data }
                  : {}
              }
              onSubmit={async (subscription) => {
                return await handleSubscribe(subscription);
              }}
            />
          )}

          {open.alertSubscriptionDialog && (
            <AlertDialog
              open={open.alertSubscriptionDialog}
              setOpen={(value) => {
                setOpen((curr) => ({
                  ...curr,
                  alertSubscriptionDialog: value,
                }));
              }}
              title="Unsubscribe?"
              content={
                "Are you sure you want to unsubscribe? This action is irreversible!"
              }
              onOk={async () => {
                await handleUnsubscribe(open.alertSubscriptionDialog.data);
              }}
            />
          )}

          {open.editAccountSubscriptionDialog && (
            <AccountSubscriptionDialog
              open={open.editAccountSubscriptionDialog}
              setOpen={(value) => {
                setOpen((curr) => ({
                  ...curr,
                  editAccountSubscriptionDialog: value,
                }));
              }}
              data={open.editAccountSubscriptionDialog.data}
              onSubmit={handleEditAccountSubscription}
            />
          )}

          <Card>
            <MDBox
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              <TabButton
                value="users"
                label={`Users (${account?.users?.length})`}
              />
              <TabButton
                value="branchGroups"
                label={`Branch groups (${account?.branchGroups?.length || 0})`}
              />
              <TabButton
                value="roles"
                label={`Roles (${account?.roles?.length || 0})`}
              />
              <TabButton
                value="colorPalettes"
                label={`Color Palettes (${account?.colorPalettes?.length || 0})`}
              />
              {account.accountTypeId != ACCOUNT_TYPE.TECHNICAL_ADMINISTRATOR &&
              account.accountTypeId != ACCOUNT_TYPE.ADMINISTRATOR ? (
                <>
                  <TabButton
                    value="subscriptions history"
                    label={`Subscriptions history (${account?.subscriptions?.length})`}
                  />
                  {account?.accountTypeId === ACCOUNT_TYPE.SUBSCRIBER ? null : (
                    <TabButton
                      value="subscriptions"
                      label={`Subscriptions (${account?.accountSubscriptions?.length})`}
                    />
                  )}
                </>
              ) : null}
            </MDBox>
            {account.accountTypeId != ACCOUNT_TYPE.TECHNICAL_ADMINISTRATOR &&
            account.accountTypeId != ACCOUNT_TYPE.ADMINISTRATOR ? (
              <>
                <TabElement value="subscriptions history">
                  <MDBox sx={{ mt: 3 }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={2}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" color="white">
                        Subscriptions history{" "}
                        {permissions?.account.get
                          ? `(${account?.subscriptions?.length})`
                          : ""}
                      </MDTypography>
                      {/*permissions?.account.create && (
                        <>
                          <MDButton
                            size="small"
                            onClick={(e) => {
                              setOpen((curr) => ({
                                ...curr,
                                subscribe: e.currentTarget,
                              }));
                            }}
                            endIcon={
                              <Icon fontSize="large">keyboard_arrow_down</Icon>
                            }
                            disabled={
                              isSubscribed.internal && isSubscribed.external
                            }
                          >
                            Subscribe
                          </MDButton>
                          <Menu
                            open={Boolean(open.subscribe)}
                            anchorEl={open.subscribe}
                            onClose={() => {
                              setOpen((curr) => ({
                                ...curr,
                                subscribe: false,
                              }));
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              sx={{
                                p: 1,
                                px: 2,
                                m: 0,
                                mx: -1,
                                display: "flex",
                                flexDirection: "row",
                                gap: 1,
                              }}
                              disabled={isSubscribed.internal}
                              onClick={() => {
                                setOpen((curr) => ({
                                  ...curr,
                                  subscriptionDialog: {
                                    mode: "create",
                                    data: { type: INTERNAL },
                                  },
                                  subscribe: false,
                                }));
                              }}
                            >
                              Self-Service Subscriptions
                            </MenuItem>
                            <Tooltip
                              title={
                                isSubscribed.external
                                  ? "Already subscribed"
                                  : ""
                              }
                            >
                              <MenuItem
                                sx={{
                                  p: 1,
                                  px: 2,
                                  m: 0,
                                  mx: -1,
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 1,
                                }}
                                disabled={isSubscribed.external}
                                onClick={() => {
                                  setOpen((curr) => ({
                                    ...curr,
                                    subscriptionDialog: {
                                      mode: "create",
                                      data: { type: EXTERNAL },
                                    },
                                    subscribe: false,
                                  }));
                                }}
                              >
                                Third-Party Subscriptions
                              </MenuItem>
                            </Tooltip>
                          </Menu>
                        </>
                      )*/}
                    </MDBox>
                    <MDBox
                      p={2}
                      sx={
                        !open.showMoreSubscriptions &&
                        account?.subscriptions?.length > 3
                          ? {
                              maxHeight: "500px",
                              overflowY: "hidden",
                              position: "relative",
                            }
                          : {}
                      }
                    >
                      <Grid sx={{ mt: 1 }} container spacing={1}>
                        {account?.subscriptions?.length ? (
                          account?.subscriptions?.map((subscription, index) => {
                            const expiresAt = new Date(subscription?.expiresAt);

                            let live = false;
                            if (
                              !subscription?.cancelledAt &&
                              expiresAt > new Date()
                            ) {
                              live = true;
                            }

                            return (
                              <Grid
                                item
                                xs={12}
                                md={6}
                                lg={4}
                                key={"Subsss" + index}
                              >
                                <SubscriptionItem
                                  subscription={subscription}
                                  onUnsubsribe={() => {
                                    setOpen((curr) => ({
                                      ...curr,
                                      alertSubscriptionDialog: {
                                        data: subscription,
                                      },
                                    }));
                                  }}
                                  live={live}
                                />
                              </Grid>
                            );
                          })
                        ) : (
                          <MDTypography
                            variant="body2"
                            sx={{ fontSize: "1rem", fontWeight: "400", ml: 1 }}
                          >
                            You have no subscriptions yet.
                          </MDTypography>
                        )}
                      </Grid>
                      {!open.showMoreSubscriptions &&
                        account?.subscriptions?.length > 3 && (
                          <MDBox
                            sx={{
                              position: "absolute",
                              width: "calc(100% - 1rem)",
                              height: "200px",
                              background:
                                "linear-gradient(to top, white, transparent)",

                              bottom: 0,
                              zIndex: 800,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "end",
                            }}
                          >
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              sx={{ mb: 1 }}
                              onClick={() => {
                                setOpen((curr) => ({
                                  ...curr,
                                  showMoreSubscriptions: true,
                                }));
                              }}
                            >
                              Show all
                            </MDButton>
                          </MDBox>
                        )}
                    </MDBox>
                  </MDBox>
                </TabElement>
                {account?.accountTypeId === ACCOUNT_TYPE.SUBSCRIBER ? null : (
                  <TabElement value="subscriptions">
                    <AllSubscriptions
                      permissions={permissions?.subscriptions}
                      data={allData.accountSubscriptions}
                      onRefresh={(data) => {
                        setAccount({ ...account, accountSubscriptions: data });
                      }}
                      type="component"
                    />
                  </TabElement>
                )}
              </>
            ) : null}
            <TabElement value="users">
              <AllUsers
                permissions={permissions?.user}
                onRefresh={(data) => {
                  setAccount({ ...account, users: data });
                }}
                type="component"
                data={allData.users}
              />
            </TabElement>
            <TabElement value="branchGroups">
              <AllBranches
                permissions={{
                  branchGroup: permissions?.branchGroup,
                  branch: permissions?.branch,
                }}
                onRefresh={(data) => {
                  setAccount({ ...account, branchGroups: data });
                }}
                data={allData.branchGroups}
                type="component"
              />
            </TabElement>
            <TabElement value="roles">
              <AllRoles
                permissions={permissions?.role}
                onRefresh={(data) => {
                  setAccount({ ...account, roles: data });
                }}
                type="component"
                data={allData.roles}
              />
            </TabElement>
            <TabElement value="colorPalettes">
              <AllThemes
                permissions={permissions?.colorPalette}
                onRefresh={(data) => {
                  setAccount({ ...account, colorPalettes: data });
                }}
                type="component"
                data={allData.colorPalettes}
              />
            </TabElement>
          </Card>
        </>
      ) : null}
    </MDBox>
  );
}

function SubscriptionElement({
  open,
  setOpen,
  type,
  subscription,
  onSubscribe,
  onUnsubscribe,
  counts,
}) {
  return (
    <Grid item xs={6}>
      <MDBox display="flex" flexDirection="column" sx={{ height: "100%" }}>
        <MDBox
          sx={(theme) => ({
            border: "1px solid",
            borderColor: theme.palette.info.main,
            backgroundColor: theme.palette.info.main,
            color: "white",
            borderBottom: "none",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            px: 2,
            py: 1,
            m: 0,
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          })}
        >
          <MDTypography
            variant="h6"
            sx={{ fontWeight: "500", color: "white !important" }}
          >
            {type === INTERNAL
              ? "Self-Service Subscription"
              : "Third-Party Subsctipion"}
          </MDTypography>
          <InfoPanel size="small" color="white !important">
            <MDTypography
              variant="h6"
              sx={{ m: 0, p: 0, fontWeight: "400", fontSize: "0.9rem" }}
            >
              {type === INTERNAL
                ? "Self-Service subscriptions allow you to create your own audits."
                : "Third-Party subscriptions enable you to select someone else to create audits for your company on your behalf."}
            </MDTypography>
          </InfoPanel>
        </MDBox>
        {subscription ? (
          <SubscriptionItem
            live={true}
            onUnsubsribe={() => {
              setOpen((curr) => ({
                ...curr,
                alertSubscriptionDialog: {
                  data: subscription,
                },
              }));
            }}
            onEditDate={() => {
              setOpen((curr) => ({
                ...curr,
                editAccountSubscriptionDialog: {
                  data: subscription,
                },
              }));
            }}
            counts={counts}
            noUnsubscribe={false}
            subscription={subscription}
            sx={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
          />
        ) : (
          <MDButton
            sx={(theme) => ({
              boxShadow: "none",
              border: "1px dashed  rgba(0, 0, 0, 0.4)",
              boxSizing: "border-box",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderRadius: "6px",
              height: "100%",
              minHeight: "200px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
            })}
            onClick={() => {
              onSubscribe();
            }}
            startIcon={
              <Icon sx={{ color: "rgba(0, 0, 0, 0.4)" }} fontSize="64px">
                add
              </Icon>
            }
          >
            <MDTypography
              variant="h6"
              sx={{ color: "rgba(0, 0, 0, 0.4) !important" }}
            >
              Subscribe
            </MDTypography>
          </MDButton>
        )}
      </MDBox>
    </Grid>
  );
}

export default Account;
