import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import AlertDialog from "components/AlertDialog";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TableSearch from "components/TableSearch";
import { useAuth } from "contexts/auth.context";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import accountsService from "services/accounts-service";
import rolesService from "services/roles-service";
import RolesDialog from "./RolesDialog";

export default function AllRoles(props) {
  const columns = [
    {
      Header: "Description",
      accessor: "description",
      width: "20%",
    },
    {
      Header: "Permissions",
      accessor: "permissions",
      width: "65%",
    },
  ];

  if (
    props.permissions?.create ||
    props.permissions?.update ||
    props.permissions?.delete
  ) {
    columns.push({
      Header: "",
      accessor: "actions",
      width: "100%",
      align: "right",
    });
  }

  const { type = "page", data, onRefresh = () => {} } = props;

  const auth = useAuth();

  const [rows, setRows] = useState(null);

  const [roles, setRoles] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [accounts, setAccounts] = useState(null);

  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);

  const [rolesDialogOpen, setRolesDialogOpen] = useState(false);
  const [rolesAction, setRolesAction] = useState("create");

  const [currentPermission, setCurrentPermission] = useState(null);
  const [currentRole, setCurrentRole] = useState(null);

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogType, setAlertDialogType] = useState("permission");

  async function handleGetAccounts() {
    let [response, data] = await accountsService.getAccounts({ raw: true });
    if (response.ok) {
      setAccounts(data);
    } else {
    }
  }

  async function handleGetPermissions(cancelToken) {
    let [response, data] = await rolesService.getPermissions(null, cancelToken);
    if (response.ok) {
      setPermissions(data);
    } else {
    }
  }

  async function handleGetAccountsAndPermissions(cancelToken) {
    if (!props.permissions.get) return;

    await Promise.all([handleGetAccounts(), handleGetPermissions(cancelToken)]);
  }

  function updateRows() {
    //onRefresh(roles);
    setRows(
      roles.length > 0
        ? roles.map((role) => {
            return {
              description: (
                <MDTypography variant="inherit">
                  {role.description}
                </MDTypography>
              ),
              permissions: (
                <Stack
                  direction="row"
                  useFlexGap
                  spacing={{ xs: 1, sm: 1 }}
                  flexWrap="wrap"
                  sx={{ maxWidth: "calc(50vw)" }}
                >
                  {role.permissions.length > 0 &&
                    role.permissions.map((permission, index) => {
                      return (
                        <Chip
                          key={"permission " + index}
                          label={permission.description}
                          variant="outlined"
                          size="small"
                          /*onDelete={() => {
                            setCurrentRole({ ...role });
                            setCurrentPermission({ ...permission });
                            setAlertDialogOpen(Math.random());
                            setAlertDialogType("permission");
                          }}*/
                        />
                      );
                    })}
                  {role.permissions.length == 0 && (
                    <MDTypography variant="inherit" color="inherit">
                      There are no permissions
                    </MDTypography>
                  )}
                </Stack>
              ),
              actions: (
                <>
                  {props.permissions.create && (
                    <Tooltip title="Duplicate">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setRolesAction("create");
                          setCurrentRole({
                            ...role,
                            description:
                              role.description.trim() + " (duplicate)",
                          });
                          setRolesDialogOpen(Math.random());
                        }}
                      >
                        <Icon>content_copy</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {/*props.permissions.create && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setCurrentRole({ ...role });
                        setPermissionsDialogOpen(Math.random());
                      }}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  )*/}
                  {props.permissions.update && (
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setCurrentRole({ ...role });
                          setRolesAction("edit");
                          setRolesDialogOpen(Math.random());
                        }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {props.permissions.delete && (
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setCurrentRole({ ...role });
                          setAlertDialogOpen(Math.random());
                          setAlertDialogType("role");
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ),
            };
          })
        : [{ description: "No roles found." }]
    );
  }

  async function handleCreatePermission(newPermissions, currentRole) {
    const permissionsToAdd = newPermissions.map((newPermission) => {
      return {
        id: newPermission.id,
        roleId: currentRole.id,
      };
    });
    const [response, data] = await rolesService.updatePermissions(
      currentRole.id,
      props?.data ? { accountId: props?.data?.accountId } : null,
      permissionsToAdd
    );

    if (response.ok) {
      setRoles(data);
      onRefresh(data);
      return { success: true };
    } else {
      console.log("ERROR while updating permissions: " + data.error);
      return { error: data.error };
    }
  }

  async function handleCreateRole(newRole) {
    if (props?.data?.accountId) newRole.accountId = props?.data?.accountId;

    const [response, data] = await rolesService.createRole(
      props?.data ? { accountId: props?.data?.accountId } : null,
      { description: newRole.description, accountId: newRole.accountId }
    );

    if (response.ok) {
      const newRoles = data;
      const oldRoles = roles;

      const createdRole = newRoles.find(
        (role) => !oldRoles.find((r) => r.id == role.id)
      );

      if (newRole.permissions) {
        return await handleCreatePermission(newRole.permissions, createdRole);
      } else {
        if (response.ok) {
          setRoles(data);
          onRefresh(data);
          return { success: true };
        } else {
          console.log("ERROR while creating role: " + data.error);
          return { error: data.error };
        }
      }
    }
  }

  async function handleUpdateRole(newRole) {
    if (props?.data?.accountId) newRole.accountId = props?.data?.accountId;
    const [response, data] = await rolesService.updateRole(
      newRole.id,
      props?.data ? { accountId: props?.data?.accountId } : null,
      newRole
    );

    if (newRole.permissions) {
      const newPermissions = await handleCreatePermission(
        newRole.permissions,
        currentRole
      );
      if (auth.user.role.id == newRole.id) {
        auth.getUser();
      }
      return newPermissions;
    } else {
      if (response.ok) {
        setRoles(data);
        onRefresh(data);
        if (auth.user.role.id == newRole.id) {
          await auth.getUser();
        }
        return { success: true };
      } else {
        console.log("ERROR while updating role: " + data.error);
        return { error: data.error };
      }
    }
  }

  async function handleRemovePermission(permission) {
    const [response, data] = await rolesService.removePermission({
      permissionId: permission.id,
      roleId: currentRole.id,
      accountId: props?.data?.accountId || null,
    });

    if (response.ok) {
      setRoles(data);
      onRefresh(data);
      return { success: true };
    } else {
      console.log("ERROR while removing permission: " + data.error);
      return { error: data.error };
    }
  }

  async function handleRemoveRole(role) {
    const [response, data] = await rolesService.removeRole(
      role.id,
      props?.data ? { accountId: props?.data?.accountId } : null
    );

    if (response.ok) {
      setRoles(data);
      onRefresh(data);
      return { success: true };
    } else {
      console.log("ERROR while removing role: " + data.error);
      return { error: data.error };
    }
  }

  useEffect(() => {
    if (roles && permissions) {
      updateRows();
    }
  }, [roles, permissions]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    handleGetAccountsAndPermissions(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    setRoles(data.roles);
  }, [data]);

  return (
    <>
      <AlertDialog
        open={alertDialogOpen}
        setOpen={setAlertDialogOpen}
        title={"Are you sure?"}
        content={"Remove this " + alertDialogType + "?"}
        onOk={async () => {
          if (alertDialogType == "permission") {
            return await handleRemovePermission(currentPermission);
          } else {
            return handleRemoveRole(currentRole);
          }
        }}
      />

      {/*<PermissionsDialog
        action={"create"}
        data={{
          permissions: permissions,
          initialPermissions: currentRole?.permissions,
        }}
        isOpen={permissionsDialogOpen}
        onSubmit={async (newPermission) => {
          return await handleCreatePermission(newPermission, currentRole);
        }}
      />*/}

      <RolesDialog
        action={rolesAction}
        data={{ role: currentRole, permissions: permissions }}
        isOpen={rolesDialogOpen}
        accountId={props?.data?.accountId}
        onSubmit={async (newRole) => {
          if (rolesAction == "create") {
            return await handleCreateRole(newRole);
          } else {
            return await handleUpdateRole(newRole);
          }
        }}
      />

      <MDBox
        sx={
          type === "component"
            ? { pt: 3 }
            : {
                pt: 6,
                pb: 3,
              }
        }
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={type === "component" ? 2 : 3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Roles {props.permissions.get ? `(${roles?.length || 0})` : ""}
                </MDTypography>
                {props.permissions.create && (
                  <MDButton
                    size={type === "component" ? "small" : "medium"}
                    onClick={() => {
                      setRolesAction("create");
                      setCurrentRole({ description: "", permissions: [] });
                      setRolesDialogOpen(Math.random());
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new role
                  </MDButton>
                )}
              </MDBox>
              {props.permissions.get ? (
                <>
                  {type === "component" ? null : (
                    <MDBox p={2} mt={2}>
                      <TableSearch
                        id="all-roles"
                        internalLoading={!Boolean(roles)}
                        fullWidth
                        setData={setRoles}
                        onSearch={async (request, callback) => {
                          if (request.accountId === 0) {
                            delete request.accountId;
                          }
                          callback(await rolesService.getRoles(request));
                        }}
                        label={"Search roles"}
                        filterBySelectsItems={
                          accounts
                            ? [
                                {
                                  id: "accountId",
                                  items: [
                                    { value: 0, render: "All" },
                                    ...(accounts?.map((account) => {
                                      return {
                                        value: account.id,
                                        render: account.name,
                                      };
                                    }) || []),
                                  ],
                                  label: "Filter by account",
                                },
                              ]
                            : null
                        }
                      />
                    </MDBox>
                  )}
                  <MDBox mt={type === "component" ? 2 : null}>
                    <DataTable
                      loading={!Boolean(roles)}
                      table={{ columns: columns, rows: rows || [] }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
