import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import AlertDialog from "components/AlertDialog";
import CollapsibleTable from "components/CollapsibleTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { usePermissionMonitor } from "components/PermissionMonitor";
import TableSearch from "components/TableSearch";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import accountsService from "services/accounts-service";
import branchesService from "services/branches-service";
import subscriptionsService from "services/subscriptions-service";
import themeService from "services/theme-service";
import usersService from "services/users-service";
import BranchGroupDialog from "./BranchGroupDialog";
import ConsolidatedCreationReportDialog from "./ConsolidatedReportCreationDialog";

function AllBranches({ data, type = "page", onRefresh = () => {} }) {
  const props = { data };
  const can = usePermissionMonitor();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState({
    content: "",
    title: "",
    onOk: () => {},
    onCancel: () => {},
  });
  const [consolidatedReportDialog, setConsolidatedReportDialog] = useState({
    open: false,
    branchGroupId: null,
    setOpen: (open) => {
      setConsolidatedReportDialog((curr) => ({
        ...curr,
        open: open,
      }));
    },
  });
  const [branchGroupsAction, setBranchGroupsAction] = useState("create");
  const [branchGroupsDialogOpen, setBranchGroupsDialogOpen] = useState(false);
  const [currentBranchGroup, setCurrentBranchGroup] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [colorPalettes, setColorPalettes] = useState();
  const [accountManagers, setAccountManagers] = useState();
  const [accounts, setAccounts] = useState();
  const lastQuery = useRef({});
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);

  function updateRows(data) {
    setRows(
      data.map((branchGroup, index) => {
        return {
          name: (
            <MDTypography variant="inherit">
              {branchGroup.name}&nbsp;{" "}
              {can("read", "branch")
                ? `(${branchGroup?.branches?.length || "0"})`
                : ""}
            </MDTypography>
          ),
          extra: (
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              mr={3}
            >
              {can("read", "branch") && (
                <MDButton
                  size="small"
                  variant="gradient"
                  color="info"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    setConsolidatedReportDialog((curr) => ({
                      ...curr,
                      open: true,
                      branchGroupId: branchGroup.id,
                    }));
                  }}
                >
                  Consolidated report
                </MDButton>
              )}
              {/* <IconButton
                onClick={() => {
                  setConsolidatedReportDialog((curr) => ({
                    ...curr,
                    open: true,
                    branchGroupId: branchGroup.id,
                  }));
                }}
              >
                <Icon color="primary">assessment_icon</Icon>
              </IconButton>*/}
              {can("create", "branch") && (
                <IconButton
                  size="small"
                  onClick={() => {
                    handleCreateNewBranch(branchGroup);
                  }}
                >
                  <Icon>add</Icon>
                </IconButton>
              )}
              {can("update", "branchGroup") && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setBranchGroupsAction("edit");
                    setBranchGroupsDialogOpen(Math.random());
                    setCurrentBranchGroup({ ...branchGroup });
                  }}
                >
                  <Icon>edit</Icon>
                </IconButton>
              )}
              {can("delete", "branchGroup") && (
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDeleteBranchGroup(branchGroup);
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </MDBox>
          ),
          table: {
            columns: [
              { Header: "name", accessor: "name", width: "100%" },
              { Header: "category", accessor: "category", width: "100%" },
              { Header: "contacts", accessor: "contacts", width: "100%" },
              { Header: "region", accessor: "region", width: "100%" },
              {
                Header: " ",
                accessor: "actions",
                width: "100%",
                align: "right",
              },
            ],
            rows: branchGroup?.branches?.map((branch) => {
              return {
                name: (
                  <MDTypography
                    key={"Branchname: " + branch.name + " - id: " + branch.id}
                    variant="inherit"
                    onClick={() => {
                      handleViewBranch(branch.id);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    {branch.name}
                  </MDTypography>
                ),
                category: (
                  <MDTypography
                    key={
                      "Branchcat: " + branch.categoryId + " - id: " + branch.id
                    }
                    variant="inherit"
                    onClick={() => {
                      handleViewBranch(branch.id);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    {branch.subcategory.description}
                  </MDTypography>
                ),
                contacts: (
                  <MDTypography
                    key={
                      "Branchcontacts: " +
                      branch.contacts.length +
                      " - id: " +
                      branch.id
                    }
                    variant="inherit"
                    onClick={() => {
                      handleViewBranch(branch.id);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    {branch.contacts.length}
                  </MDTypography>
                ),
                region: (
                  <MDTypography
                    key={
                      "Branchregion: " +
                      branch.region.id +
                      " - id: " +
                      branch.id
                    }
                    variant="inherit"
                    onClick={() => {
                      handleViewBranch(branch.id);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    {branch.region.description}
                  </MDTypography>
                ),
                actions: (
                  <MDBox
                    display="flex"
                    flexDirection="row"
                    key={"branch actions: " + branch.id}
                  >
                    {can("update", "branch") && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleBranchMoreDetails(branch.id);
                        }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    )}
                    {can("delete", "branch") && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDeleteBranch(branch);
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    )}
                  </MDBox>
                ),
              };
            }),
          },
        };
      })
    );
  }

  async function fetch(cancelToken) {
    if (fetching) return;

    if (!(can("read", "branchGroup") || can("read", "branch"))) return;

    setFetching(true);

    handleGetAccounts();
    const [response, data] = can("read", "branch")
      ? await branchesService.getBranches(null, cancelToken)
      : can("read", "branchGroup")
        ? await branchesService.getBranchGroups(null, cancelToken)
        : [false, null];

    if (response.ok) {
      updateRows(data);
    } else {
      console.log("All branches error occured: " + data.error);
    }

    setFetching(false);
  }

  async function refreshData() {
    const [ok, data] = can("read", "branch")
      ? await branchesService.getBranches({
          ...lastQuery.current,
          ...(props?.data?.accountId
            ? { accountId: props?.data?.accountId }
            : {}),
        })
      : can("read", "branchGroup")
        ? await branchesService.getBranchGroups({
            ...lastQuery.current,
            ...(props?.data?.accountId
              ? { accountId: props?.data?.accountId }
              : {}),
          })
        : [false, null];

    if (ok) {
      updateRows(data);
      onRefresh(data);
    }
  }

  function handleCreateNewBranch(branchGroup) {
    navigate("/branches/new?branchGroup=" + branchGroup.id);
  }

  function handleEditBranch(id) {
    navigate("/branches/edit/" + id);
  }

  function handleViewBranch(id) {
    if (can("read", "branch") || can("update", "branch")) {
      navigate("/branches/" + id);
    }
  }

  function handleBranchMoreDetails(id) {
    navigate("/branches/" + id);
  }

  function handleDeleteBranchGroup(branchGroup) {
    setOpen(true);
    setDialog({
      content:
        "Are you sure you want to delete the branch group " +
        branchGroup.name +
        "? This will delete all of its branches!",
      title: "Delete " + branchGroup.name + "?",
      onOk: async () => {
        const [response, branchGroups] =
          await branchesService.removeBranchGroup(
            branchGroup.id,
            data ? { accountId: data.accountId } : null
          );
        if (response.ok) {
          /*updateRows(branchGroups);
          onRefresh(branchGroups);*/
          await refreshData();
        } else {
          console.log(
            "Error when removing branch group: " + branchGroups.error
          );
        }
      },
      onCancel: () => {},
    });
  }

  function handleDeleteBranch(branch) {
    setOpen(true);
    setDialog({
      content:
        "Are you sure you want to delete the branch " + branch.name + "?",
      title: "Delete " + branch.name + "?",
      onOk: async () => {
        const [response, branchGroups] = await branchesService.removeBranch(
          branch.id,
          data ? { accountId: data.accountId } : null
        );
        if (response.ok) {
          /*updateRows(branchGroups);
          onRefresh(branchGroups);*/
          await refreshData();
        } else {
          console.log("Error when removing branch: " + branchGroups.error);
        }
      },
      onCancel: () => {},
    });
  }

  async function handleCreateBranchGroup(branchGroup) {
    const [response, branchGroups] = await branchesService.createBranchGroup(
      data ? { accountId: data.accountId } : null,
      {
        ...branchGroup,
        subscriptionId: Number(branchGroup.subscriptionId),
      }
    );
    if (response.ok) {
      /*updateRows(branchGroups);
      onRefresh(branchGroups);*/
      await refreshData();
      return { success: true };
    } else {
      console.log("ERROR while creating branch group: " + branchGroups.error);
      return { error: branchGroups.error };
    }
  }

  async function handleUpdateBranchGroup(branchGroup) {
    const [response, branchGroups] = await branchesService.updateBranchGroup(
      branchGroup.id,
      data ? { accountId: data.accountId } : null,
      { ...branchGroup, subscriptionId: parseInt(branchGroup.subscriptionId) }
    );
    if (response.ok) {
      /*updateRows(branchGroups);
      onRefresh(branchGroups);*/
      await refreshData();
      return { success: true };
    } else {
      console.log("ERROR while updating branch group: " + branchGroups.error);
      return { error: branchGroups.error };
    }
  }

  async function handleGetSubscriptions(cancelToken) {
    const [response, data] = await subscriptionsService.getSubscriptions(
      null,
      cancelToken
    );
    if (response.ok) {
      setSubscriptions(data);
    } else {
    }
  }

  async function handleGetColorPalettes() {
    const [response, data] = await themeService.getColorPalettes(null);
    if (response.ok) {
      setColorPalettes(data);
    } else {
    }
  }

  async function handleGetAccountManagers(accountId) {
    const query = {};
    if (accountId || data?.accountId)
      query.accountId = accountId || data?.accountId;
    const [response, users] = await usersService.getUsers(query);
    if (response.ok) {
      setAccountManagers(users);
    } else {
    }
  }

  async function handleGetAccounts() {
    const [response, data] = await accountsService.getAccounts({ raw: true });
    if (response.ok) {
      setAccounts(data);
    } else {
    }
  }

  useEffect(() => {
    if (data) return;
    const source = axios.CancelToken.source();
    fetch(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    if (!data) return;

    updateRows(data.branchGroups || []);
  }, [data]);

  return (
    <>
      <ConsolidatedCreationReportDialog {...consolidatedReportDialog} />
      <MDBox
        sx={type === "component" ? { p: 0, pt: 3, pb: 1 } : { pt: 6, pb: 3 }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card
              sx={type === "component" ? { all: "unset !important" } : null}
            >
              <MDBox
                mx={2}
                mt={-3}
                py={type === "component" ? 2 : 3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Branch groups{" "}
                  {can("read", "branchGroup") ? `(${rows.length})` : ""}
                </MDTypography>

                <Tooltip
                  title={can(
                    "create",
                    "branchGroup",
                    data?.account,
                    (reason) => reason.message,
                    () => null
                  )}
                >
                  <div>
                    <MDButton
                      disabled={!can("create", "branchGroup", data?.account)}
                      size={type === "component" ? "small" : "medium"}
                      onClick={() => {
                        setBranchGroupsAction("create");
                        setBranchGroupsDialogOpen(Math.random());
                      }}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;add new branch group
                    </MDButton>
                  </div>
                </Tooltip>
              </MDBox>
              {can("read", "branchGroup") ? (
                <>
                  {type === "component" ? null : (
                    <MDBox p={2} mt={2}>
                      <TableSearch
                        id="all-branches"
                        fullWidth
                        setData={updateRows}
                        onSearch={async (request, callback) => {
                          if (
                            can("read", "branchGroup") ||
                            can("read", "branch")
                          ) {
                            if (request.accountId == 0) {
                              delete request.accountId;
                            }

                            lastQuery.current = request;
                            //setLastQuery(request);

                            callback(
                              can("read", "branch")
                                ? await branchesService.getBranches(request)
                                : can("read", "branchGroup")
                                  ? await branchesService.getBranchGroups(
                                      request
                                    )
                                  : [false, null]
                            );
                          }
                        }}
                        filterBySelectsItems={
                          accounts
                            ? [
                                {
                                  id: "accountId",
                                  items: [
                                    { value: 0, render: "All" },
                                    ...accounts.map((account) => {
                                      return {
                                        value: account.id,
                                        render: account.name,
                                      };
                                    }),
                                  ],
                                  label: "Filter by account",
                                },
                              ]
                            : null
                        }
                        label={"Search branches"}
                      />
                    </MDBox>
                  )}
                  <MDBox mt={type === "component" ? 2 : null}>
                    <CollapsibleTable
                      rows={rows}
                      showCollapsibleContent={can("read", "branch")}
                      noCard={type === "component"}
                    />
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <AlertDialog open={open} setOpen={setOpen} {...dialog} />
      <BranchGroupDialog
        action={branchGroupsAction}
        data={{
          branchGroup: currentBranchGroup,
          subscriptions: subscriptions,
          colorPalettes,
          accountManagers,
          accounts,
          accountId: data?.accountId,
        }}
        fetchSubscriptions={handleGetSubscriptions}
        fetchColorPalettes={handleGetColorPalettes}
        fetchAccountManagers={handleGetAccountManagers}
        fetchAccounts={handleGetAccounts}
        isOpen={branchGroupsDialogOpen}
        onSubmit={async (newBranchGroup) => {
          if (branchGroupsAction == "create") {
            return await handleCreateBranchGroup(newBranchGroup);
          } else {
            return await handleUpdateBranchGroup(newBranchGroup);
          }
        }}
      />
    </>
  );
}

export default AllBranches;
