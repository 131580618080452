import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import LoadingButton from "components/LoadingButton";
import { Avatar, Grid } from "@mui/material";
import ColorPickerInput from "components/ColorPickerInput";
import useObjectState from "utils/useObjectState";
import DropdownSelect from "components/DropdownSelect";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import accountsService from "services/accounts-service";

export default function ThemesEditNewDialog({
  isOpen,
  onSubmit,
  data,
  type,
  accountId,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [colorPalette, setColorPalette] = React.useState({
    description: "",
    color1: "",
    color2: "",
    color3: "",
  });

  const [options, changeOptions] = useObjectState({
    accounts: null,
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setColorPalette({
      description: "",
      color1: "",
      color2: "",
      color3: "",
      fontColor1: "",
      fontColor2: "",
      fontColor3: "",
    });
  }, [isOpen]);

  React.useEffect(() => {
    setColorPalette({ ...data });
  }, [data]);

  const handleGetAccounts = async () => {
    const [response, data] = await accountsService.getAccounts({ raw: true });
    if (response.ok) {
      changeOptions("accounts", data);
    } else {
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dropdownOptions = React.useMemo(() => {
    return {
      accounts:
        options.accounts?.map((account) => ({
          id: account.id,
          label: account.name,
        })) || [],
    };
  }, [options]);

  React.useEffect(() => {
    handleGetAccounts();
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(colorPalette);
          if (result.success) {
            handleClose();
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>Create new color palette</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: -0.5 }}>
          <Grid item xs={12}>
            <MDInput
              autoFocus
              required
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              value={colorPalette.description}
              onChange={(e) => {
                setColorPalette({
                  ...colorPalette,
                  description: e.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ColorPickerInput
              required
              margin="dense"
              id="color1"
              name="color1"
              label="Color 1"
              type="color"
              value={colorPalette.color1}
              onChange={(e) => {
                setColorPalette({ ...colorPalette, color1: e.target.value });
              }}
              setValue={(v) => {
                setColorPalette({
                  ...colorPalette,
                  color1: v,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ColorPickerInput
              required
              margin="dense"
              id="fontColor1"
              name="fontColor1"
              label="Font color 1"
              type="color"
              value={colorPalette.fontColor1}
              onChange={(e) => {
                setColorPalette({
                  ...colorPalette,
                  fontColor1: e.target.value,
                });
              }}
              setValue={(v) => {
                setColorPalette({
                  ...colorPalette,
                  fontColor1: v,
                });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <ColorPickerInput
              required
              margin="dense"
              id="color2"
              name="color2"
              label="Color 2"
              type="color"
              value={colorPalette.color2}
              onChange={(e) => {
                setColorPalette({ ...colorPalette, color2: e.target.value });
              }}
              setValue={(v) => {
                setColorPalette({
                  ...colorPalette,
                  color2: v,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ColorPickerInput
              required
              margin="dense"
              id="fontColor2"
              name="fontColor2"
              label="Font color 2"
              type="color"
              value={colorPalette.fontColor2}
              onChange={(e) => {
                setColorPalette({
                  ...colorPalette,
                  fontColor2: e.target.value,
                });
              }}
              setValue={(v) => {
                setColorPalette({
                  ...colorPalette,
                  fontColor2: v,
                });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <ColorPickerInput
              required
              margin="dense"
              id="color3"
              name="color3"
              label="Color 3"
              type="color"
              value={colorPalette.color3}
              onChange={(e) => {
                setColorPalette({ ...colorPalette, color3: e.target.value });
              }}
              setValue={(v) => {
                setColorPalette({
                  ...colorPalette,
                  color3: v,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ColorPickerInput
              required
              margin="dense"
              id="fontColor3"
              name="fontColor3"
              label="Font color 3"
              type="color"
              value={colorPalette.fontColor3}
              onChange={(e) => {
                setColorPalette({
                  ...colorPalette,
                  fontColor3: e.target.value,
                });
              }}
              setValue={(v) => {
                setColorPalette({
                  ...colorPalette,
                  fontColor3: v,
                });
              }}
              fullWidth
            />
          </Grid>
          {!accountId && (
            <Grid item xs={12}>
              <DropdownSelect
                required
                label={"Account"}
                initialValue={colorPalette.accountId}
                onChange={(e) => {
                  setColorPalette({ ...colorPalette, accountId: e?.id });
                }}
                items={dropdownOptions.accounts}
                fullWidth
                renderOption={(option) => (
                  <MDBox
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{ height: "30px", width: "30px" }}
                      src={option.url}
                    />
                    <MDTypography variant="body" sx={{ fontWeight: "400" }}>
                      {option.label}
                    </MDTypography>
                  </MDBox>
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {type == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
