import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingButton from "components/LoadingButton";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import "dayjs/locale/en-gb";

export default function AccountSubscriptionDialog({
  open,
  setOpen,
  data,
  onSubmit,
}) {
  const [accountSubscription, setAccountSubscription] = useState({
    startedAt: null,
  });
  const [loading, setLoading] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    setAccountSubscription({
      startedAt: data?.startedAt ? dayjs(new Date(data.startedAt)) : null,
    });
  }, [data]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          console.log("On Submit: ", data.id, {
            startedAt: accountSubscription.startedAt.$d,
          });

          const result = await onSubmit(data.id, {
            startedAt: accountSubscription.startedAt.$d,
          });
          if (result.success) {
            handleClose();
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>Edit Subscription</DialogTitle>
      <DialogContent>
        <Box mt={0.5}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DateTimePicker
              value={accountSubscription.startedAt || ""}
              onChange={(startedAt) => {
                setAccountSubscription({ startedAt });
              }}
              label="Started At"
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
